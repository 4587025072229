.fallback-logo {
  width: 78px;
  height: 78px;
  transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 768px) {
    .fallback-logo {
        width: 52px;
        height: 52px;
        transition: all 0.2s ease-in-out;
    }

    .loading {
        width: 28px !important;
        height: 28px !important;
    }

    .effect-1,
    .effect-2,
    .effect-3 {
        width: 28px !important;
        height: 28px !important;
    }
}
