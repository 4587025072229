.btn-google-sign {
    height: 50px;
    border: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
    font-size: 16px;
    line-height: 48px;
    display: block;
    border-radius: 1px;
    font-family: 'Roboto;arial;sans-serif';
    cursor: pointer;
    user-select: none;
    background-color: #4285f4;
    color: #fff;
    transition: background-color .218s, border-color .218s, box-shadow .218s;
}

.btn-google-sign-disabled {
    background-color: rgba(37, 5, 5, .08);
    color: rgba(0, 0, 0, .40);
    cursor: not-allowed;
    box-shadow: none !important;
}

.btn-google-sign-disabled:hover {
    box-shadow: none !important;
}

.btn-google-sign:hover {
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, .3);
    transition: background-color .218s, border-color .218s, box-shadow .218s;
}

.google-label {
    display: block;
    text-align: center;
    user-select: none;
}