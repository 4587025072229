@charset "UTF-8";
/* Author: thewebmax team
=========================
This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.
This files [Table of contents] are outlined below
---------------------------------------------------
** - ========================
	 GENERAL 
	 ========================
** - Title separators
** - Title separators
** - Center title
** - Counter
** - Client carousel 1
** - Client carousel 2
** - Footer News Letter
** - Footer dark version css
** - Footer Light version css
** - Footer Newsletter
** - Recent Post Entry
** - Widget Services
** - scroll top btn css
** - Pricing Table
** - inner page banner
** - Breadcrumb
** - Contact Form
** - Pagination Style
** - Blog Side Bar
** - Side bar tags
** - Comment list section
** - comment form
** - Comment area
** - Single Blog Post
** - Single Post Tags
** - Single Post Tags
** - Posts Author
** - Single Post Navigation
** - Loading
** - Loading Animation Start
** - Description list
** - Description list
** - Section Overlay
** - Services
** - Search
** - Google map
** - Gallery
** - Video
** - Slide Top Animation
** - Zoom in Zoom Out Animation
** - Up Down Animation
** - Working Process
** - process steps
** - how it work
** - testimonial style 1
** - testimonial style 1
** - Blog post 1
** - Blog post 2
** - BLog Post List Style
** - Job post section
** - Employer List
** - Employer grid
** - Explore New Life
** - Explore New Life
** - Job categories
** - Search Bar
** - Search Bar Section home 2
** - trusted Logo
** - tabs
** - tabs
** - Side bar Advertisement
** - Side Bar Elements
** - Checked Input
** - Error 404
** - Candidate List
** - Candidate grid
** - Candidates detail
** - Employer detail
** - Work Experience
** - Social Share
** - Employer detail
** - Home Page 2 Banner Section Css Start 
** - Home Page 1 Banner Section Css Start 
** - Side Map
** - Side Map2
** - Sidebar Info
** - Sidebar Info
** - Sidebar  Info 2
** - Sidebar-contact
** - Faq
** - Accordion button
** - Sign Up popup
** - Dashboard Header
** - My Account css
** - Dashboard message Dropdown
** - Dashboard Notification Dropdown 
** - Dashboard Message
** - Dashboard Notification
** - Admin Nav 
** - Form Elements 
** - Dashboard Card
** - Upload Video Button
** - dashboard List
** - dashboard message
** - Edit Profile
** - Dashboard Widget
** - notification list
** - Message list
** - Msg style 2
** - msg user list side button
** - Chart
** - Dashboard candidates style
** - Data Table Candidets 
** - default table
** - Data Table Candidets 
** - Data Table
** - Model Popup 
---------------------------------------------------
*/
// @import url("../../css2");

// @import url("../../css2-1");

/*===GENERAL===*/

.text-very-sm {
  font-size: 8px;
}

.site-text-primary {
  color: #92388e;
}

.site-text-secondry {
  color: #171d28;
}

.site-text-black {
  color: #000;
}

.site-text-gray {
  color: #f5f7f9;
}

.site-text-white {
  color: #fff;
}

.site-text-orange {
  color: #fd7e14;
}

.site-bg-primary {
  background-color: #1967d2;
}

.site-bg-black {
  background-color: #000;
}

.site-bg-dark {
  background-color: #17171d;
}

.site-bg-gray {
  background-color: #f5f7f9;
}

.site-bg-light {
  background-color: #f9f9f9;
}

.site-bg-white {
  background-color: #fff;
}

.site-bg-light-purple {
  background-color: #dbe6f7;
}

.text-primary {
  color: #92388e;
}

body {
  color: #121212;
  // font-family: "Josefin Sans", sans-serif;
  line-height: 1.7;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  font-size: 15px;
}

.vertical-line {
  // display: block;
  background-color: red;
  width: 1px;
  height: 30px;
}

a {
  color: #17171d;
  outline: 0px none;
  text-decoration: none;

  &:hover,
  &:focus {
    outline: 0px none;
    text-decoration: none;
  }

  &:active,
  &:hover,
  &:focus {
    color: inherit;
  }
}

.overflow-hide {
  overflow: hidden;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

/*Float clearfix*/

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.shadow-bx {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.wt-icon-box-wraper:after,
ol.commentlist li:after {
  content: "";
  display: table;
  clear: both;
}

section,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
summary {
  display: block;
}

ul {
  padding: 0px;
}

// ::selection {
//   background: #484848;
//   color: #fff;
// }

/*===Heading & Title====*/

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-family: "Josefin Sans", sans-serif !important;
  line-height: 1.2;
  color: #17171d;
}

h1 {
  font-size: 60px;
  font-weight: 700;
}

h2 {
  font-size: 46px;
  font-weight: 500;
}

h3 {
  font-size: 22px;
  font-weight: 500;
}

h4 {
  font-size: 18px;
  font-weight: 500;
}

h5 {
  font-size: 16px;
  font-weight: 500;
}

h6 {
  font-size: 16px;
  font-weight: 400;
}

/*---Text align--- */

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/*===Listing Style===*/

dl,
ul,
ol {
  list-style-position: outside;
  padding: 0px;
}

ul,
ol {
  margin-bottom: 24px;
}

ul li,
ol li {
  padding: 0px;
}

.list-simple {
  li {
    margin-bottom: 10px;

    ul {
      margin-left: 15px;
      margin-top: 10px;
    }
  }

  &.list-unstyled li ul {
    margin-left: 30px;
    margin-top: 10px;
  }
}

.list-circle,
.list-angle-right,
.list-arrow,
.list-check,
.list-checked,
.list-check-circle,
.list-chevron-circle,
.list-arrow-circle,
.list-times-circle {
  margin: 0px 0px 20px 0px;
  padding: 0px;
  list-style: none;
  font-weight: 600;
}

.list-circle li,
.list-angle-right li,
.list-arrow li,
.list-check li,
.list-checked li,
.list-check-circle li,
.list-chevron-circle li,
.list-arrow-circle li,
.list-times-circle li {
  padding: 5px 5px 5px 20px;
  position: relative;
}

.list-circle li:before,
.list-angle-right li:before,
.list-arrow li:before,
.list-check li:before,
.list-checked li:before,
.list-check-circle li:before,
.list-chevron-circle li:before,
.list-arrow-circle li:before,
.list-times-circle li:before {
  font-family: "FontAwesome";
  position: absolute;
  left: 0px;
  top: 3px;
  display: block;
  font-size: 15px;
  color: #000;
}

.list-circle li:before {
  top: 6px;
  font-size: 10px;
  content: "\f111";
}

.list-angle-right li:before {
  content: "\f105";
}

.list-arrow li:before {
  content: "\f0da";
}

.list-check li:before {
  content: "\f00c";
}

.list-checked li:before {
  content: "\f046";
}

.list-check-circle li:before {
  content: "\f058";
}

.list-chevron-circle li:before {
  content: "\f138";
}

.list-arrow-circle li:before {
  content: "\f0a9";
}

.list-times-circle li:before {
  content: "\f057";
}

/*---Padding (around)---*/

.p-a0 {
  padding: 0px;
}

.p-a5 {
  padding: 5px;
}

.p-a10 {
  padding: 10px;
}

.p-a15 {
  padding: 15px;
}

.p-a20 {
  padding: 20px;
}

.p-a25 {
  padding: 25px;
}

.p-a30 {
  padding: 30px;
}

.p-a40 {
  padding: 40px;
}

.p-a50 {
  padding: 50px;
}

.p-a60 {
  padding: 60px;
}

.p-a70 {
  padding: 70px;
}

.p-a80 {
  padding: 80px;
}

.p-a90 {
  padding: 90px;
}

.p-a100 {
  padding: 100px;
}

.p-a110 {
  padding: 110px;
}

.p-a120 {
  padding: 120px;
}

.p-a130 {
  padding: 130px;
}

.p-a140 {
  padding: 140px;
}

.p-a150 {
  padding: 150px;
}

/*---Padding (top)---*/

.p-t0 {
  padding-top: 0px;
}

.p-t5 {
  padding-top: 5px;
}

.p-t10 {
  padding-top: 10px;
}

.p-t15 {
  padding-top: 15px;
}

.p-t20 {
  padding-top: 20px;
}

.p-t30 {
  padding-top: 30px;
}

.p-t40 {
  padding-top: 40px;
}

.p-t50 {
  padding-top: 50px;
}

.p-t60 {
  padding-top: 60px;
}

.p-t70 {
  padding-top: 70px;
}

.p-t80 {
  padding-top: 80px;
}

.p-t90 {
  padding-top: 90px;
}

.p-t100 {
  padding-top: 100px;
}

.p-t110 {
  padding-top: 110px;
}

.p-t120 {
  padding-top: 120px;
}

.p-t130 {
  padding-top: 130px;
}

.p-t140 {
  padding-top: 140px;
}

.p-t150 {
  padding-top: 150px;
}

/*---Padding (bottom)---*/

.p-b0 {
  padding-bottom: 0px;
}

.p-b5 {
  padding-bottom: 5px;
}

.p-b10 {
  padding-bottom: 10px;
}

.p-b15 {
  padding-bottom: 15px;
}

.p-b20 {
  padding-bottom: 20px;
}

.p-b30 {
  padding-bottom: 30px;
}

.p-b40 {
  padding-bottom: 40px;
}

.p-b50 {
  padding-bottom: 50px;
}

.p-b60 {
  padding-bottom: 60px;
}

.p-b70 {
  padding-bottom: 70px;
}

.p-b80 {
  padding-bottom: 80px;
}

.p-b90 {
  padding-bottom: 90px;
}

.p-b100 {
  padding-bottom: 100px;
}

.p-b110 {
  padding-bottom: 110px;
}

.p-b120 {
  padding-bottom: 120px;
}

.p-b130 {
  padding-bottom: 130px;
}

.p-b140 {
  padding-bottom: 140px;
}

.p-b150 {
  padding-bottom: 150px;
}

/*---Padding (left)---*/

.p-l0 {
  padding-left: 0px;
}

.p-l5 {
  padding-left: 5px;
}

.p-l10 {
  padding-left: 10px;
}

.p-l15 {
  padding-left: 15px;
}

.p-l20 {
  padding-left: 20px;
}

.p-l30 {
  padding-left: 30px;
}

.p-l40 {
  padding-left: 40px;
}

.p-l50 {
  padding-left: 50px;
}

.p-l60 {
  padding-left: 60px;
}

.p-l70 {
  padding-left: 70px;
}

.p-l80 {
  padding-left: 80px;
}

.p-l90 {
  padding-left: 90px;
}

.p-l100 {
  padding-left: 100px;
}

.p-l110 {
  padding-left: 110px;
}

.p-l120 {
  padding-left: 120px;
}

.p-l130 {
  padding-left: 130px;
}

.p-l140 {
  padding-left: 140px;
}

.p-l150 {
  padding-left: 150px;
}

/*---Padding (right)---*/

.p-r0 {
  padding-right: 0px;
}

.p-r5 {
  padding-right: 5px;
}

.p-r10 {
  padding-right: 10px;
}

.p-r15 {
  padding-right: 15px;
}

.p-r20 {
  padding-right: 20px;
}

.p-r30 {
  padding-right: 30px;
}

.p-r40 {
  padding-right: 40px;
}

.p-r50 {
  padding-right: 50px;
}

.p-r60 {
  padding-right: 60px;
}

.p-r70 {
  padding-right: 70px;
}

.p-r80 {
  padding-right: 80px;
}

.p-r90 {
  padding-right: 90px;
}

.p-r100 {
  padding-right: 100px;
}

.p-r110 {
  padding-right: 110px;
}

.p-r120 {
  padding-right: 120px;
}

.p-r130 {
  padding-right: 130px;
}

.p-r140 {
  padding-right: 140px;
}

.p-r150 {
  padding-right: 150px;
}

/*---Padding (left right)---*/

.p-lr0 {
  padding-left: 0px;
  padding-right: 0px;
}

.p-lr5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-lr10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-lr15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-lr20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-lr30 {
  padding-left: 30px;
  padding-right: 30px;
}

.p-lr40 {
  padding-left: 40px;
  padding-right: 40px;
}

.p-lr50 {
  padding-left: 50px;
  padding-right: 50px;
}

.p-lr60 {
  padding-left: 60px;
  padding-right: 60px;
}

.p-lr70 {
  padding-left: 70px;
  padding-right: 70px;
}

.p-lr80 {
  padding-left: 80px;
  padding-right: 80px;
}

.p-lr90 {
  padding-left: 90px;
  padding-right: 90px;
}

.p-lr100 {
  padding-left: 100px;
  padding-right: 100px;
}

.p-lr120 {
  padding-left: 120px;
  padding-right: 120px;
}

.p-lr150 {
  padding-left: 150px;
  padding-right: 150px;
}

/*---Padding (top bottom)---*/

.p-tb0 {
  padding-bottom: 0px;
  padding-top: 0px;
}

.p-tb5 {
  padding-bottom: 5px;
  padding-top: 5px;
}

.p-tb10 {
  padding-bottom: 10px;
  padding-top: 10px;
}

.p-tb15 {
  padding-bottom: 15px;
  padding-top: 15px;
}

.p-tb20 {
  padding-bottom: 20px;
  padding-top: 20px;
}

.p-tb30 {
  padding-bottom: 30px;
  padding-top: 30px;
}

.p-tb40 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.p-tb50 {
  padding-bottom: 50px;
  padding-top: 50px;
}

.p-tb60 {
  padding-bottom: 60px;
  padding-top: 60px;
}

.p-tb70 {
  padding-bottom: 70px;
  padding-top: 70px;
}

.p-tb80 {
  padding-bottom: 80px;
  padding-top: 80px;
}

.p-tb90 {
  padding-bottom: 90px;
  padding-top: 90px;
}

.p-tb100 {
  padding-bottom: 100px;
  padding-top: 100px;
}

.p-tb120 {
  padding-bottom: 120px;
  padding-top: 120px;
}

.p-tb150 {
  padding-bottom: 150px;
  padding-top: 150px;
}

/*----Margin (around)----*/

.m-a-1 {
  margin: -1px;
}

.m-a0 {
  margin: 0px;
}

.m-a5 {
  margin: 5px;
}

.m-a10 {
  margin: 10px;
}

.m-a15 {
  margin: 15px;
}

.m-a20 {
  margin: 20px;
}

.m-a30 {
  margin: 30px;
}

.m-a40 {
  margin: 40px;
}

.m-a50 {
  margin: 50px;
}

.m-a60 {
  margin: 60px;
}

.m-a70 {
  margin: 70px;
}

.m-a80 {
  margin: 80px;
}

.m-a90 {
  margin: 90px;
}

.m-a100 {
  margin: 100px;
}

.m-a110 {
  margin: 110px;
}

.m-a120 {
  margin: 120px;
}

.m-a130 {
  margin: 130px;
}

.m-a140 {
  margin: 140px;
}

.m-a150 {
  margin: 150px;
}

/*---Marging (top)----*/

.m-t0 {
  margin-top: 0px;
}

.m-t5 {
  margin-top: 5px;
}

.m-t10 {
  margin-top: 10px;
}

.m-t15 {
  margin-top: 15px;
}

.m-t20 {
  margin-top: 20px;
}

.m-t30 {
  margin-top: 30px;
}

.m-t40 {
  margin-top: 40px;
}

.m-t50 {
  margin-top: 50px;
}

.m-t60 {
  margin-top: 60px;
}

.m-t70 {
  margin-top: 70px;
}

.m-t80 {
  margin-top: 80px;
}

.m-t90 {
  margin-top: 90px;
}

.m-t100 {
  margin-top: 100px;
}

.m-t110 {
  margin-top: 110px;
}

.m-t120 {
  margin-top: 120px;
}

.m-t130 {
  margin-top: 130px;
}

.m-t140 {
  margin-top: 140px;
}

.m-t150 {
  margin-top: 150px;
}

/*---Marging (bottom)---*/

.m-b0 {
  margin-bottom: 0px;
}

.m-b5 {
  margin-bottom: 5px;
}

.m-b10 {
  margin-bottom: 10px;
}

.m-b15 {
  margin-bottom: 15px;
}

.m-b20 {
  margin-bottom: 20px;
}

.m-b30 {
  margin-bottom: 30px;
}

.m-b40 {
  margin-bottom: 40px;
}

.m-b50 {
  margin-bottom: 50px;
}

.m-b60 {
  margin-bottom: 60px;
}

.m-b70 {
  margin-bottom: 70px;
}

.m-b80 {
  margin-bottom: 80px;
}

.m-b90 {
  margin-bottom: 90px;
}

.m-b100 {
  margin-bottom: 100px;
}

.m-b110 {
  margin-bottom: 110px;
}

.m-b120 {
  margin-bottom: 120px;
}

.m-b130 {
  margin-bottom: 130px;
}

.m-b140 {
  margin-bottom: 140px;
}

.m-b150 {
  margin-bottom: 150px;
}

/*---Marging (left)---*/

.m-l0 {
  margin-left: 0px;
}

.m-l5 {
  margin-left: 5px;
}

.m-l10 {
  margin-left: 10px;
}

.m-l15 {
  margin-left: 15px;
}

.m-l20 {
  margin-left: 20px;
}

.m-l30 {
  margin-left: 30px;
}

.m-l40 {
  margin-left: 40px;
}

.m-l50 {
  margin-left: 50px;
}

.m-l60 {
  margin-left: 60px;
}

.m-l70 {
  margin-left: 70px;
}

.m-l80 {
  margin-left: 80px;
}

.m-l90 {
  margin-left: 90px;
}

.m-l100 {
  margin-left: 100px;
}

.m-l110 {
  margin-left: 110px;
}

.m-l120 {
  margin-left: 120px;
}

.m-l130 {
  margin-left: 130px;
}

.m-l140 {
  margin-left: 140px;
}

.m-l150 {
  margin-left: 150px;
}

/*---Marging (right)---*/

.m-r0 {
  margin-right: 0px;
}

.m-r5 {
  margin-right: 5px;
}

.m-r10 {
  margin-right: 10px;
}

.m-r15 {
  margin-right: 15px;
}

.m-r20 {
  margin-right: 20px;
}

.m-r30 {
  margin-right: 30px;
}

.m-r40 {
  margin-right: 40px;
}

.m-r50 {
  margin-right: 50px;
}

.m-r60 {
  margin-right: 60px;
}

.m-r70 {
  margin-right: 70px;
}

.m-r80 {
  margin-right: 80px;
}

.m-r90 {
  margin-right: 90px;
}

.m-r100 {
  margin-right: 100px;
}

.m-r110 {
  margin-right: 110px;
}

.m-r120 {
  margin-right: 120px;
}

.m-r130 {
  margin-right: 130px;
}

.m-r140 {
  margin-right: 140px;
}

.m-r150 {
  margin-right: 150px;
}

/*---Marging (left right)---*/

.m-lr0 {
  margin-left: 0px;
  margin-right: 0px;
}

.m-lr5 {
  margin-left: 5px;
  margin-right: 5px;
}

.m-lr10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-lr15 {
  margin-left: 15px;
  margin-right: 15px;
}

.m-lr20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m-lr30 {
  margin-left: 30px;
  margin-right: 30px;
}

.m-lr40 {
  margin-left: 40px;
  margin-right: 40px;
}

.m-lr50 {
  margin-left: 50px;
  margin-right: 50px;
}

.m-lr60 {
  margin-left: 60px;
  margin-right: 60px;
}

.m-lr70 {
  margin-left: 70px;
  margin-right: 70px;
}

.m-lr80 {
  margin-left: 80px;
  margin-right: 80px;
}

.m-lr90 {
  margin-left: 90px;
  margin-right: 90px;
}

.m-lr100 {
  margin-left: 100px;
  margin-right: 100px;
}

.m-lr120 {
  margin-left: 120px;
  margin-right: 120px;
}

.m-lr150 {
  margin-left: 150px;
  margin-right: 150px;
}

/*---Marging (top bottom)---*/

.m-tb0 {
  margin-bottom: 0px;
  margin-top: 0px;
}

.m-tb5 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.m-tb10 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.m-tb15 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.m-tb20 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.m-tb30 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.m-tb40 {
  margin-bottom: 40px;
  margin-top: 40px;
}

.m-tb50 {
  margin-bottom: 50px;
  margin-top: 50px;
}

.m-tb60 {
  margin-bottom: 60px;
  margin-top: 60px;
}

.m-tb70 {
  margin-bottom: 70px;
  margin-top: 70px;
}

.m-tb80 {
  margin-bottom: 80px;
  margin-top: 80px;
}

.m-tb90 {
  margin-bottom: 90px;
  margin-top: 90px;
}

.m-tb100 {
  margin-bottom: 100px;
  margin-top: 100px;
}

.m-tb120 {
  margin-bottom: 120px;
  margin-top: 120px;
}

.m-tb150 {
  margin-bottom: 150px;
  margin-top: 150px;
}

/*---Colum gap less---*/

.no-col-gap {
  [class*="col-xs-"],
  [class*="col-sm-"],
  [class*="col-md-"],
  [class*="col-lg-"] {
    padding-left: 0px;
    padding-right: 0px;
  }
}

/*===Background Effect===*/

.bg-center {
  background-position: center;
}

.bg-top-left {
  background-position: top left;
}

.bg-top-right {
  background-position: top right;
}

.bg-top-center {
  background-position: top center;
}

.bg-bottom-left {
  background-position: bottom left;
}

.bg-bottom-right {
  background-position: bottom right;
}

.bg-bottom-center {
  background-position: bottom center;
}

.bg-left-center,
.bg-right-center {
  background-position: left;
}

.bg-auto {
  background-size: auto;
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-full-width {
  background-size: 100% auto;
}

.bg-full-height {
  background-size: auto 100%;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-scroll {
  background-attachment: scroll;
}

/*===Overlay ( transparient box style)===*/

.overlay-wraper {
  position: relative;
}

.overlay-main {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-09 {
  opacity: 0.9;
}

.overlay-light .overlay-main {
  opacity: 0.3;
}

.overlay-dark .overlay-main {
  opacity: 0.9;
}

.overlay-wraper > {
  .container,
  .container-fluid,
  .wt-icon-box-wraper,
  .wt-left-part,
  .wt-right-part {
    position: relative;
    z-index: 1;
  }
}

/*===Boxes Css===*/

.rounded-bx,
.wt-box,
.wt-icon-box,
.wt-icon-box-small,
.wt-thum-bx,
.wt-post-thum {
  position: relative;
}

/*---Box content----*/

.wt-box {
  position: relative;

  &.no-margin {
    margin-bottom: 0;
  }
}

/*---For icons with box---*/

.wt-icon-box-xld {
  width: 180px;
  height: 180px;
  display: inline-block;
  text-align: center;

  i {
    font-size: 100px;
  }

  img {
    height: 90px;
  }
}

.wt-icon-box-xl {
  width: 150px;
  height: 150px;
  display: inline-block;
  text-align: center;

  i {
    font-size: 80px;
  }

  img {
    height: 80px;
  }
}

.wt-icon-box-lg {
  width: 120px;
  height: 120px;
  display: inline-block;
  text-align: center;

  i {
    font-size: 60px;
  }

  img {
    height: 55px;
  }
}

.wt-icon-box-md {
  width: 100px;
  height: 100px;
  display: inline-block;
  text-align: center;

  i {
    font-size: 45px;
  }

  img {
    height: 40px;
  }
}

.wt-icon-box-sm {
  width: 80px;
  height: 80px;
  display: inline-block;
  text-align: center;

  i {
    font-size: 30px;
  }

  img {
    height: 30px;
  }
}

.wt-icon-box-xs {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;

  i {
    font-size: 20px;
  }

  img {
    height: 20px;
  }
}

.wt-icon-box-xld.radius,
.wt-icon-box-xl.radius,
.wt-icon-box-lg.radius,
.wt-icon-box-md.radius,
.wt-icon-box-sm.radius,
.wt-icon-box-xs.radius {
  border-radius: 100%;
}

.wt-icon-box-xld i,
.wt-icon-box-xl i,
.wt-icon-box-lg i,
.wt-icon-box-md i,
.wt-icon-box-sm i,
.wt-icon-box-xs i {
  vertical-align: middle;
}

.wt-icon-box-xld img,
.wt-icon-box-xl img,
.wt-icon-box-lg img,
.wt-icon-box-md img,
.wt-icon-box-sm img,
.wt-icon-box-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
}

/*----For only icons---*/

.icon-xld,
.icon-xl,
.icon-lg,
.icon-md,
.icon-sm,
.icon-xs {
  display: inline-block;
  text-align: center;
}

.icon-xld i,
.icon-xl i,
.icon-lg i,
.icon-md i,
.icon-sm i,
.icon-xs i {
  vertical-align: middle;
}

.icon-xld img,
.icon-xl img,
.icon-lg img,
.icon-md img,
.icon-sm img,
.icon-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
}

.icon-xld {
  width: 120px;

  i {
    font-size: 100px;
    line-height: 100px;
  }

  &.inline-icon {
    width: auto;
    text-align: left !important;
  }

  img {
    height: 90px;
  }

  &.inline-icon {
    width: auto;
  }
}

.icon-xl {
  width: 100px;

  i {
    font-size: 80px;
    line-height: 80px;
  }

  &.inline-icon {
    width: auto;
    text-align: left !important;
  }

  img {
    height: 80px;
  }

  &.inline-icon {
    width: auto;
  }
}

.icon-lg {
  width: 80px;

  i {
    font-size: 60px;
    line-height: 60px;
  }

  &.inline-icon {
    width: auto;
    text-align: left !important;
  }

  img {
    height: 70px;
  }

  &.inline-icon {
    width: auto;
  }
}

.icon-md {
  width: 60px;

  i {
    font-size: 45px;
    line-height: 45px;
  }

  &.inline-icon {
    width: auto;
    text-align: left !important;
  }

  img {
    height: 50px;
  }

  &.inline-icon {
    width: auto;
  }
}

.icon-sm {
  width: 40px;

  i {
    font-size: 30px;
    line-height: 30px;
  }

  &.inline-icon {
    width: auto;
    text-align: left !important;
  }

  img {
    height: 30px;
  }

  &.inline-icon {
    width: auto;
  }
}

.icon-xs {
  width: 30px;

  i {
    font-size: 20px;
    line-height: 20px;
  }

  &.inline-icon {
    width: auto;
    text-align: left !important;
  }

  img {
    height: 20px;
  }

  &.inline-icon {
    width: auto;
  }
}

/*---Place icon with box---*/

.wt-icon-box-wraper {
  position: relative;

  .wt-tilte {
    margin-top: 0;
  }

  .after-titile-line {
    margin-bottom: 10px;
  }

  p:last-child {
    margin: 0px;
  }
}

.icon-content {
  overflow: hidden;
}

.wt-icon-box-xld,
.wt-icon-box-xl,
.wt-icon-box-lg,
.wt-icon-box-md,
.wt-icon-box-sm,
.wt-icon-box-xs {
  display: table;
}

.wt-icon-box-xld .icon-cell,
.wt-icon-box-xl .icon-cell,
.wt-icon-box-lg .icon-cell,
.wt-icon-box-md .icon-cell,
.wt-icon-box-sm .icon-cell,
.wt-icon-box-xs .icon-cell {
  display: table-cell;
  vertical-align: middle;
}

/*---Icon box left align---*/

.wt-icon-box-wraper {
  &.left {
    .wt-icon-box-xld,
    .wt-icon-box-xl,
    .wt-icon-box-lg,
    .wt-icon-box-md,
    .wt-icon-box-sm,
    .wt-icon-box-xs,
    .icon-xld,
    .icon-xl,
    .icon-lg,
    .icon-md,
    .icon-sm,
    .icon-xs {
      float: left;
      margin-right: 10px;
    }
  }

  &.right {
    text-align: right;

    .wt-icon-box-xld,
    .wt-icon-box-xl,
    .wt-icon-box-lg,
    .wt-icon-box-md,
    .wt-icon-box-sm,
    .wt-icon-box-xs {
      float: right;
      display: table;
      margin-left: 20px;
    }

    .icon-xld,
    .icon-xl,
    .icon-lg,
    .icon-md,
    .icon-sm,
    .icon-xs {
      float: right;
      margin-left: 20px;
    }
  }

  &.center {
    text-align: center;

    .wt-icon-box-xld,
    .wt-icon-box-xl,
    .wt-icon-box-lg,
    .wt-icon-box-md,
    .wt-icon-box-sm,
    .wt-icon-box-xs {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.bx-style-1 {
    border-width: 1px;
    border-style: solid;
    border-color: #ddd;
  }

  &.bx-style-2 {
    border-width: 1px;
    border-style: solid;
    border-color: #ddd;

    &.center [class*="wt-icon-box-"] {
      position: absolute;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateY(-50%);
    }

    &.left [class*="wt-icon-box-"],
    &.right [class*="wt-icon-box-"] {
      position: absolute;
    }
  }
}

/*---Icon box right align---*/

/*---Icon box center align---*/

/*---Buttons--- */

.site-button-link {
  position: relative;
  color: #1967d2;
  font-size: 18px;
  font-weight: 500;
  z-index: 1;
  transition: 0.5s all ease;
  overflow: hidden;

  &:after {
    content: "\f105";
    position: absolute;
    font-family: "Font Awesome 5 Free";
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0px;
    transition: 0.5s all ease;
    opacity: 0;
    font-weight: 900;
  }

  &:hover {
    padding-left: 20px;
    color: #000;

    &:after {
      font-size: 22px;
      left: 0px;
      opacity: 1;
    }
  }

  &.site-text-white {
    color: #fff;

    &:hover {
      color: #17171d;
    }
  }

  &.site-text-secondry {
    color: #171d28;

    &:hover {
      color: #171d28;
    }
  }
}

/* Theme default button */

.site-button {
  outline: none;
  color: #fff;
  padding: 15px 30px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: inline-table;
  background-color: #1967d2;
  border: none;
  font-size: 16px;
  font-weight: 600;
  transition: 0.5s all ease;

  &:hover {
    color: #000;
  }

  i {
    padding-right: 10px;
  }

  &.white {
    background-color: #fff;
    color: #1967d2;
  }

  &.outline-white {
    border: 1px solid #fff;
    color: #fff;
    background-color: transparent;
  }

  &.outline-primary {
    border: 1px solid #1967d2;
    color: #1967d2;
    background-color: transparent;

    &:hover {
      color: #17171d;
      border: 1px solid #17171d;
    }
  }

  &.secondry {
    background-color: #17171d;

    &:hover {
      color: #1967d2;
    }
  }
}

@media (max-width: 480px) {
  .site-button {
    padding: 8px 8px;
    font-size: 16px;
  }
}

.btn-hover-animation {
  z-index: 1;
  overflow: hidden;
  display: inline-block;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -4px;
    left: -14px;
    background: rgba(0, 0, 0, 0.1);
    height: 50px;
    width: 50px;
    border-radius: 50px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 1s ease-out;
    transition: transform 1s ease-out;
  }

  &:hover:before {
    transform: scale(8);
  }
}

.site-button-secondry {
  color: #fff;
  padding: 15px 40px;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  background-color: #000;
  border: none;
  font-size: 15px;
  font-weight: 700;

  &.sb-bdr-light {
    border-right: 6px solid #fff;
  }

  &:active,
  &:focus,
  &:visited {
    background-color: #000;
    color: #fff;
  }

  &:hover {
    background-color: #f5f7f9;
    color: #000;
  }
}

/*====Button Effect====*/

.site-btn-effect {
  color: rgba(0, 0, 0, 0) !important;
  transition: all 0.3s ease !important;
}

.mfp-video.video-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  margin: -25px;
  color: #fff;
  display: block;
  z-index: 10;
  font-size: 16px;
  border: 2px solid #fff;
  border-radius: 50%;

  &:hover {
    background-color: #0f1221;
    color: #fff;
    border: 2px solid transparent;
  }

  i {
    margin-right: -5px;
  }
}

/*Button text uppercase */

.site-button.text-uppercase {
  text-transform: uppercase;
}

/*Button size */

.button-sm {
  padding: 5px 10px;
  font-size: 12px;
}

.button-lg {
  padding: 15px 30px;
  font-size: 16px;
}

.button-xl {
  padding: 30px 50px;
  font-size: 24px;
}

/*Button rounded */

.radius-no {
  border-radius: 0px;
}

.radius-sm {
  border-radius: 10px;
}

.radius-md {
  border-radius: 20px;
}

.radius-xl {
  border-radius: 100px;
}

.radius-bx {
  border-radius: 100%;
}

/*===Owl Slider===*/

.owl-carousel {
  .owl-nav {
    margin-top: 40px;
    text-align: center;

    .disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  .owl-dots {
    text-align: center;
    margin-top: 30px;

    .owl-dot {
      display: inline-block;

      span {
        width: 6px;
        height: 6px;
        margin: 5px 7px;
        background: #051026;
        border: 2px solid transparent;
        display: block;
        border-radius: 50%;
        transition: opacity 200ms ease;
      }

      &:hover span {
        background: #000;
      }

      &.active span {
        background: none;
        border: 2px solid #051026;
        margin: 2px;
        height: 12px;
        width: 12px;
        background: #fff;
      }
    }
  }
}

/*---Owl dots button---*/

/*---Owl button top to center---*/

.owl-btn-top-center .owl-nav,
.owl-btn-top-left .owl-nav,
.owl-btn-top-right .owl-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
}

.owl-btn-top-center .owl-stage-outer,
.owl-btn-top-left .owl-stage-outer,
.owl-btn-top-right .owl-stage-outer {
  padding-top: 60px;
}

.owl-btn-top-center .owl-nav {
  text-align: center;
}

/*---Owl button top to left---*/

.owl-btn-top-left .owl-nav {
  text-align: left;
}

/*---Owl button top to right---*/

.owl-btn-top-right .owl-nav {
  text-align: right;
}

/*---Owl button bottom to center [it is default position ]---*/

.owl-btn-bottom-center .owl-nav {
  text-align: center;
}

/*---Owl button bottom to left---*/

.owl-btn-bottom-left .owl-nav {
  text-align: left;
}

/*Owl button bottom to right*/

.owl-btn-bottom-right .owl-nav {
  text-align: right;
}

/*---Owl button vertical to center--*/

.owl-btn-vertical-center .owl-nav {
  margin: 0px;

  .owl-prev {
    position: absolute;
    top: 50%;
    margin: -15px 0;
    left: 0;
  }

  .owl-next {
    position: absolute;
    top: 50%;
    margin: -15px 0;
    right: 0;
  }
}

/*---Owl button show on hover---*/

.owl-btn-hover {
  .owl-nav {
    opacity: 0;
  }

  &:hover .owl-nav {
    opacity: 1;
  }
}

.owl-carousel {
  .owl-item img {
    transform-style: inherit;
  }

  .owl-nav button {
    &.owl-next,
    &.owl-prev {
      width: 42px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background-color: #1967d2;
      color: #fff;
      padding: 0px !important;
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 2px;
      display: inline-block;
      cursor: pointer;
      border-radius: 50%;
      opacity: 1;
      margin-right: 5px;
      font-weight: 500;
      box-shadow: 0px 0px 30px rgba(56, 152, 226, 0.3);
    }
  }

  button.owl-dot {
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    background-color: #1967d2;
    color: #fff;
    padding: 0px !important;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    opacity: 1;
    margin-right: 5px;
    font-weight: 500;
    box-shadow: 0px 0px 30px rgba(56, 152, 226, 0.3);
  }

  .owl-nav button {
    &.owl-next i,
    &.owl-prev i {
      font-style: normal;
      font-size: 26px;
      line-height: 42px;
    }
  }

  button.owl-dot i {
    font-style: normal;
    font-size: 26px;
    line-height: 42px;
  }

  .owl-nav button {
    &.owl-next:hover,
    &.owl-prev:hover {
      background-color: #fff;
      color: #1967d2;
      opacity: 0.9;
    }
  }

  button.owl-dot:hover {
    background-color: #fff;
    color: #1967d2;
    opacity: 0.9;
  }

  .owl-nav button {
    &.owl-next:active,
    &.owl-prev:active {
      background-color: #fff;
      color: #1967d2;
      opacity: 0.9;
    }
  }

  button.owl-dot:active {
    background-color: #fff;
    color: #1967d2;
    opacity: 0.9;
  }

  .owl-nav button {
    &.owl-next:focus,
    &.owl-prev:focus {
      background-color: #fff;
      color: #1967d2;
      opacity: 0.9;
    }
  }

  button.owl-dot:focus {
    background-color: #fff;
    color: #1967d2;
    opacity: 0.9;
  }
}

/*---Owl slider button---*/

.owl-btn-bottom-left .owl-nav {
  text-align: left;
}

/*---Owl dots nav position---*/

.owl-dots-bottom-left .owl-dots {
  bottom: 0px;
  padding: 15px 20px;
  position: absolute;
  width: 100%;
  text-align: left;
}

.owl-dots-bottom-right .owl-dots {
  bottom: 0px;
  padding: 15px 20px;
  position: absolute;
  width: 100%;
  text-align: right;
}

.owl-dots-bottom-center .owl-dots {
  bottom: 0px;
  padding: 15px 20px;
  position: absolute;
  width: 100%;
}

/*-------------------------------------
	Text meant only for screen readers
-------------------------------------*/

.sticky,
.gallery-caption,
.bypostauthor {
  clear: both;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;

  &:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #21759b;
    display: block;
    font-size: 16px;
    font-size: 0.875rem;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
  }
}

/*-------------------------------------
	Tabs 
-------------------------------------*/
/*Tabs style 1 [ default ] */

.wt-tabs {
  .tab-pane {
    padding: 20px 0px 0px 0px;
    background-color: #fff;
  }

  .nav-tabs {
    border: 0px;

    > li {
      border: 1px solid #ebebeb;

      > a {
        color: #000;
        background-color: #fff;
        font-size: 16px;
        padding: 15px 40px;
        border: 0px;
        display: block;
        font-weight: 600;

        i {
          margin-right: 5px;
        }
      }

      a.active {
        background-color: #f5f7f9;
        color: #000;

        &:focus,
        &:hover {
          background-color: #f5f7f9;
          color: #000;
        }
      }
    }
  }

  .nav {
    > li > a {
      &:focus,
      &:hover {
        background-color: transparent;
        border: 0px;
      }
    }

    li a:hover {
      border: 1px;
    }
  }

  &.tabs-default {
    .tabs-default-nav .nav-tabs {
      display: flex;
      justify-content: flex-end;

      > li {
        > a {
          color: #000;

          span {
            display: block;

            i {
              font-weight: normal;
            }
          }

          &.active {
            color: #fff;
          }
        }

        &.active > a {
          color: #000;
          border-color: #ddd #ddd #fff;

          &:focus,
          &:hover {
            color: #000;
            border-color: #ddd #ddd #fff;
          }
        }
      }
    }

    .tab-pane {
      color: #fff;
    }
  }
}

@media (max-width: 767px) {
  .wt-tabs.tabs-default .tabs-default-nav .nav-tabs {
    justify-content: flex-start;
  }
}

@media (max-width: 991px) {
  .wt-tabs.tabs-default .tab-pane {
    padding: 0px;
  }
}

.header-style-3 {
  position: relative;

  /*--is fixed---*/
  /*====Mobile Side =======*/
  /*---when header is sticky---*/
  /*---when header is sticky---*/
  /*---header id Fixed---*/

  .header-nav-btn-section {
    float: right;
    display: flex;

    .twm-nav-btn-left {
      margin-right: 10px;

      .twm-nav-sign-up {
        background-color: #dbe6f7;
        color: #1967d2;
        font-size: 16px;
        border-radius: 4px;
        padding: 6px 15px;
        display: block;
        white-space: nowrap;

        i {
          padding-right: 5px;
        }
      }
    }

    .twm-nav-btn-right {
      margin-right: 10px;

      .twm-nav-post-a-job {
        background-color: #1967d2;
        color: #dbe6f7;
        font-size: 16px;
        border-radius: 4px;
        padding: 6px 15px;
        display: block;
        white-space: nowrap;

        i {
          padding-right: 5px;
        }
      }
    }
  }

  .header-search a.header-search-icon {
    color: #1967d2;
    width: 54px;
    height: 54px;
    line-height: 54px;
    display: block;
    text-align: center;
    font-size: 24px;
    font-weight: normal;
    border-radius: 50%;
  }

  .extra-nav {
    /*---Extra Nav---*/
    display: table;
    float: right;
    height: 70px;
    position: relative;
    z-index: 13;

    .extra-cell {
      display: table-cell;
      padding-left: 25px;
      vertical-align: middle;

      &:first-child {
        padding-left: 0px;
      }
    }
  }

  .logo-header {
    /*--logo for header---*/
    display: table;
    float: left;
    width: 190px;
    height: 70px;
    vertical-align: middle;
    padding: 0px;
    font-size: 36px;
    color: #000;
    margin: 0px;
    position: relative;
    z-index: 11;

    .logo-header-inner {
      display: table-cell;
      vertical-align: middle;
      height: 100%;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &.site-header {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
    z-index: 999;

    ul,
    ol {
      margin-bottom: 0px;
    }
  }

  .main-bar {
    /*--main-bar--*/
    position: relative;
    width: 100%;
    // padding-top: 10px;
    // padding-bottom: 10px;
    transition: all 0.5s ease;
    background: transparent;
    max-width: 1345px;
    left: 50%;
    transform: translateX(-50%);

    .container,
    .container-fluid {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &:after {
      clear: both;
      content: "";
      display: table;
    }
  }

  .navbar-toggler {
    /*--Navbar Toggler---*/
    border: none;
    margin: 24px 0px 24px 15px;
    padding: 12px 10px;

    &:focus {
      box-shadow: none !important;
    }

    .icon-bar {
      background: #1967d2;
      height: 3px;
      border-radius: 0px;
      display: block;
      width: 22px;

      + .icon-bar {
        margin-top: 4px;
      }
    }
  }

  .is-fixed.mobile-sider-drawer-menu .header-nav .nav {
    height: 100vh !important;
    width: 100%;

    > li > a {
      padding: 12px 15px !important;
    }
  }

  .header-nav {
    position: relative;
    padding: 0px;
    z-index: 10;

    /*-- Submenu direction---*/

    .nav {
      display: flex;
      justify-content: flex-end;
      flex-direction: inherit;

      li i {
        font-size: 9px;
        margin-left: 3px;
        margin-top: -3px;
        vertical-align: middle;
        opacity: 0.7;
      }

      > li {
        position: relative;
        margin: -10px 0px;
        padding: 10px 0px;
        // font-family: "Josefin Sans", sans-serif;

        > a {
          color: #2f2f2f;
          font-size: 16px;
          padding: 15px 16px;
          cursor: pointer;
          margin: 0px 0px;
          font-weight: 400;
          display: block;

          &:hover {
            background-color: transparent;
            color: #1967d2;
          }

          &:active,
          &:focus {
            background-color: transparent;
          }
        }

        &.active > a,
        &.current-menu-item > a {
          background-color: transparent;
          color: #000;
        }

        &:hover > {
          a {
            color: #1967d2;
          }

          .sub-menu {
            opacity: 1;
            visibility: visible;
            margin-top: 0px;
            transition: all 0.3s ease;
          }
        }

        .sub-menu {
          background-color: #fff;
          display: block;
          left: 0px;
          top: 100%;
          list-style: none;
          margin: 20px 0 0;
          opacity: 0;
          padding: 0px;
          position: absolute;
          visibility: hidden;
          width: 220px;
          z-index: 10;
          transition: none !important;

          li {
            border-bottom: 1px solid #f4f4f4;
            position: relative;
            text-transform: none;

            a {
              color: #000;
              display: block;
              font-size: 13px;
              padding: 14px 20px;
              padding-left: 20px;
              font-weight: 500;

              &:hover {
                color: #000;
                text-decoration: none;
              }
            }

            &:hover > a {
              color: #1967d2;
            }

            &:last-child {
              border-bottom: 0px;
            }

            .fa {
              color: #000;
              display: block;
              float: right;
              position: absolute;
              right: 10px;
              top: 2px;
            }

            > .sub-menu {
              left: 220px;
              margin: 0px 0px 0px 20px;
              transition: all 0.1s ease;
            }

            &:hover > .sub-menu {
              left: 220px;
              margin: 0px;
              opacity: 1;
              top: -1px;
              visibility: visible;

              &:before {
                background-color: transparent;
                bottom: 0px;
                content: "";
                display: block;
                height: 100%;
                left: -6px;
                position: absolute;
                top: 0px;
                width: 6px;
              }
            }
          }
        }
      }
    }

    .submenu-direction .sub-menu {
      left: auto;
      right: 0px;

      li {
        > .sub-menu {
          left: auto;
          right: 220px;
          margin: 0px 20px 0px 0px;
        }

        &:hover > .sub-menu {
          left: auto;
          right: 220px;
          margin: 0px 0px 0px 0px;
        }
      }
    }

    &.nav-animation .nav > li {
      > ul.sub-menu li > a {
        transition: all 0.5s ease-out;
        position: relative;
        z-index: 1;
      }

      &:hover > ul.sub-menu li:hover > a {
        padding-left: 40px;
      }
    }
  }

  .has-child {
    .submenu-toogle {
      position: absolute;
      right: 4px;
      top: 4px;
      color: #000;
      background-color: transparent;
      z-index: 900;
      cursor: pointer;
      padding: 10px;
      font-size: 16px;
      display: none;
    }

    li .submenu-toogle {
      opacity: 0.9;
    }

    &.nav-active > a + .submenu-toogle {
      &.glyphicon-chevron-right:before {
        content: "\e114";
      }

      &.glyphicon-plus::before {
        content: "−";
      }
    }
  }

  .sticky-no .main-bar {
    position: static !important;
  }

  .is-fixed {
    .main-bar {
      position: fixed;
    }

    &.color-fill {
      .main-bar {
        background-color: #fff;
        border-radius: 10px;
      }

      .header-nav .nav > li {
        margin: 0px;
        padding: 0px;
      }
    }
  }

  &.no-fixed {
    position: relative;
  }
}

@media (max-width: 575px) {
  .header-style-3 .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up {
    padding: 6px 12px;
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .header-style-3 .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up {
    font-size: 0px;
  }
}

@media (max-width: 1024px) {
  .header-style-3 .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up i {
    font-size: 16px;
    padding-right: 0px;
  }
}

@media (max-width: 575px) {
  .header-style-3 .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job {
    padding: 6px 12px;
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .header-style-3 .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job {
    font-size: 0px;
  }
}

@media (max-width: 1024px) {
  .header-style-3 .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job i {
    font-size: 16px;
    padding-right: 0px;
  }
}

@media (max-width: 600px) {
  .header-style-3 .header-search a.header-search-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

@media (max-width: 600px) {
  .header-style-3 .extra-nav .extra-cell {
    padding-left: 0px;
  }
}

@media (max-width: 1199px) {
  .header-style-3 .logo-header {
    width: 130px;
  }
}

@media (max-width: 400px) {
  .header-style-3 .logo-header {
    width: 90px;
  }
}

@media (max-width: 991px) {
  .header-style-3 .main-bar {
    .container,
    .container-fluid {
      display: block;
    }
  }
}

@media (max-width: 540px) {
  .header-style-3 .navbar-toggler {
    margin-left: 0px;
  }
}

@media (max-width: 991px) {
  .header-style-3 .navbar-toggler {
    display: block;
    margin-top: 14px;
    margin-bottom: 14px;
    float: right;
    outline: none !important;
  }
}

@media (max-width: 991px) {
  .header-style-3 .navbar-toggler {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}

@media (max-width: 540px) {
  .header-style-3 .is-fixed .navbar-toggler {
    margin-bottom: 0px;
  }
}

@media (max-width: 991px) {
  .header-style-3 .header-nav {
    clear: both;
    margin: 0px -15px;
    border-bottom: 1px solid #e9e9e9;

    &.navbar-collapse {
      align-items: flex-start;
    }
  }
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav {
    float: none;
    margin: 0px;
    background: #fff;
    display: block;
    width: 100%;
    left: 0px;
  }
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav li {
    float: none;
  }
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav li i {
    float: right;
  }
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav > li ul {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f9f9f9;
  }
}

@media (max-width: 1199px) {
  .header-style-3 .header-nav .nav > li > a {
    padding: 12px 12px;
  }
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav > li {
    &.active > a,
    &.current-menu-item > a {
      color: #000;
    }
  }
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav > li > a {
    &:hover,
    &:active,
    &:focus {
      background-color: #f0f0f0;
      text-decoration: none;
    }
  }
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav > li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f9f9f9;
    opacity: 1;
    margin: 0px !important;
  }
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav > li .sub-menu li > .sub-menu > li {
    float: none;
    display: block;
    width: auto;
  }
}

@media (max-width: 991px) {
  .header-style-3 .header-nav .nav > li .sub-menu li > .sub-menu > li a {
    padding-left: 30px;
  }
}

@media (max-width: 991px) {
  .header-style-3 .has-child {
    position: relative;
  }
}

@media (max-width: 991px) {
  .header-style-3 .has-child .submenu-toogle {
    display: block;
  }
}

@media (max-width: 991px) {
  .header-style-3 .has-child.nav-active > a + .submenu-toogle.fa.fa-angle-right::before {
    content: "\f107";
  }
}

@media (max-width: 991px) {
  .header-style-3 .mobile-full-width-menu .header-nav .nav {
    position: inherit;
  }
}

@media (max-width: 991px) {
  .header-style-3.mobile-sider-drawer-menu {
    .header-nav {
      position: fixed;
      width: 250px !important;
      z-index: 999;
      height: 100vh !important;
      overflow-y: inherit !important;
      left: -250px;
      top: 0px;
      transition: all 0.5s linear;
      background-color: #fff;

      .nav > li {
        &:hover > .sub-menu,
        .sub-menu li:hover > .sub-menu {
          animation: none !important;
        }
      }
    }

    &.active .header-nav {
      left: 15px;
    }

    .icon-bar {
      transition: all 0.5s linear;
      position: relative;
    }

    #mobile-side-drawer {
      min-height: 41px;
      position: relative;
    }

    &.active .icon-bar {
      &.icon-bar-first {
        transform: rotate(45deg) translateX(3px) translateY(2px);
      }

      &.icon-bar-three {
        transform: rotate(-45deg) translateX(3px) translateY(-2px);
      }
    }

    .icon-bar.icon-bar-two {
      position: relative;
      right: 0%;
    }

    &.active .icon-bar.icon-bar-two {
      position: absolute;
      right: 100px;
      opacity: 0;
    }

    .is-fixed .header-nav .nav,
    .header-fixed .header-nav .nav {
      overflow: auto;
      max-height: 100%;
    }
  }
}

@media (max-width: 991px) {
  .header-style-3 {
    .header-nav .nav > li {
      margin: 0px;
      padding: 0px;

      > a {
        margin: 0px;
        padding: 10px 12px;
      }
    }

    .is-fixed.color-fill .header-nav .nav > li {
      margin: 0px;
      padding: 0px;
    }
  }
}

@media (max-width: 767px) {
  .header-style-3 {
    .main-bar {
      padding: 0px;
    }

    .is-fixed {
      .main-bar,
      &.color-fill .main-bar {
        padding: 0px;
      }
    }
  }
}

.header-style-light {
  position: relative;

  /*--is fixed---*/
  /*====Mobile Side =======*/
  /*---when header is sticky---*/
  /*---when header is sticky---*/
  /*---header id Fixed---*/

  .header-nav-btn-section {
    float: right;
    display: flex;

    .twm-nav-btn-left {
      margin-right: 10px;

      .twm-nav-sign-up {
        background-color: #dbe6f7;
        color: #1967d2;
        font-size: 16px;
        border-radius: 10px;
        padding: 8px 15px;
        display: block;
        white-space: nowrap;

        i {
          padding-right: 5px;
        }
      }
    }

    .twm-nav-btn-right {
      margin-right: 10px;

      .twm-nav-post-a-job {
        background-color: #17171d;
        color: #dbe6f7;
        font-size: 16px;
        border-radius: 10px;
        padding: 8px 15px;
        display: block;
        white-space: nowrap;

        i {
          padding-right: 5px;
        }
      }
    }
  }

  .header-search a.header-search-icon {
    color: #fff;
    width: 54px;
    height: 54px;
    line-height: 54px;
    display: block;
    text-align: center;
    font-size: 24px;
    font-weight: normal;
    border-radius: 50%;
  }

  .extra-nav {
    /*---Extra Nav---*/
    display: table;
    float: right;
    height: 70px;
    position: relative;
    z-index: 13;

    .extra-cell {
      display: table-cell;
      padding-left: 25px;
      vertical-align: middle;

      &:first-child {
        padding-left: 0px;
      }
    }
  }

  .logo-header {
    /*--logo for header---*/
    display: table;
    float: left;
    width: 190px;
    height: 70px;
    vertical-align: middle;
    padding: 0px;
    font-size: 36px;
    color: #000;
    margin: 0px;
    position: relative;
    z-index: 11;

    .logo-header-inner {
      display: table-cell;
      vertical-align: middle;
      height: 100%;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &.site-header {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
    z-index: 999;

    ul,
    ol {
      margin-bottom: 0px;
    }
  }

  .main-bar {
    /*--main-bar--*/
    position: relative;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: all 0.5s ease;
    background: transparent;
    max-width: 1345px;
    left: 50%;
    transform: translateX(-50%);

    .container,
    .container-fluid {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &:after {
      clear: both;
      content: "";
      display: table;
    }
  }

  .navbar-toggler {
    /*--Navbar Toggler---*/
    border: none;
    margin: 24px 0px 24px 15px;
    padding: 12px 10px;

    &:focus {
      box-shadow: none !important;
    }

    .icon-bar {
      background: #fff;
      height: 3px;
      border-radius: 0px;
      display: block;
      width: 22px;

      + .icon-bar {
        margin-top: 4px;
      }
    }
  }

  .is-fixed.mobile-sider-drawer-menu .header-nav .nav {
    height: 100vh !important;
    width: 100%;

    > li > a {
      padding: 12px 15px !important;
    }
  }

  .header-nav {
    position: relative;
    padding: 0px;
    z-index: 10;

    /*-- Submenu direction---*/

    .nav {
      display: flex;
      justify-content: flex-end;
      flex-direction: inherit;

      li i {
        font-size: 9px;
        margin-left: 3px;
        margin-top: -3px;
        vertical-align: middle;
        opacity: 0.7;
      }

      > li {
        position: relative;
        margin: -10px 0px;
        padding: 10px 0px;
        // font-family: "Josefin Sans", sans-serif;

        > a {
          color: #fff;
          font-size: 16px;
          padding: 15px 16px;
          cursor: pointer;
          margin: 0px 0px;
          font-weight: 400;
          display: block;

          &:hover {
            background-color: transparent;
            opacity: 0.8;
          }

          &:active,
          &:focus {
            background-color: transparent;
          }
        }

        &.active > a,
        &.current-menu-item > a {
          background-color: transparent;
          color: #000;
        }

        &:hover > {
          a {
            opacity: 0.8;
          }

          .sub-menu {
            opacity: 1;
            visibility: visible;
            margin-top: 0px;
            transition: all 0.3s ease;
          }
        }

        .sub-menu {
          background-color: #fff;
          display: block;
          left: 0px;
          top: 100%;
          list-style: none;
          margin: 20px 0 0;
          opacity: 0;
          padding: 0px;
          position: absolute;
          visibility: hidden;
          width: 220px;
          z-index: 10;
          transition: none !important;

          li {
            border-bottom: 1px solid #f4f4f4;
            position: relative;
            text-transform: none;

            a {
              color: #000;
              display: block;
              font-size: 13px;
              padding: 14px 20px;
              padding-left: 20px;
              font-weight: 500;

              &:hover {
                color: #000;
                text-decoration: none;
              }
            }

            &:hover > a {
              color: #1967d2;
            }

            &:last-child {
              border-bottom: 0px;
            }

            .fa {
              color: #000;
              display: block;
              float: right;
              position: absolute;
              right: 10px;
              top: 2px;
            }

            > .sub-menu {
              left: 220px;
              margin: 0px 0px 0px 20px;
              transition: all 0.1s ease;
            }

            &:hover > .sub-menu {
              left: 220px;
              margin: 0px;
              opacity: 1;
              top: -1px;
              visibility: visible;

              &:before {
                background-color: transparent;
                bottom: 0px;
                content: "";
                display: block;
                height: 100%;
                left: -6px;
                position: absolute;
                top: 0px;
                width: 6px;
              }
            }
          }
        }
      }
    }

    .submenu-direction .sub-menu {
      left: auto;
      right: 0px;

      li {
        > .sub-menu {
          left: auto;
          right: 220px;
          margin: 0px 20px 0px 0px;
        }

        &:hover > .sub-menu {
          left: auto;
          right: 220px;
          margin: 0px 0px 0px 0px;
        }
      }
    }

    &.nav-animation .nav > li {
      > ul.sub-menu li > a {
        transition: all 0.5s ease-out;
        position: relative;
        z-index: 1;
      }

      &:hover > ul.sub-menu li:hover > a {
        padding-left: 40px;
      }
    }
  }

  .has-child {
    .submenu-toogle {
      position: absolute;
      right: 4px;
      top: 4px;
      color: #000;
      background-color: transparent;
      z-index: 900;
      cursor: pointer;
      padding: 10px;
      font-size: 16px;
      display: none;
    }

    li .submenu-toogle {
      opacity: 0.9;
    }

    &.nav-active > a + .submenu-toogle {
      &.glyphicon-chevron-right:before {
        content: "\e114";
      }

      &.glyphicon-plus::before {
        content: "−";
      }
    }
  }

  .sticky-no .main-bar {
    position: static !important;
  }

  .is-fixed {
    .main-bar {
      position: fixed;
    }

    &.color-fill {
      .main-bar {
        background-color: #fff;
        border-radius: 10px;
      }

      .header-nav .nav > li {
        margin: 0px;
        padding: 0px;
      }
    }
  }

  .on-scroll-show {
    display: none;
  }

  .is-fixed.color-fill {
    .on-scroll-show {
      display: block;
    }

    .default-scroll-show {
      display: none;
    }
  }

  .color-fill {
    .navbar-toggler .icon-bar {
      background: #1967d2;
    }

    .header-nav .nav li a {
      color: #17171d;
    }
  }
}

@media (max-width: 575px) {
  .header-style-light .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up {
    padding: 6px 12px;
    font-size: 16px;
  }
}

@media (max-width: 540px) {
  .header-style-light .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up {
    font-size: 0px;
  }
}

@media (max-width: 540px) {
  .header-style-light .header-nav-btn-section .twm-nav-btn-left .twm-nav-sign-up i {
    font-size: 16px;
    padding-right: 0px;
  }
}

@media (max-width: 575px) {
  .header-style-light .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job {
    padding: 6px 12px;
    font-size: 16px;
  }
}

@media (max-width: 540px) {
  .header-style-light .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job {
    font-size: 0px;
  }
}

@media (max-width: 540px) {
  .header-style-light .header-nav-btn-section .twm-nav-btn-right .twm-nav-post-a-job i {
    font-size: 16px;
    padding-right: 0px;
  }
}

@media (max-width: 540px) {
  .header-style-light .extra-nav .extra-cell {
    padding-left: 0px;
  }
}

@media (max-width: 1199px) {
  .header-style-light .logo-header {
    width: 130px;
  }
}

@media (max-width: 400px) {
  .header-style-light .logo-header {
    width: 90px;
  }
}

@media (max-width: 991px) {
  .header-style-light .main-bar {
    .container,
    .container-fluid {
      display: block;
    }
  }
}

@media (max-width: 540px) {
  .header-style-light .navbar-toggler {
    margin-left: 0px;
  }
}

@media (max-width: 991px) {
  .header-style-light .navbar-toggler {
    display: block;
    margin-top: 14px;
    margin-bottom: 14px;
    float: right;
    outline: none !important;
  }
}

@media (max-width: 991px) {
  .header-style-light .navbar-toggler {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}

@media (max-width: 540px) {
  .header-style-light .is-fixed .navbar-toggler {
    margin-bottom: 0px;
  }
}

@media (max-width: 991px) {
  .header-style-light .header-nav {
    clear: both;
    margin: 0px -15px;
    border-bottom: 1px solid #e9e9e9;

    &.navbar-collapse {
      align-items: flex-start;
    }
  }
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav {
    float: none;
    margin: 0px;
    background: #fff;
    display: block;
    width: 100%;
    left: 0px;
  }
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav li {
    float: none;
  }
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav li i {
    float: right;
  }
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav > li ul {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f9f9f9;
  }
}

@media (max-width: 1199px) {
  .header-style-light .header-nav .nav > li > a {
    padding: 12px 12px;
    color: #17171d;
  }
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav > li {
    &.active > a,
    &.current-menu-item > a {
      color: #000;
    }
  }
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav > li > a {
    &:hover,
    &:active,
    &:focus {
      background-color: #f0f0f0;
      text-decoration: none;
    }
  }
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav > li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f9f9f9;
    opacity: 1;
    margin: 0px !important;
  }
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav > li .sub-menu li > .sub-menu > li {
    float: none;
    display: block;
    width: auto;
  }
}

@media (max-width: 991px) {
  .header-style-light .header-nav .nav > li .sub-menu li > .sub-menu > li a {
    padding-left: 30px;
  }
}

@media (max-width: 991px) {
  .header-style-light .has-child {
    position: relative;
  }
}

@media (max-width: 991px) {
  .header-style-light .has-child .submenu-toogle {
    display: block;
  }
}

@media (max-width: 991px) {
  .header-style-light .has-child.nav-active > a + .submenu-toogle.fa.fa-angle-right::before {
    content: "\f107";
  }
}

@media (max-width: 991px) {
  .header-style-light .mobile-full-width-menu .header-nav .nav {
    position: inherit;
  }
}

@media (max-width: 991px) {
  .header-style-light.mobile-sider-drawer-menu {
    .header-nav {
      position: fixed;
      width: 250px !important;
      z-index: 999;
      height: 100vh !important;
      overflow-y: inherit !important;
      left: -250px;
      top: 0px;
      transition: all 0.5s linear;
      background-color: #fff;

      .nav > li {
        &:hover > .sub-menu,
        .sub-menu li:hover > .sub-menu {
          animation: none !important;
        }
      }
    }

    &.active .header-nav {
      left: 14px;
    }

    .icon-bar {
      transition: all 0.5s linear;
      position: relative;
    }

    #mobile-side-drawer {
      min-height: 41px;
      position: relative;
    }

    &.active .icon-bar {
      &.icon-bar-first {
        transform: rotate(45deg) translateX(3px) translateY(2px);
      }

      &.icon-bar-three {
        transform: rotate(-45deg) translateX(3px) translateY(-2px);
      }
    }

    .icon-bar.icon-bar-two {
      position: relative;
      right: 0%;
    }

    &.active .icon-bar.icon-bar-two {
      position: absolute;
      right: 100px;
      opacity: 0;
    }

    .is-fixed .header-nav .nav,
    .header-fixed .header-nav .nav {
      overflow: auto;
      max-height: 100%;
    }
  }
}

@media (max-width: 991px) {
  .header-style-light {
    .header-nav .nav > li {
      margin: 0px;
      padding: 0px;

      > a {
        margin: 0px;
        padding: 10px 12px;
      }
    }

    .is-fixed.color-fill .header-nav .nav > li {
      margin: 0px;
      padding: 0px;
    }
  }
}

@media (max-width: 767px) {
  .header-style-light {
    .main-bar {
      padding: 0px;
    }

    .is-fixed {
      .main-bar,
      &.color-fill .main-bar {
        padding: 0px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1170px;
  }
}

/*Title separators*/

.wt-small-separator {
  // font-family: "Josefin Sans", sans-serif;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  color: #1967d2;
  font-size: 18px;
  line-height: 40px;
  font-weight: 500;

  &.white {
    color: #fff;
  }
}

@media (max-width: 991px) {
  .wt-small-separator {
    font-size: 22px;
    margin-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .wt-small-separator {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0px;
  }
}

@media (max-width: 767px) {
  .wt-small-separator h2 {
    margin-bottom: 20px;
  }
}

.wt-separator-two-part-row {
  align-items: center;
}

@media (max-width: 991px) {
  .wt-separator-two-part-right {
    text-align: left;
    margin-bottom: 30px;
  }
}

/*Center title*/

.section-head {
  &.left.wt-small-separator-outer {
    text-align: left;

    h2 {
      margin-bottom: 35px;
    }

    p {
      margin-bottom: 40px;
    }
  }

  &.center.wt-small-separator-outer {
    margin-bottom: 50px;
    text-align: center;
    max-width: 645px;
    margin-left: auto;
    margin-right: auto;

    .section-head-text {
      margin: 20px 0px 0px 0px;
    }

    h2 {
      max-width: 650px;
      margin: 0px auto;
    }
  }
}

@media (max-width: 991px) {
  .section-head.left.wt-small-separator-outer h2 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .section-head.left.wt-small-separator-outer h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .section-head.left.wt-small-separator-outer h2 {
    font-size: 22px;
  }
}

@media (max-width: 991px) {
  .section-head.center.wt-small-separator-outer h2 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .section-head.center.wt-small-separator-outer h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .section-head.center.wt-small-separator-outer h2 {
    font-size: 22px;
  }
}

/*Counter*/
@media (max-width: 991px) {
  .twm-companies-wrap {
    padding-bottom: 0px !important;
  }
}

.twm-company-approch-outer {
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    background-color: #dbe6f7;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 40px;
    z-index: -1;
  }
}

.twm-company-approch {
  max-width: 650px;
  margin: 0px auto;
  background-color: #fff;
  padding: 25px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1) inset;
  position: relative;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    left: -28px;
    bottom: 0px;
    z-index: 0;
    width: 0;
    height: 0;
    border-left: 28px solid transparent;
    border-right: 0px solid transparent;
    border-top: 40px solid #b3c2d6;
  }
}

@media (max-width: 767px) {
  .twm-company-approch {
    max-width: 520px;
  }
}

.counter-outer-two {
  position: relative;

  .tw-count-number {
    font-size: 38px;
    line-height: 38px;
    font-weight: 700;
    // font-family: "Josefin Sans", sans-serif;
  }

  .counter {
    font-size: 46px;
    line-height: 50px;
    font-weight: 600;
    // font-family: "Josefin Sans", sans-serif;
  }

  .icon-content-info {
    color: #17171d;
    margin-bottom: 0px;
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .counter-outer-two {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .counter-outer-two .tw-count-number {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 991px) {
  .counter-outer-two .counter {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (max-width: 768px) {
  .counter-outer-two .counter {
    font-size: 24px;
  }
}

/*Client carousel 1*/

.home-client-carousel {
  padding: 30px 10px 10px 10px;
  background-color: #1967d2;
  z-index: 1;
  position: relative;
  bottom: -70px;
  left: 0px;

  &:after,
  &:before {
    position: absolute;
    content: "";
    width: 60px;
    height: 60px;
  }

  &:after {
    right: 10px;
    bottom: -10px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
  }

  &:before {
    left: -10px;
    top: 10px;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
  }

  &.owl-carousel .owl-dots {
    margin-top: 10px;
  }

  .client-logo a img {
    width: auto;
    height: 70px;
    margin: auto;
  }
}

@media (max-width: 991px) {
  .home-client-carousel {
    bottom: 0px;
  }
}

/*Client carousel 2*/

.home-client-carousel2 {
  padding: 0px;
  margin-bottom: 120px;

  .client-logo {
    max-width: 130px;
    margin-left: auto;
    margin-right: auto;

    a {
      height: 100px;
      display: flex;
      justify-content: space-around;

      img {
        width: auto;
        height: auto;
      }

      &:hover img {
        filter: grayscale(100%);
        transition: 0.5s all ease;
      }
    }
  }
}

@media (max-width: 991px) {
  .home-client-carousel2 {
    margin-bottom: 30px;
  }
}

/*Footer News Letter*/

.ftr-nw-content {
  margin-bottom: 50px;

  .ftr-nw-title {
    font-size: 20px;
    color: #fff;
  }

  .ftr-nw-form {
    display: flex;

    .form-control {
      height: 66px;
      border: 0px;
      background-color: #dbe6f7;
      border-radius: 10px 0px 0px 10px;
    }

    .ftr-nw-subcribe-btn {
      font-size: 16px;
      color: #fff;
      background-color: #1967d2;
      border: none;
      // font-family: "Josefin Sans", sans-serif;
      font-weight: 600;
      padding: 9px 30px;
      border-radius: 0px 10px 10px 0px;
      white-space: nowrap;
    }
  }
}

@media (max-width: 991px) {
  .ftr-nw-content {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .ftr-nw-content .ftr-nw-title {
    margin-bottom: 30px;
  }
}

@media (max-width: 400px) {
  .ftr-nw-content .ftr-nw-form {
    display: block;
  }
}

@media (max-width: 575px) {
  .ftr-nw-content .ftr-nw-form .form-control {
    height: 48px;
  }
}

@media (max-width: 400px) {
  .ftr-nw-content .ftr-nw-form .form-control {
    border-radius: 10px;
  }
}

@media (max-width: 768px) {
  .ftr-nw-content .ftr-nw-form .ftr-nw-subcribe-btn {
    font-size: 16px;
    padding: 7px 20px;
  }
}

@media (max-width: 400px) {
  .ftr-nw-content .ftr-nw-form .ftr-nw-subcribe-btn {
    border-radius: 10px;
    display: block;
    width: 100%;
    margin-top: 10px;
  }
}

/*Footer dark version css*/

.footer-dark {
  color: #fff;
  font-size: 16px;
  padding-top: 60px;
  background-size: cover;

  p {
    font-weight: 300;
  }

  .logo-footer {
    margin-bottom: 25px;
    max-width: 174px;
  }

  // .footer-top {
  //   border-top: 1px solid rgba(255, 255, 255, 0.1);
  //   border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  //   padding: 50px 0px 30px 0px;
  // }

  .ftr-list {
    list-style: none;

    li p {
      margin-bottom: 0px;
      font-size: 16px;

      span {
        margin-right: 5px;
        font-weight: 500;
      }
    }
  }

  .widget-title {
    // font-family: "Josefin Sans", sans-serif;
    color: #dbe6f7;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 30px;
    padding-bottom: 23px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0px;
      background-color: #1967d2;
      width: 40px;
      height: 3px;
    }
  }

  .social-icons {
    margin: 0px;

    li {
      display: inline-block;

      a {
        height: 45px;
        width: 45px;
        line-height: 45px;
        padding: 0px;
        color: #fff;
        font-size: 22px;
        text-align: center;
        margin-right: 3px;

        &:hover {
          color: #dbe6f7;
        }
      }
    }
  }

  .footer-bottom {
    padding: 0px;
    color: #fff;
    position: relative;
    z-index: 1;
    font-weight: 400;

    .footer-bottom-info {
      display: flex;
      justify-content: space-between;
      padding: 30px 0px;
      align-items: center;
    }

    .footer-copy-right .copyrights-text {
      color: #fff;
      font-size: 16px;
    }
  }
}

@media (max-width: 991px) {
  .footer-dark {
    padding-top: 40px;
  }
}

@media (max-width: 991px) {
  .footer-dark .footer-top {
    padding-top: 40px;
  }
}

@media (max-width: 991px) {
  .footer-dark .footer-top {
    padding-bottom: 0px;
  }
}

@media (max-width: 991px) {
  .footer-dark .footer-bottom .footer-bottom-info {
    display: block;
  }
}

@media (max-width: 991px) {
  .footer-dark .footer-bottom .footer-copy-right {
    margin: 5px 10px 5px 0px;
    display: inline-block;
  }
}

.footer-top .widget {
  margin-bottom: 20px;
}

/*Footer Light version css*/

.footer-light {
  color: #5a5a5a;
  font-size: 14px;
  background-color: #eaedf0;

  .ftr-nw-content {
    margin-bottom: 50px;

    .ftr-nw-title {
      font-size: 20px;
      color: #17171d;
    }

    .ftr-nw-form {
      display: flex;

      .form-control {
        height: 66px;
        border: 0px;
        background-color: #fff;
        // border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      .ftr-nw-subcribe-btn {
        font-size: 16px;
        color: #fff;
        background-color: #1967d2;
        border: none;
        // font-family: "Josefin Sans", sans-serif;
        font-weight: 600;
        padding: 9px 30px;
        border-radius: 0px 10px 10px 0px;
        white-space: nowrap;
      }
    }
  }

  p {
    font-weight: 400;
  }

  .logo-footer {
    margin-bottom: 25px;
    max-width: 216.08px;
  }

  .footer-top {
    // border-top: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid #1212121a;
    padding: 30px 0px 30px 0px;
  }

  .ftr-list {
    list-style: none;

    li p {
      margin-bottom: 0px;
      font-size: 16px;

      span {
        margin-right: 5px;
        font-weight: 500;
      }
    }
  }

  .widget-title {
    // font-family: "Josefin Sans", sans-serif;
    color: #92388e;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 6px;
    padding-bottom: 6px;
    position: relative;

    &:before {
      content: "";
      font-size: 12px;
      position: absolute;
      bottom: 0px;
      left: 0px;
      // background-color: #17171d;
      width: 40px;
      height: 3px;
    }
  }

  .social-icons {
    margin: 0px;

    li {
      display: inline-block;

      a {
        height: 45px;
        width: 45px;
        line-height: 45px;
        padding: 0px;
        color: #1967d2;
        font-size: 22px;
        text-align: center;
        margin-right: 3px;

        &:hover {
          color: #17171d;
        }
      }
    }
  }

  .footer-bottom {
    padding: 0px;
    color: #fff;
    position: relative;
    z-index: 1;
    font-weight: 400;

    .footer-bottom-info {
      display: flex;
      justify-content: space-between;
      padding: 10px 0px;
      align-items: center;
    }

    .footer-copy-right .copyrights-text {
      color: #5a5a5a;
      font-size: 14px;
    }

    .footer-copy-right a:hover {
      color: #92388e;
      font-weight: 600;
    }
  }

  .widget_services ul li a {
    color: #232323;

    &:hover {
      color: #92388e;
    }
  }
}

@media (max-width: 768px) {
  .footer-light .ftr-nw-content .ftr-nw-form .ftr-nw-subcribe-btn {
    font-size: 14px;
    padding: 7px 20px;
  }
}

@media (max-width: 991px) {
  .footer-light .footer-bottom .footer-bottom-info {
    display: block;
  }
}

@media (max-width: 991px) {
  .footer-light .footer-bottom .footer-copy-right {
    margin: 5px 10px 5px 0px;
    display: inline-block;
  }
}

.widget {
  margin-bottom: 40px;
}

/*Footer Newsletter*/

.ftr-list-center {
  display: grid;
  justify-content: center;
}

@media (max-width: 991px) {
  .ftr-list-center {
    justify-content: flex-start;
  }
}

/*Recent Post Entry*/

.recent-posts-entry {
  .widget-post {
    margin-bottom: 20px;

    &:last-child {
      border: none;
      margin-bottom: 0px;
    }
  }

  .wt-post-media {
    width: 90px;
    float: left;
  }

  .wt-post-info {
    margin-left: 105px;

    .wt-post-header {
      margin-bottom: 6px;
    }

    .wt-post-meta ul {
      list-style: none;
      margin-bottom: 0px;

      li {
        font-size: 12px;
        line-height: 16px;
        color: #1967d2;
      }
    }

    .post-title {
      margin-top: 0px;
    }
  }
}

@media (max-width: 1024px) {
  .recent-posts-entry .wt-post-media {
    width: 60px;
  }
}

@media (max-width: 1024px) {
  .recent-posts-entry .wt-post-info {
    margin-left: 70px;
  }
}

/*Widget Services*/

.widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    display: block;
    width: 100%;
    padding: 2px 0px;
    border: 0px;
    overflow: hidden;

    a {
      color: #fff;
      position: relative;
      // padding: 0px 0px 5px 0px;
      display: block;
      margin-left: 0px;
      transition: 0.5s all ease;
      font-size: 12px;
      font-weight: 300;

      &:hover {
        color: #92388e;
      }
    }
  }
}

/*scroll top btn css*/

button.scroltop {
  height: 40px;
  width: 40px;
  background: #1d1b18;
  border: none;
  position: fixed;
  right: 15px;
  bottom: 15px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: none;
  text-align: center;
  z-index: 999;
  color: #fff;

  span {
    display: block;
    font-size: 24px;
    line-height: 24px;
  }
}

/*Pricing Table*/

.pricing-table-1 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  padding: 50px;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: "";
    width: 400px;
    height: 400px;
    border-radius: 50%;
    left: -50px;
    top: -240px;
    z-index: -1;
    background-color: #dbe6f7;
    position: absolute;
  }

  &.circle-yellow:after {
    background-color: #faf1de;
  }

  &.circle-pink:after {
    background-color: #f3e9f5;
  }

  .p-table-title {
    position: relative;

    .wt-title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0px;
      color: #1967d2;
      display: inline-block;
    }
  }

  .p-table-inner {
    position: relative;

    .p-table-list ul {
      list-style: none;
      margin-bottom: 40px;

      li {
        position: relative;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
        padding-left: 46px;

        i {
          color: #1967d2;
          position: absolute;
          left: 8px;
          top: 4px;
          font-size: 28px;
        }

        &.disable {
          color: #aaaaaa;

          i {
            color: #aaaaaa;
          }
        }
      }
    }

    .p-table-btn {
      text-align: center;
    }

    .p-table-price {
      margin-bottom: 50px;
      display: flex;
      align-items: center;
      color: #17171d;

      span {
        // font-family: "Josefin Sans", sans-serif;
        font-size: 38px;
        line-height: 56px;
        font-weight: 600;
        display: block;
        padding-right: 15px;
      }

      p {
        font-size: 18px;
        margin-bottom: 0px;
        font-weight: 600;
        // font-family: "Josefin Sans", sans-serif;
      }
    }
  }

  .p-table-recommended {
    background-color: #2db346;
    color: #fff;
    border-radius: 5px;
    padding: 2px 16px;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

@media (max-width: 575px) {
  .pricing-table-1 {
    padding: 30px;
  }
}

@media (max-width: 575px) {
  .pricing-table-1:after {
    top: -270px;
  }
}

@media (max-width: 767px) {
  .pricing-table-1 .p-table-inner .p-table-price {
    margin-bottom: 60px;
  }
}

@media (max-width: 991px) {
  .pricing-table-1 .p-table-inner .p-table-price span {
    font-size: 36px;
    line-height: 26px;
  }
}

/*inner page banner*/

.wt-bnr-inr {
  height: 390px;
  background-size: cover;
  background-position: center center;
  display: table;
  width: 100%;
  position: relative;

  .container {
    display: table;
    height: 100%;
  }
}

@media (max-width: 767px) {
  .wt-bnr-inr {
    height: 380px;
  }
}

.wt-bnr-inr-entry {
  display: table-cell;
  vertical-align: bottom;
  text-align: center;
  padding-bottom: 30px;

  .banner-title-outer {
    position: relative;

    .wt-title {
      font-size: 28px;
      margin-bottom: 0px;
      position: relative;
    }

    .banner-title-name {
      display: inline-block;
      margin-bottom: 30px;

      .wt-title {
        color: #17171d;
      }
    }
  }
}

/*Breadcrumb*/

.wt-breadcrumb {
  margin: 0;
  display: inline-block;
  list-style: none;
  position: relative;

  li {
    padding-right: 30px;
    position: relative;
    display: inline-block;
    font-size: 18px;
    // font-family: "Josefin Sans", sans-serif;
    font-weight: 400;
    color: #1967d2;

    a {
      color: #17171d;

      &:hover {
        color: #1967d2;
      }
    }

    &:after {
      content: "";
      position: absolute;
      right: 8px;
      width: 8px;
      height: 2px;
      background-color: #17171d;
      top: 50%;
      transform: translateY(-50%);
      color: #17171d;
      font-size: 12px;
      font-weight: 600;
    }

    &:last-child {
      color: #1967d2;
      padding-right: 0px;

      &:after {
        display: none;
      }
    }

    &:first-child a i {
      font-size: 18px;
      vertical-align: text-top;
    }
  }
}

/*Contact Form*/

.twm-contact-one {
  position: relative;

  .contact-one-inner {
    padding-top: 120px;
    padding-bottom: 120px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 420px;
      right: -15px;
      top: -40px;
      bottom: -40px;
      background-color: #dbe6f7;
      z-index: -1;
    }
  }

  .contact-info-wrap {
    margin-left: 100px;
    margin-right: 25px;
    position: relative;
    z-index: 1;

    .contact-info {
      border-radius: 10px 10px 10px 0px;
      box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
      padding: 40px;
      background-color: #fff;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0px;
      bottom: -57px;
      z-index: -1;
      width: 0;
      height: 0;
      border-left: 57px solid transparent;
      border-right: 0px solid transparent;
      border-top: 57px solid #d8d8d8;
    }
  }

  .contact-info-section .c-info-column {
    padding: 0px 0px 0px 100px;
    color: #17171d;
    position: relative;
    min-height: 75px;
    margin-bottom: 90px;

    &:last-child {
      margin-bottom: 0px;
    }

    p {
      font-size: 16px;
    }

    .c-info-icon {
      position: absolute;
      font-size: 22px;
      left: 0px;
      top: 0px;
      color: #1967d2;
      width: 72px;
      height: 72px;
      line-height: 72px;
      border-radius: 10px;
      text-align: center;
      background-color: #dbe6f7;
      z-index: 1;

      &.custome-size {
        font-size: 26px;
      }
    }

    span {
      font-weight: 600;
      font-size: 18px;
      display: block;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 0px;

      a {
        color: #17171d;
      }
    }
  }

  .section-head-small {
    font-size: 20px;
    font-weight: 500;

    &.white .wt-title {
      color: #fff;
    }
  }

  .contact-form-outer .section-head.left.wt-small-separator-outer {
    text-align: left;

    h2 {
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 991px) {
  .twm-contact-one .contact-one-inner {
    padding-top: 38px;
    padding-bottom: 0px;
  }
}

@media (max-width: 1200px) {
  .twm-contact-one .contact-one-inner:after {
    width: 394px;
  }
}

@media (max-width: 991px) {
  .twm-contact-one .contact-one-inner:after {
    display: none;
  }
}

@media (max-width: 1200px) {
  .twm-contact-one .contact-info-wrap {
    margin-left: 20px;
  }
}

@media (max-width: 991px) {
  .twm-contact-one .contact-info-wrap {
    margin: 0px;
    padding-top: 38px;
    padding-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .twm-contact-one .contact-info-wrap .contact-info {
    border-radius: 10px;
  }
}

@media (max-width: 400px) {
  .twm-contact-one .contact-info-wrap .contact-info {
    padding: 20px;
  }
}

@media (max-width: 991px) {
  .twm-contact-one .contact-info-wrap:before {
    display: none;
  }
}

@media (max-width: 991px) {
  .twm-contact-one .contact-info-section .c-info-column {
    margin-bottom: 30px;
  }
}

@media (max-width: 400px) {
  .twm-contact-one .contact-info-section .c-info-column {
    padding: 0px 0px 0px 65px;
  }
}

@media (max-width: 400px) {
  .twm-contact-one .contact-info-section .c-info-column .c-info-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

.cons-contact-form {
  .form-control {
    height: 56px;
    padding: 10px 20px;
    background-color: #dbe6f7;
    border: 0px;
    border-radius: 10px;

    &:focus {
      box-shadow: none;
      background-color: #f5f7f9;
    }
  }

  textarea.form-control {
    padding-top: 15px;
    height: 80px;
    margin-bottom: 40px;
    resize: none;
  }
}

// .form-control {
//   width: 100%;
//   height: 60px;
//   padding: 20px;
//   border: none;
//   background-color: #dbe6f7;
//   border-radius: 10px;

//   &:focus {
//     box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.6);
//     background-color: #F8F8F8;
//   }
// }

// .form-group {
//   margin-bottom: 30px;
// }

/*Pagination Style*/

.pagination-outer {
  padding-top: 30px;

  .pagination-style1 {
    position: relative;
    display: inline-block;

    ul {
      list-style: none;
      margin-bottom: 0px;

      li {
        position: relative;
        margin: 0px 3px 0px;
        display: inline-block;

        &.prev a,
        &.next a {
          width: 50px;
          height: 50px;
          font-size: 16px;
          color: #17171d;
          font-weight: 500;
          transition: 0.5s all ease;
          letter-spacing: 1px;
        }

        a {
          position: relative;
          width: 50px;
          height: 50px;
          color: #17171d;
          font-size: 16px;
          line-height: 50px;
          text-align: center;
          display: inline-block;
          transition: 0.5s all ease;
          // font-family: "Josefin Sans", sans-serif;
          font-weight: 400;
        }

        &.active a,
        &:hover a {
          background-color: #1967d2;
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .pagination-outer {
    margin-bottom: 30px;
  }
}

@media (max-width: 400px) {
  .pagination-outer .pagination-style1 ul li {
    margin: 0px 2px 0px;
  }
}

@media (max-width: 420px) {
  .pagination-outer .pagination-style1 ul li.prev a {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 420px) {
  .pagination-outer .pagination-style1 ul li.next a {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .pagination-outer .pagination-style1 ul li a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

/*Blog Side Bar*/

.side-bar {
  background-color: #f8f8f8;
  padding: 30px;
  border-radius: 10px;

  .widget {
    &:last-child {
      margin-bottom: 0px;
    }

    .widget-title {
      position: relative;
      padding-bottom: 15px;
      text-align: center;

      &:before {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #1967d2;
        width: 20px;
        height: 2px;
      }
    }
  }

  .search-bx .input-group {
    .form-control {
      height: 60px;
      background-color: #fff;
      font-size: 20px;
      border: none;
      padding: 0px 25px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .btn {
      color: #17171d;
      border-radius: 0px;
      height: 60px;
      padding: 0px 15px;
      font-size: 18px;
      background-color: #fff;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .widget_services ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    background-color: #fff;

    li {
      position: relative;
      padding: 10px 0px 10px 15px;
      line-height: 20px;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;

      &:after {
        content: "";
        width: 4px;
        height: 5px;
        border-radius: 50%;
        background-color: #1967d2;
        position: absolute;
        left: 0px;
        top: 17px;
      }

      &:last-child {
        padding-bottom: 0px;
      }

      a {
        color: #17171d;
        margin-left: 0px;
        padding: 0px;
        display: inline-block;
        // font-family: "Josefin Sans", sans-serif;
        font-size: 16px;
        font-weight: 600;

        &:hover {
          color: #1967d2;
        }

        i {
          padding-right: 5px;
        }

        &:before {
          display: none;
        }
      }

      .badge {
        background: none;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .recent-posts-entry {
    .widget-post {
      margin-bottom: 20px;

      &:last-child {
        border: none;
        margin-bottom: 0px;
      }
    }

    .wt-post-media {
      width: 72px;
      float: left;

      img {
        border-radius: 10px;
      }
    }

    .wt-post-info {
      margin-left: 92px;

      .post-date {
        margin-top: 0px;
        color: #1967d2;
        font-size: 16px;
        display: block;
      }

      .post-title {
        margin-top: 0px;
        font-size: 16px;
        display: block;
      }
    }
  }
}

@media (max-width: 991px) {
  .side-bar {
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .side-bar {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .side-bar .search-bx .input-group .form-control {
    height: 60px;
  }
}

@media (max-width: 420px) {
  .side-bar .recent-posts-entry .wt-post-info {
    margin-left: 95px;
  }
}

/*Side bar tags*/

.tw-sidebar-tags-wrap .tagcloud {
  margin-top: -10px;

  a {
    padding: 5px 15px;
    margin-top: 10px;
    margin-right: 10px;
    display: inline-block;
    color: #1967d2;
    border-radius: 6px;
    font-size: 16px;
    background-color: #dbe6f7;

    &:hover {
      background-color: #1967d2;
      color: #fff;
    }
  }
}

/*Comment list section*/

ol.comment-list {
  list-style: none;

  li {
    &.comment {
      position: relative;
      padding: 0;

      .comment-body {
        padding: 0px 90px 20px 120px;
        position: relative;
        margin-bottom: 30px;
        overflow: hidden;
        min-height: 130px;
      }

      .comment-author {
        display: inline-block;
        margin-bottom: 5px;
        position: absolute;
        top: 0px;
        left: 0px;

        .avatar {
          width: 90px;
          height: 90px;
          transition: all 0.5s ease-in-out;
          border-radius: 10px;
        }
      }

      .fn {
        display: inline-block;
        color: #17171d;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        margin-bottom: 10px;
        // font-family: "Josefin Sans", sans-serif;
      }

      .comment-meta {
        margin-bottom: 5px;
        display: block;
        text-align: center;

        a {
          color: #818181;
          font-size: 12px;
          text-align: center;
        }
      }

      .reply {
        position: absolute;
        right: 0px;
        top: 0px;

        .comment-reply-link {
          position: relative;
          color: #1967d2;
          padding: 4px 12px;
          font-size: 16px;
          display: inline-block;

          &:hover {
            color: #17171d;
          }
        }
      }

      p {
        margin: 0px;
        font-weight: 400;
      }
    }

    .children {
      list-style: none;
      margin-left: 40px;

      li {
        padding: 0;

        &:last-child .comment-body {
          margin-bottom: 0px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  ol.comment-list li.comment .comment-body {
    padding: 0px 0px 50px 0px;
  }
}

@media (max-width: 480px) {
  ol.comment-list li.comment .comment-author {
    position: inherit;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  ol.comment-list li.comment .fn {
    display: block;
  }
}

@media (max-width: 480px) {
  ol.comment-list li.comment .reply {
    top: auto;
    right: auto;
    bottom: 10px;
    left: 0px;
  }
}

@media (max-width: 480px) {
  ol.comment-list li.comment .reply .comment-reply-link {
    padding: 4px 0px;
  }
}

@media (max-width: 767px) {
  ol.comment-list li .children {
    margin-left: 0px;
  }
}

/*comment form*/
@media (max-width: 991px) {
  .comment-respond {
    margin-bottom: 40px;
  }
}

@media (max-width: 480px) {
  .comment-respond {
    padding: 0px;
  }
}

.comment-respond {
  .comment-reply-title {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .form-submit .site-button {
    position: relative;
  }
}

/*Comment area*/

.comments-area {
  padding: 0;

  .comments-title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .comment-form {
    padding: 30px;
    background-color: #f8f8f8;

    .comment-form-author label,
    .comment-form-email label,
    .comment-form-comment label {
      display: none;
      line-height: 18px;
      margin-bottom: 10px;
    }

    .comment-form-author input[type="text"],
    .comment-form-email input[type="text"],
    .comment-form-comment input[type="text"] {
      width: 100%;
      height: 60px;
      padding: 20px;
      border: 1px solid transparent;
      background-color: #dbe6f7;
      border-radius: 10px;
    }

    .comment-form-author input[type="text"].form-control:focus,
    .comment-form-email input[type="text"].form-control:focus {
      box-shadow: none;
    }

    .comment-form-comment {
      input[type="text"].form-control:focus {
        box-shadow: none;
      }

      textarea {
        width: 100%;
        padding: 20px;
        height: 120px;
        resize: none;
        border: 1px solid transparent;
        background-color: #dbe6f7;
        border-radius: 10px;

        &.form-control:focus {
          box-shadow: none;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .comments-area .comment-form {
    margin: 0 -5px;
  }
}

ol.comment-list li.comment .comment-respond .comment-form p {
  padding: 0px 15px !important;
}

.comment-form .form-control:-webkit-input-placeholder {
  color: #17171d;
  // font-size: 16px;
}

/*Single Blog Post*/

.blog-post-single-outer {
  .blog-post-single {
    .wt-post-meta-list {
      margin-bottom: 5px;
      width: 100%;
      clear: both;
      display: inline-block;

      .wt-list-content {
        float: left;
        position: relative;
        color: #17171d;
        font-weight: 500;

        &.post-date {
          margin-right: 10px;
        }

        &.post-author a {
          color: #1967d2;
          padding-left: 10px;
        }
      }
    }

    .wt-post-info {
      padding-top: 0px;
    }

    .post-title {
      font-size: 46px;
      margin-bottom: 15px;
    }

    .twm-blog-s-title {
      margin: 30px 0px;
    }

    .wt-post-media {
      img {
        width: 100%;
        border-radius: 10px;
      }

      .wt-post-meta {
        position: absolute;
        left: 0px;
        bottom: 0px;

        ul {
          background-color: #1967d2;
          display: inline-block;
          margin-bottom: 0px;

          li {
            color: #fff;
            display: inline-table;
            font-size: 16px;
            text-align: center;
            padding: 8px 10px;
            font-weight: 500;
            position: relative;

            &:after {
              position: absolute;
              content: "|";
              right: -2px;
              top: 50%;
              transform: translateY(-50%);
            }

            a {
              color: #fff;
            }

            &:last-child:after {
              display: none;
            }
          }
        }
      }
    }

    blockquote {
      background: #f8f8f8;
      margin: 30px 0px;
      padding: 50px 80px 30px 80px;
      position: relative;
      overflow: hidden;
      // font-family: "Josefin Sans", sans-serif;

      p {
        font-size: 16px;
        font-weight: 400;
        color: #17171d;
        margin-bottom: 20px;
        position: relative;

        span {
          font-size: 48px;
          line-height: 40px;
          display: inline-block;
          // font-family: "Josefin Sans", sans-serif;
          position: absolute;
          left: -20px;
          top: -20px;
        }
      }

      strong {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        color: #17171d;
      }
    }
  }

  .post-social-icons-wrap {
    text-align: center;

    h4 {
      font-size: 16px;
    }
  }
}

@media (max-width: 991px) {
  .blog-post-single-outer .blog-post-single .post-title {
    font-size: 30px;
  }
}

@media (max-width: 575px) {
  .blog-post-single-outer .blog-post-single .post-title {
    font-size: 22px;
  }
}

@media (max-width: 400px) {
  .blog-post-single-outer .blog-post-single blockquote {
    padding: 30px;
  }
}

/*Single Post Tags*/

.post-area-tags-wrap {
  margin-top: 30px;
  padding-top: 30px;
}

/*Single Post Tags*/

.post-social-icons {
  margin: 0px;

  li {
    display: inline-block;

    a {
      height: 34px;
      width: 34px;
      line-height: 34px;
      color: #818181;
      padding: 0px;
      font-size: 18px;
      text-align: center;
      margin-right: 3px;

      &:hover {
        color: #fff;
        background-color: #051721;
      }
    }
  }
}

/*Posts Author*/

.twm-posts-author {
  background-color: #f8f8f8;
  padding: 30px;
  border-radius: 10px;
  display: table;
  width: 100%;

  .twm-post-author-pic {
    width: 105px;
    height: 105px;
    display: table-cell;
    padding-right: 30px;

    img {
      border-radius: 10px;
      margin-right: 30px;
    }
  }

  .twm-post-author-content {
    display: table-cell;
    vertical-align: top;

    span {
      display: block;
      color: #1967d2;
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 10px;
    }

    strong {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 500;
      color: #17171d;
    }
  }
}

@media (max-width: 400px) {
  .twm-posts-author {
    display: block;
  }
}

@media (max-width: 400px) {
  .twm-posts-author .twm-post-author-pic {
    display: block;
  }
}

@media (max-width: 400px) {
  .twm-posts-author .twm-post-author-content {
    display: block;
  }
}

/*Single Post Navigation*/

.post-navigation {
  margin-bottom: 36px;

  .post-nav-links {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 30px 0px;
    border-top: 1px solid #dedede;
    position: relative;

    .post-nav-item {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      width: 50%;

      .nav-post-arrow {
        margin-right: 20px;

        i {
          font-size: 50px;
          line-height: 0.7;
          color: #1967d2;
        }
      }

      .nav-post-meta {
        padding-right: 50px;

        label {
          color: #616161;
          font-size: 16px;
          text-transform: uppercase;
          display: block;
          margin-bottom: 3px;
        }

        a {
          font-size: 16px;
          line-height: 24px;
          color: #17171d;
          // font-family: "Josefin Sans", sans-serif;
        }
      }

      &.nav-post-next {
        flex-direction: row-reverse;
        text-align: right;

        .nav-post-meta {
          padding-left: 50px;
          padding-right: 0px;
        }

        .nav-post-arrow {
          margin-right: 0;
          margin-left: 20px;
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .post-navigation .post-nav-links .post-nav-item {
    display: block;
  }
}

@media (max-width: 640px) {
  .post-navigation .post-nav-links .post-nav-item .nav-post-arrow {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .post-navigation .post-nav-links .post-nav-item .nav-post-meta {
    padding-right: 15px;
  }
}

@media (max-width: 480px) {
  .post-navigation .post-nav-links .post-nav-item .nav-post-meta {
    padding-right: 10px;
  }
}

@media (max-width: 640px) {
  .post-navigation .post-nav-links .post-nav-item .nav-post-meta a {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
  }
}

@media (max-width: 420px) {
  .post-navigation .post-nav-links .post-nav-item .nav-post-meta a {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    display: block;
  }
}

@media (max-width: 767px) {
  .post-navigation .post-nav-links .post-nav-item.nav-post-next .nav-post-meta {
    padding-left: 15px;
  }
}

@media (max-width: 480px) {
  .post-navigation .post-nav-links .post-nav-item.nav-post-next .nav-post-meta {
    padding-left: 10px;
  }
}

@media (max-width: 991px) {
  .section-full {
    &.p-t120,
    &.p-b90,
    &.p-tb120 {
      padding-top: 38px;
      padding-bottom: 10px;
    }
  }
}

/*Loading*/

.loading-area {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  overflow: hidden;

  .loading-box {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    z-index: 9999;
  }

  .loading-pic {
    width: 100%;
    position: absolute;
    top: 50%;
    z-index: 99999;
    text-align: center;
    transform: translateY(-50%);
  }
}

/*Loading Animation Start*/

.wrapper {
  margin: 0px auto;
  display: block;

  .cssload-loader {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 48px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    background-color: #1967d2;
    animation: 1.15s infinite linear;

    &:before,
    &:after {
      content: "";
      animation: 1.15s infinite linear;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:before {
      content: "";
      border: 7px solid #fff;
      top: 0px;
      left: 0px;
      animation-name: cssload-animation;
    }
  }
}

@keyframes cssload-animation {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(2);
  }
}

/*Description list*/

.description-list {
  margin: 30px 0px;

  li {
    padding-left: 45px;
    list-style: none;
    position: relative;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;

    &:last-child {
      margin-bottom: 0px;
    }

    i {
      background-color: #1967d2;
      color: #fff;
      line-height: 26px;
      position: absolute;
      left: 0px;
      top: 0px;
      text-align: center;
      font-size: 22px;
      width: 28px;
      height: 28px;
      line-height: 28px;
      border-radius: 50%;
    }
  }
}

@media (max-width: 575px) {
  .description-list li {
    padding-left: 30px;

    i {
      font-size: 12px;
      width: 20px;
      height: 20px;
      line-height: 20px;
    }
  }
}

/*Description list*/

.description-list-2 {
  margin: 30px 0px;

  li {
    padding-left: 45px;
    list-style: none;
    position: relative;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;

    &:last-child {
      margin-bottom: 0px;
    }

    i {
      color: #1967d2;
      line-height: 26px;
      position: absolute;
      left: 0px;
      top: 0px;
      text-align: center;
      font-size: 22px;
      width: 28px;
      height: 28px;
      line-height: 28px;
      border-radius: 50%;
    }
  }
}

/*Section Overlay*/

.overlay-wraper {
  position: relative;

  .overlay-main {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-09 {
  opacity: 0.9;
}

.overlay-wraper > {
  .container,
  .container-fluid {
    position: relative;
    z-index: 1;
  }
}

/*Services*/

.all_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    position: relative;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
    }

    a {
      transition: 0.5s all ease;
      position: relative;
      font-size: 16px;
      color: #666666;

      &:hover {
        color: #1967d2;
      }
    }

    .badge {
      float: right;
      background: none;
      color: #666666;
      font-weight: normal;
      font-size: 16px;
    }
  }
}

/*Search*/

#search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
  display: none;

  &.open {
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
    z-index: 999;
    display: block;
  }

  form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    width: 100%;
    color: #1967d2;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 30px;
    font-weight: 300;
    text-align: left;
    outline: none;
    padding: 10px;
    display: flex;
    align-items: center;

    span {
      display: block;
    }
  }

  input[type="search"] {
    background: none;
    border: none;
    padding: 0px 12px;
    outline: none;
    color: #17171d;
    font-size: 24px;
    text-align: center;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #17171d;
    }
  }

  .search-btn {
    border: none;
    background: none;
    padding: 0px 30px;
    outline: none;
    display: block;
    width: 100%;
    text-align: center;

    i {
      font-size: 36px;
      line-height: 60px;
      color: #1967d2;
    }
  }

  .close {
    position: fixed;
    top: 30px;
    right: 30px;
    border-radius: 10px;
    opacity: 1;
    font-size: 27px;
    color: #fff;
    width: 60px;
    height: 60px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    background-color: #1967d2;

    &:after,
    &:before {
      content: "";
      position: absolute;
      width: 2px;
      height: 32px;
      background-color: #fff;
      right: 28px;
      top: 16px;
    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:hover {
      &:after,
      &:before {
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 575px) {
  #search input[type="search"] {
    font-size: 16px;
  }
}

/*Google map*/

.google-map {
  width: 100%;
  height: 460px;

  iframe {
    width: 100%;
    border: 0px;
    filter: grayscale(100%);
  }
}

// .form-select {
//   height: 50px;
//   font-size: 13px;
//   line-height: 20px;
//   padding: 10px;
//   border-radius: 0px;
//   color: #6c757d;

//   option {
//     padding-left: 0px;
//   }

//   &:focus {
//     box-shadow: none;
//   }

//   &.large {
//     height: 60px;
//     font-size: 16px;
//     line-height: 20px;
//     padding: 20px;
//     color: #a0a0a0;
//     border-radius: 10px;
//     border: none;

//     option {
//       padding-left: 0px;
//     }

//     &:focus {
//       box-shadow: none;
//     }
//   }
// }

/*Gallery*/

.twm-two-part-section {
  margin-bottom: 30px;
}

.tw-sidebar-gallery ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0px -10px;

  li {
    width: 25%;

    .tw-service-gallery-thumb {
      padding: 2px;
      position: relative;
      z-index: 1;

      &:after {
        position: absolute;
        left: -2px;
        top: -2px;
        right: -2px;
        bottom: -2px;
        content: "";
        border: 2px solid #1967d2;
        border-radius: 10px;
        opacity: 0;
        z-index: -1;
      }

      &:hover:after {
        opacity: 1;
      }
    }

    a {
      display: block;
      text-align: center;
      position: relative;
      background-color: #000;
      border-radius: 10px;

      img {
        transition: 0.5s all ease;
        border-radius: 10px;
      }

      i {
        opacity: 0;
        width: 46px;
        height: 46px;
        line-height: 46px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.5s all ease;
      }
    }

    &:hover a {
      img {
        opacity: 0.3;
      }

      i {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 991px) {
  .tw-sidebar-gallery ul {
    margin: 0px -2px;
  }
}

/*Video*/

.video-section-first {
  height: 288px;
  background-size: cover;
  border-radius: 10px;
  position: relative;
}

@media (max-width: 1199px) {
  .video-section-first {
    height: 236px;
  }
}

@media (max-width: 991px) {
  .video-section-first {
    height: 266px;
  }
}

.play-now-video {
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 78px;
    width: 78px;
    text-align: center;
    line-height: 78px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    z-index: 1;
    font-size: 32px;
    padding-left: 0px;
    display: block;
    transform: translateX(-50%) translateY(-50%);
    transform-origin: center center;
    border-radius: 50%;
    box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
  }

  .ripple {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 78px;
    width: 78px;
    transform: translateX(-50%) translateY(-50%);
    transform-origin: center center;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgb(255, 255, 255);
    animation: ripple-video 3s infinite;
    z-index: 1;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 78px;
      width: 78px;
      transform: translateX(-50%) translateY(-50%);
      transform-origin: center center;
      border-radius: 50%;
      box-shadow: 0 0 0 0 rgb(255, 255, 255);
      animation: ripple-video 3s infinite;
      z-index: 1;
    }

    &:before {
      animation-delay: 0.9s;
      content: "";
      position: absolute;
    }

    &:after {
      animation-delay: 0.6s;
      content: "";
      position: absolute;
    }
  }
}

@keyframes ripple-video {
  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/*Slide Top Animation*/

.slide-top {
  animation: slide-top 2s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite alternate-reverse both;
}

@keyframes slide-top {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-50px);
  }
}

/*Zoom in Zoom Out Animation*/

.zoon-in-out {
  animation: zoom-in-zoom-out 8s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1.1, 1.1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1.1, 1.1);
  }
}

/*Up Down Animation*/

.up-down {
  animation: slide-top 1.5s ease-in-out infinite alternate-reverse both;
}

@keyframes slide-top {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

/*==================================================
	Start Work
==================================================*/
/*Working Process*/

.bg-clr-sky {
  background-color: #3898e2;
}

.bg-clr-pink {
  background-color: #bc84ca;
}

.bg-clr-green {
  background-color: #56d8b1;
}

.bg-clr-sky-light {
  background-color: #7cb5ea;
}

.bg-clr-pink-light {
  background-color: #cea7d9;
}

.bg-clr-green-light {
  background-color: #8be3c6;
}

.bg-clr-yellow-light {
  background-color: #eac87c;
}

.bg-sky-light-shadow {
  box-shadow: 0px 0px 40px rgba(124, 181, 234, 0.7);
}

.bg-pink-light-shadow {
  box-shadow: 0px 0px 40px rgba(206, 167, 217, 0.7);
}

.bg-green-light-shadow {
  box-shadow: 0px 0px 40px rgba(139, 227, 198, 0.7);
}

.bg-yellow-light-shadow {
  box-shadow: 0px 0px 40px rgba(234, 200, 124, 0.7);
}

.bg-color-pink-200 {
  background-color: #ba4693;
}

.text-clr-sky {
  color: #3898e2;
}

.text-clr-pink {
  color: #bc84ca;
}

.text-clr-green {
  color: #56d8b1;
}

.text-clr-green2 {
  color: #05ad05;
}

.text-clr-yellow {
  color: #e2b438;
}

.text-clr-yellow-2 {
  color: #ffe119;
}

.text-clr-red {
  color: #ff0000;
}

/*process steps*/

.twm-how-it-work-section .row {
  display: flex;
  justify-content: center;
}

.twm-w-process-steps {
  padding-left: 80px;
  padding-top: 60px;
  margin-bottom: 30px;
  position: relative;

  .twm-large-number {
    color: #efefef;
    font-size: 75px;
    font-weight: 800;
    line-height: 0px;
    position: absolute;
    left: 0px;
    top: 30px;
    font-family: "Poppins", sans-serif;
  }

  .twm-w-pro-top {
    margin-left: 20px;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    position: relative;

    .twm-media {
      position: absolute;
      left: -20px;
      top: 10px;
      width: 90px;
      height: 95px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      box-shadow: -5px 10px 25px rgba(56, 152, 226, 0.4);
    }

    .twm-title {
      color: #fff;
      padding-left: 80px;
      margin-bottom: 0px;
    }
  }
}

@media (max-width: 420px) {
  .twm-w-process-steps {
    padding-left: 0px;
    padding-top: 40px;
  }
}

@media (max-width: 420px) {
  .twm-w-process-steps .twm-large-number {
    position: inherit;
    top: auto;
    left: auto;
  }
}

@media (max-width: 991px) {
  .twm-w-process-steps-2-wrap {
    margin-top: 30px;
  }
}

.twm-w-process-steps-2-wrap .row [class*="col-"]:nth-child(odd) {
  margin-top: -30px;
}

.twm-w-process-steps-2 {
  padding-left: 80px;
  padding-top: 30px;
  position: relative;

  .twm-large-number {
    font-size: 60px;
    font-weight: 600;
    line-height: 50px;
    position: absolute;
    right: 15px;
    top: 15px;
    font-family: "Poppins", sans-serif;
  }

  .twm-w-pro-top {
    margin-left: 20px;
    margin-bottom: 40px;
    padding: 25px;
    border-radius: 10px;
    position: relative;

    .twm-media {
      position: absolute;
      left: -50px;
      top: 10px;
      width: 90px;
      height: 95px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      box-shadow: -5px 10px 25px rgba(56, 152, 226, 0.4);
    }

    .twm-title {
      color: #17171d;
      margin-bottom: 30px;
      margin-top: 40px;
      margin-left: 30px;
    }
  }

  p {
    margin-bottom: 0px;
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .twm-w-process-steps-2 {
    padding-left: 50px;
  }
}

@media (max-width: 991px) {
  .twm-w-process-steps-2 {
    padding-left: 30px;
  }
}

/*how it work*/

.twm-how-it-work-area2 .container > .row {
  display: flex;
  align-items: center;
}

/*testimonial style 1*/

.twm-testimonial-1-carousel {
  margin-bottom: 30px;
}

.twm-testimonial-1 {
  position: relative;

  .twm-testimonial-1-content {
    border-radius: 10px;
    display: table;
    width: 100%;
    position: relative;
    z-index: 1;

    &:after {
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      left: 100px;
      background-color: #f5f7f9;
      content: "";
      z-index: -1;
      border-radius: 10px;
    }

    .twm-testi-media {
      width: 235px;
      max-width: 235px;
      display: table-cell;
      vertical-align: bottom;

      img {
        width: auto;
      }
    }

    .twm-testi-content {
      display: table-cell;
      padding: 50px 30px 50px 30px;

      .twm-quote {
        margin-bottom: 50px;

        img {
          width: auto;
        }
      }

      .twm-testi-info {
        font-size: 18px;
        font-style: italic;
        color: #17171d;
        margin-bottom: 40px;
      }

      .twm-testi-detail {
        .twm-testi-name {
          color: #1967d2;
          font-size: 18px;
          font-weight: 500;
        }

        .twm-testi-position {
          color: #17171d;
          font-size: 16px;
          position: relative;
          display: inline-block;

          &:after {
            content: "";
            width: 20px;
            height: 1px;
            background-color: #1967d2;
            position: absolute;
            right: -30px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .twm-testimonial-1 .twm-testimonial-1-content:after {
    left: 0px;
  }
}

@media (max-width: 1199px) {
  .twm-testimonial-1 .twm-testimonial-1-content .twm-testi-media {
    width: 170px;
  }
}

@media (max-width: 575px) {
  .twm-testimonial-1 .twm-testimonial-1-content .twm-testi-content {
    padding: 0px;
  }
}

@media (max-width: 575px) {
  .twm-testimonial-1 .twm-testimonial-1-content .twm-testi-content .twm-quote {
    margin-bottom: 0px;

    img {
      width: 30px;
    }
  }
}

@media (max-width: 575px) {
  .twm-testimonial-1 .twm-testimonial-1-content {
    display: block;
    text-align: center;
    padding: 30px;

    .twm-testi-content {
      display: block;
    }

    .twm-testi-media {
      display: block;
      margin: 0px auto;
    }
  }
}

/*testimonial style 1*/

.twm-testimonial-2-carousel {
  margin-bottom: 30px;
}

.twm-testimonial-2 {
  position: relative;
  padding: 0px 15px 15px;

  .twm-testimonial-2-content {
    border-radius: 10px;

    .twm-testi-media img {
      width: auto;
      height: 220px;
    }

    .twm-testi-content {
      padding: 50px 30px 30px 30px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 20px rgba(56, 152, 226, 0.3);
      position: relative;

      .twm-quote {
        position: absolute;
        right: 30px;
        top: -45px;
        opacity: 0.3;

        img {
          width: auto;
        }
      }

      .twm-testi-info {
        font-size: 18px;
        color: #17171d;
        margin-bottom: 20px;
      }

      .twm-testi-detail {
        .twm-testi-name {
          color: #1967d2;
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 10px;
        }

        .twm-testi-position {
          color: #fff;
          background-color: #1967d2;
          font-size: 16px;
          position: relative;
          display: inline-block;
          border-radius: 0px 8px 8px 0px;
          padding: 8px 40px 8px 30px;
          margin-left: -30px;

          &:after {
            content: "";
            width: 20px;
            height: 1px;
            background-color: #fff;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

/*Blog post 1*/

.twm-blog-post-wrap-right {
  margin-left: 30px;

  .twm-blog-post-1-outer {
    margin-top: 0px;
  }
}

@media (max-width: 991px) {
  .twm-blog-post-wrap-right {
    margin-left: 0px;
  }
}

.twm-blog-post-1-outer-wrap {
  padding-top: 20px;
}

.twm-blog-post-1-outer {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 25px rgba(180, 205, 237, 0.7);
  margin-bottom: 30px;
  margin-top: 20px;

  &.shadow-none {
    box-shadow: none;
  }

  .wt-post-media {
    margin-top: -40px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 10px 25px rgba(56, 152, 226, 0.3);
    margin-bottom: 50px;

    img {
      width: 100%;
    }
  }

  .wt-post-info {
    position: relative;
    z-index: 1;

    .wt-post-meta {
      position: relative;
      margin-bottom: 20px;

      ul {
        list-style: none;
        margin-bottom: 0px;

        li {
          position: relative;
          z-index: 1;
          display: inline-block;

          &.post-date {
            background-color: #7a98bf;
            color: #fff;
            font-size: 16px;
            line-height: 28px;
            padding: 5px 15px 5px 0px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            margin-right: 10px;
            position: relative;
            z-index: 1;

            &:after {
              content: "";
              background-color: #7a98bf;
              position: absolute;
              top: 0px;
              left: -30px;
              bottom: 0px;
              width: 30px;
              z-index: -1;
            }

            &:before {
              content: "";
              position: absolute;
              left: -29px;
              bottom: -8px;
              z-index: -1;
              width: 0;
              height: 0;
              border-left: 9px solid transparent;
              border-right: 0px solid transparent;
              border-top: 8px solid #6881a2;
            }
          }

          &.post-author {
            color: #17171d;
            font-weight: 600;

            a {
              color: #1967d2;
              display: inline-block;
            }
          }
        }
      }
    }

    .wt-post-title .post-title {
      margin-bottom: 15px;
    }

    .wt-post-text p {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}

/*Blog post 2*/

.twm-blog-post-2-outer {
  position: relative;

  .wt-post-media {
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .wt-post-info {
    background-color: #1967d2;
    border-radius: 10px;
    padding: 30px;
    margin: -50px 10px 0px 10px;
    position: relative;

    .wt-post-meta {
      position: relative;
      margin-bottom: 20px;

      ul {
        list-style: none;
        margin-bottom: 0px;

        li {
          position: relative;
          z-index: 1;
          display: inline-block;

          &.post-date {
            background-color: #171d28;
            color: #fff;
            font-size: 16px;
            line-height: 28px;
            padding: 5px 30px;
            border-radius: 10px;
            position: absolute;
            z-index: 1;
            top: -48px;
            left: 0px;
          }
        }
      }
    }

    .wt-post-title .post-title {
      margin-bottom: 15px;

      a {
        color: #fff;
      }
    }
  }
}

/*BLog Post List Style*/

.twm-blog-list-style {
  display: flex;
  position: relative;
  margin-bottom: 60px;

  .wt-post-media {
    margin-bottom: 0px;
    margin-right: 30px;
    max-width: 320px;
  }

  .wt-post-info {
    position: static;

    .wt-post-meta {
      position: inherit;

      ul li.post-date {
        position: absolute;
        left: 20px;
        top: 20px;
      }
    }
  }
}

@media (max-width: 767px) {
  .twm-blog-list-style {
    display: block;
  }
}

@media (max-width: 767px) {
  .twm-blog-list-style .wt-post-media {
    max-width: 100%;
    margin-right: 0px;
    margin-bottom: 30px;
  }
}

/*Job post section*/

.twm-jobs-list-wrap {
  max-width: 910px;
  margin: 0px auto;

  ul li {
    list-style: none;
  }
}

.twm-jobs-list-style1 {
  background-color: #fff;
  border: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  position: relative;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .twm-media {
    width: 150px;
    height: 150px;
    display: inline-block;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
    margin-right: 40px;
    position: absolute;
    bottom: -20px;
    line-height: 150px;

    img {
      display: inline-block;
    }
  }

  p {
    font-size: 16px;
  }

  .twm-mid-content {
    padding-left: 180px;
  }

  .twm-right-content {
    margin-top: auto;

    .twm-job-websites {
      font-size: 16px;
    }

    .twm-jobs-browse {
      background: #92388e;
      border: 1px solid #fff;
      color: #fff;
      font-weight: 600;
      border-radius: 8px;
      padding: 10px 20px;
    }
  }

  .twm-job-title {
    .title {
      font-size: 24px;
      font-weight: 700;
      margin: 0;
      text-align: left;
    }
  }

  .twm-job-post-duration {
    color: #000000;
    opacity: 0.6;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
  }

  .twm-job-contain {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  .twm-job-row-container {
    display: flex;

    .twm-jobs-row-left {
      margin-right: 5px;
    }
  }
}

@media (max-width: 767px) {
  .twm-jobs-list-style1 {
    text-align: center;
    display: block;
    margin-bottom: 30px !important;
  }

  .twm-jobs-browse {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .twm-jobs-list-style1 .twm-media {
    position: inherit;
    bottom: inherit;
    margin-right: 0px;
    margin-bottom: 30px;
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}

@media (max-width: 767px) {
  .twm-jobs-list-style1 .twm-mid-content {
    padding-left: 0px;
  }
}

@media (max-width: 991px) {
  .twm-jobs-list-style1 .twm-media {
    bottom: inherit;
  }
}

@media (max-width: 767px) {
  .twm-jobs-list-style1 .twm-right-content {
    text-align: inherit;
    width: auto;
  }
}

.twm-jobs-category span {
  color: #fff;
  border-radius: 5px;
  padding: 5px 12px;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .twm-jobs-category span {
    display: inline-block;
  }
}

.cabdidate-de-info .twm-jobs-category span {
  color: #fff;
  border-radius: 5px;
  padding: 5px 12px;
}

@media (max-width: 767px) {
  .cabdidate-de-info .twm-jobs-category span {
    margin-top: 0px;
    display: inline-block;
  }
}

.twm-jobs-grid-style1 {
  background-color: #fff;
  border: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  position: relative;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  margin-top: 30px;

  .twm-media {
    width: 90px;
    height: auto;
    line-height: 90px;
    display: inline-block;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
    margin-right: 30px;
    position: absolute;
    top: -30px;
    overflow: hidden;

    img {
      display: block;
    }
  }

  .twm-mid-content {
    padding-top: 60px;
  }

  .twm-right-content {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .twm-job-address {
      margin-bottom: 0px;
    }

    .twm-jobs-amount {
      font-weight: 500;
      color: #17171d;
      margin: 20px 0px 10px 0px;

      span {
        color: #92388e;
      }
    }

    .twm-jobs-browse {
      font-weight: 600;
      font-size: 18px;
    }
  }

  .twm-job-post-duration {
    color: #92388e;
    font-size: 16px;
    margin-left: 120px;
  }

  .twm-jobs-category {
    position: absolute;
    right: -10px;
    top: 10px;
  }
}

@media (max-width: 420px) {
  .twm-jobs-grid-style1 {
    text-align: center;
    margin-top: 0px;
  }
}

@media (max-width: 420px) {
  .twm-jobs-grid-style1 .twm-media {
    position: inherit;
    top: inherit;
    margin: 0px auto;
  }
}

@media (max-width: 420px) {
  .twm-jobs-grid-style1 .twm-mid-content {
    padding-top: 30px;
  }
}

@media (max-width: 420px) {
  .twm-jobs-grid-style1 .twm-right-content {
    display: block;
  }
}

@media (max-width: 420px) {
  .twm-jobs-grid-style1 .twm-job-post-duration {
    display: block;
    margin: 0px;
  }
}

@media (max-width: 420px) {
  .twm-jobs-grid-style1 .twm-jobs-category {
    position: inherit;
    top: inherit;
    right: inherit;
    margin-top: 10px;
  }
}

/*Employer List*/

.twm-employer-list-wrap ul {
  margin: 0px;

  li {
    list-style: none;
  }
}

.twm-employer-list-style1 {
  background-color: #fff;
  border: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  position: relative;
  padding: 25px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .twm-media {
    width: 150px;
    height: 150px;
    display: inline-block;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
    margin-right: 40px;
    position: absolute;
    bottom: -20px;
    line-height: 150px;

    img {
      display: inline-block;
    }
  }

  p {
    font-size: 16px;
  }

  .twm-mid-content {
    padding-left: 180px;
  }

  .twm-right-content {
    text-align: center;
    width: 100px;

    .twm-job-address {
      margin-bottom: 0px;
    }

    .twm-jobs-vacancies {
      font-weight: 500;
      color: #17171d;
      margin: 20px 0px 10px 0px;
      font-size: 16px;

      span {
        color: #00aa18;
        font-size: 18px;
        font-weight: 600;
        display: block;
        text-align: center;
      }
    }

    .twm-job-websites {
      font-size: 16px;
    }
  }
}

@media (max-width: 575px) {
  .twm-employer-list-style1 {
    display: block;
    text-align: center;
    margin-bottom: 30px !important;
  }
}

@media (max-width: 575px) {
  .twm-employer-list-style1 .twm-media {
    position: inherit;
    bottom: inherit;
    margin: 0px 0px 30px 0px;
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}

@media (max-width: 575px) {
  .twm-employer-list-style1 .twm-mid-content {
    padding-left: 0px;
  }
}

@media (max-width: 575px) {
  .twm-employer-list-style1 .twm-right-content {
    margin: 0px auto;
  }
}

/*Employer grid*/

.twm-employer-grid-style1 {
  background-color: #fff;
  border: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  position: relative;
  padding: 25px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .twm-media {
    width: 90px;
    height: 90px;
    line-height: 90px;
    display: inline-block;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
    margin-right: 40px;
    position: absolute;
    top: -20px;
    overflow: hidden;

    img {
      display: inline-block;
    }
  }

  p {
    font-size: 16px;
  }

  .twm-mid-content {
    padding-top: 80px;
  }

  .twm-right-content {
    text-align: center;
    width: 100px;
    position: absolute;
    right: 10px;
    top: 10px;

    .twm-job-address {
      margin-bottom: 0px;
    }

    .twm-jobs-vacancies {
      font-weight: 500;
      color: #17171d;
      margin: 20px 0px 10px 0px;
      font-size: 16px;

      span {
        color: #00aa18;
        font-size: 18px;
        font-weight: 600;
        display: block;
        text-align: center;
      }
    }

    .twm-job-websites {
      font-size: 16px;
    }
  }
}

.twm-bg-red {
  background-color: #ff0000;
}

.twm-bg-green {
  background-color: #2db346;
}

.twm-bg-primary {
  background-color: #92388e;
}

.twm-bg-brown {
  background-color: #b3692d;
}

.twm-bg-purple {
  background-color: #8883ec;
}

.twm-bg-sky {
  background-color: #2d9bb3;
}

.twm-bg-golden {
  background-color: #b7912a;
}

.twm-bg-ring-wrap {
  overflow: hidden;
  position: relative;

  .twm-bg-ring-right {
    position: absolute;
    border-radius: 50%;
  }

  .twm-bg-ring-left {
    position: absolute;
    border-radius: 50%;
    width: 495px;
    height: 495px;
    border: 100px solid #56d8b1;
    left: -150px;
    bottom: -150px;
    opacity: 0.1;
  }

  .twm-bg-ring-right {
    width: 700px;
    height: 700px;
    border: 130px solid #1967d2;
    right: -80px;
    top: 150px;
    opacity: 0.1;
  }
}

.twm-bg-ring-wrap2 {
  overflow: hidden;
  position: relative;

  .twm-bg-ring-right {
    position: absolute;
    border-radius: 50%;
  }

  .twm-bg-ring-left {
    position: absolute;
    border-radius: 50%;
    width: 495px;
    height: 495px;
    border: 100px solid #56d8b1;
    left: -150px;
    bottom: -150px;
    opacity: 0.3;
  }

  .twm-bg-ring-right {
    width: 700px;
    height: 700px;
    border: 130px solid #1967d2;
    right: -250px;
    top: -250px;
    opacity: 0.05;
  }
}

/*Explore New Life*/

.twm-explore-area {
  overflow: hidden;
}

.twm-explore-content-outer {
  position: relative;
  z-index: 1;

  .twm-bold-circle-left {
    width: 140px;
    height: 140px;
    border: 30px solid #56d8b1;
    left: 50px;
    bottom: -110px;
    opacity: 0.4;
    border-radius: 50%;
    position: absolute;
  }

  .twm-bold-circle-right {
    width: 250px;
    height: 250px;
    border: 50px solid #1967d2;
    right: -140px;
    top: -90px;
    opacity: 0.2;
    border-radius: 50%;
    position: absolute;
  }
}

.twm-explore-media-wrap .twm-media {
  position: relative;
  z-index: 2;
  margin-right: -85px;
}

@media (max-width: 991px) {
  .twm-explore-media-wrap .twm-media {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

@media (max-width: 575px) {
  .twm-explore-media-wrap .twm-media {
    margin-right: 0px;

    img {
      width: 100%;
    }
  }
}

.twm-explore-content {
  background-color: #1967d2;
  border-radius: 10px;
  padding: 55px 70px;
  position: relative;
  overflow: hidden;
  margin-top: 70px;
  margin-left: 30px;
  z-index: 1;

  .twm-l-line-1 {
    width: 475px;
    height: 475px;
    position: absolute;
    border: 1px solid #e7e7e7;
    border-radius: 50%;
    opacity: 0.1;
    left: -290px;
    top: -290px;
    z-index: -1;
  }

  .twm-l-line-2 {
    width: 475px;
    height: 475px;
    position: absolute;
    border: 1px solid #e7e7e7;
    border-radius: 50%;
    opacity: 0.1;
    left: -230px;
    top: -230px;
    z-index: -1;
  }

  .twm-r-circle-1 {
    width: 500px;
    height: 500px;
    position: absolute;
    background-color: #e7e7e7;
    border-radius: 50%;
    opacity: 0.05;
    right: -140px;
    top: -200px;
    z-index: -1;
  }

  .twm-r-circle-2 {
    width: 500px;
    height: 500px;
    position: absolute;
    background-color: #e7e7e7;
    border-radius: 50%;
    opacity: 0.05;
    right: -90px;
    top: -145px;
    z-index: -1;
  }

  .twm-title-small {
    margin-bottom: 10px;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    font-weight: 500;
  }

  .twm-title-large {
    margin-bottom: 40px;

    h2 {
      color: #fff;
      margin-bottom: 40px;
    }

    p {
      color: #e7e7e7;
    }
  }

  .twm-upload-file .site-button {
    background-color: #fff;
    color: #17171d;

    i {
      color: #1967d2;
      padding-right: 0px;
      padding-left: 5px;
      font-size: 24px;
    }
  }
}

@media (max-width: 991px) {
  .twm-explore-content {
    margin-bottom: 30px;
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .twm-explore-content {
    padding: 30px;
  }
}

@media (max-width: 768px) {
  .twm-explore-content .twm-title-large h2 {
    font-size: 30px;
  }
}

.twm-explore-content-2 {
  background-color: #1967d2;
  border-radius: 50px;
  padding: 55px 70px;
  position: relative;
  z-index: 1;
  margin-right: 100px;
  margin-bottom: 30px;

  .twm-explore-top-section {
    margin-right: 110px;
    margin-bottom: 40px;

    .twm-title-small {
      margin-bottom: 10px;
      color: #fff;
      font-size: 18px;
      line-height: 40px;
      font-weight: 500;
    }

    .twm-title-large {
      margin-bottom: 40px;

      h2 {
        color: #fff;
        margin-bottom: 40px;
      }

      p {
        color: #e7e7e7;
      }
    }

    .twm-read-more .site-button {
      background-color: #fff;
      color: #1967d2;

      i {
        color: #1967d2;
        padding-right: 0px;
        padding-left: 5px;
        font-size: 24px;
      }
    }
  }

  .twm-explore-bottom-section .counter-outer-two {
    position: relative;

    .tw-count-number {
      font-size: 38px;
      line-height: 38px;
      font-weight: 700;
      // font-family: "Josefin Sans", sans-serif;
    }

    .counter {
      font-size: 46px;
      line-height: 50px;
      font-weight: 600;
      // font-family: "Josefin Sans", sans-serif;
    }

    .icon-content-info {
      color: #fff;
      margin-bottom: 0px;
      font-size: 16px;
    }
  }

  .twm-explore-media-wrap2 {
    position: relative;
    z-index: 1;
    height: 100%;

    &:after {
      background-color: #fff;
      content: "";
      position: absolute;
      width: 467px;
      height: 467px;
      border-radius: 50%;
      right: -170px;
      top: 0px;
      z-index: -1;
      box-shadow: 20px 20px 0px #4780ce inset;
    }

    .twm-media {
      position: absolute;
      width: 350px;
      right: -170px;
      bottom: -40px;

      img {
        width: auto;
      }
    }
  }
}

@media (max-width: 991px) {
  .twm-explore-content-2 {
    padding: 30px;
  }
}

@media (max-width: 600px) {
  .twm-explore-content-2 {
    margin-right: 0px;
  }
}

@media (max-width: 991px) {
  .twm-explore-content-2 .twm-explore-top-section {
    margin-right: 0px;
  }
}

@media (max-width: 991px) {
  .twm-explore-content-2 .twm-explore-top-section .twm-title-large h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .twm-explore-content-2 .twm-explore-top-section .twm-title-large h2 {
    font-size: 22px;
  }
}

@media (max-width: 600px) {
  .twm-explore-content-2 .twm-explore-media-wrap2:after {
    display: none;
  }
}

@media (max-width: 600px) {
  .twm-explore-content-2 .twm-explore-media-wrap2 .twm-media {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .twm-explore-content-2 .twm-explore-media-wrap2 .twm-media {
    position: inherit;
    right: inherit;
    bottom: inherit;
    margin: 0px auto;
  }
}

@media (max-width: 600px) {
  .twm-explore-content-2 .twm-explore-media-wrap2 .twm-media img {
    width: 95%;
  }
}

/*Explore New Life*/

.twm-explore-content-outer-3 {
  position: relative;
  z-index: 1;
  margin-bottom: 130px;

  .twm-l-line-1 {
    width: 73px;
    height: 270px;
    position: absolute;
    background-color: #eac87c;
    border-radius: 50px;
    left: -30px;
    bottom: -140px;
    z-index: -1;
    opacity: 0.2;
  }

  .twm-l-line-2 {
    width: 73px;
    height: 270px;
    position: absolute;
    background-color: #cea7d9;
    border-radius: 50px;
    left: 55px;
    bottom: -120px;
    z-index: -1;
    opacity: 0.2;
  }

  .twm-explore-content-3 {
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
    border-radius: 10px;
    padding: 55px 70px;
    position: relative;
    margin-top: 70px;
    margin-left: 30px;
    z-index: 1;

    &:after {
      content: "";
      right: -40px;
      top: 40px;
      position: absolute;
      width: 100%;
      height: 100%;
      border-right: 40px solid #dbe6f7;
      border-bottom: 40px solid #dbe6f7;
      border-radius: 10px;
      z-index: -1;
    }

    .twm-title-small {
      margin-bottom: 10px;
      color: #17171d;
      font-size: 18px;
      line-height: 40px;
      font-weight: 500;
    }

    .twm-title-large {
      margin-bottom: 40px;

      h2 {
        color: #17171d;
        margin-bottom: 40px;
      }
    }

    .twm-upload-file .site-button {
      background-color: #1967d2;
      color: #fff;

      i {
        color: #fff;
        padding-right: 0px;
        padding-left: 5px;
        font-size: 24px;
      }
    }
  }
}

@media (max-width: 991px) {
  .twm-explore-content-outer-3 {
    margin-bottom: 70px;
  }
}

@media (max-width: 575px) {
  .twm-explore-content-outer-3 {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .twm-explore-content-outer-3 .twm-l-line-1 {
    display: none;
  }
}

@media (max-width: 991px) {
  .twm-explore-content-outer-3 .twm-l-line-2 {
    display: none;
  }
}

@media (max-width: 991px) {
  .twm-explore-content-outer-3 .twm-explore-content-3 {
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .twm-explore-content-outer-3 .twm-explore-content-3 {
    padding: 30px;
  }
}

@media (max-width: 575px) {
  .twm-explore-content-outer-3 .twm-explore-content-3:after {
    display: none;
  }
}

@media (max-width: 768px) {
  .twm-explore-content-outer-3 .twm-explore-content-3 .twm-title-large h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .twm-explore-content-outer-3 .twm-explore-content-3 .twm-title-large h2 {
    font-size: 22px;
  }
}

/*Job categories*/

.twm-job-categories-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.twm-job-categories-section {
  margin-bottom: 30px;

  &:after {
    width: 100%;
    height: 100%;
    content: "";
    left: calc(50% - 120px);
    top: 0px;
    background-color: #fff;
    position: absolute;
    z-index: -1;
  }
}

@media (max-width: 991px) {
  .twm-job-categories-section:after {
    left: 0px;
  }
}

.twm-job-categories-section-2 {
  margin-bottom: 30px;
}

.job-categories-block {
  display: flex;
  padding: 50px 30px;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  border-radius: 10px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &:after {
    width: 200px;
    height: 200px;
    position: absolute;
    left: -100px;
    bottom: -35px;
    content: "";
    background-color: #3898e2;
    border-radius: 50%;
    opacity: 0.1;
    z-index: -1;
  }

  &:before {
    width: 200px;
    height: 200px;
    position: absolute;
    left: -80px;
    bottom: -30px;
    content: "";
    background-color: #3898e2;
    border-radius: 50%;
    opacity: 0.1;
    z-index: -1;
  }

  .twm-media {
    width: 100px;

    img {
      width: auto;
    }
  }

  .twm-content {
    .twm-jobs-available {
      background-color: #1967d2;
      color: #fff;
      font-size: 16px;
      border-radius: 5px;
      padding: 4px 15px;
      display: inline-block;
      margin-bottom: 10px;
    }

    a {
      margin-bottom: 0px;
      display: block;
    }
  }
}

@media (max-width: 1199px) {
  .job-categories-block {
    padding: 30px 20px;
  }
}

@media (max-width: 991px) {
  .job-categories-block {
    margin: 0px 20px;
  }
}

.job-categories-style1 .owl-carousel .owl-stage-outer {
  display: table;
  position: relative;
  padding: 30px 0px 30px 10px;
}

@media (max-width: 991px) {
  .job-categories-style1 .owl-carousel .owl-stage-outer {
    display: block;
    padding: 30px 0px;
  }
}

.owl-btn-left-bottom .owl-nav {
  text-align: left;
  position: absolute;

  .owl-next {
    right: -1px;
    transition: all 0.2s linear;
  }

  .owl-prev {
    transition: all 0.2s linear;
  }
}

.job-categories-block-2 {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  border-radius: 10px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;

  &:after {
    width: 110px;
    height: 110px;
    position: absolute;
    right: -60px;
    bottom: -60px;
    content: "";
    background-color: #1967d2;
    border-radius: 50%;
    opacity: 0.04;
    z-index: -1;
  }

  &:before {
    width: 110px;
    height: 110px;
    position: absolute;
    left: -60px;
    top: -60px;
    content: "";
    background-color: #1967d2;
    border-radius: 50%;
    opacity: 0.04;
    z-index: -1;
  }

  .twm-media {
    width: 100px;
    display: inline-block;
    margin-bottom: 30px;

    img {
      width: auto;
    }
  }

  .twm-content {
    .twm-jobs-available {
      background-color: #dbe6f7;
      color: #1967d2;
      font-size: 16px;
      border-radius: 5px;
      padding: 4px 15px;
      display: inline-block;
      margin-bottom: 10px;
    }

    a {
      margin-bottom: 0px;
      display: block;
    }
  }
}

/*Search Bar*/

.twm-bnr-search-bar {
  // background-color: #fff;
  // border-radius: 10px;
  // border: 1px solid rgba(47, 47, 47, 0.09);
  // box-shadow: 0px 0px 30px rgba(230, 230, 230, 0.3);
  // text-align: left;
  // padding-left: 8px;

  label {
    font-size: 16px;
    line-height: 16px;
    color: #000;
    text-transform: uppercase;
    display: block;
    padding-top: 8px;
  }

  .form-select,
  .form-control {
    height: 34px;
    padding: 0px;
    border: none;
  }

  .site-button {
    display: block;
    width: 100%;
    padding: 15px 8px;
  }
}

@media (max-width: 1199px) {
  .twm-bnr-search-bar {
    height: auto;
    padding: 10px;

    .form-group {
      margin: 5px 0px;
    }
  }
}

.twm-inputicon-box {
  position: relative;

  .form-control {
    padding-right: 40px;
    background: transparent;
  }

  .twm-input-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 34px;
    z-index: 5;
    line-height: 34px;
    text-align: center;
    font-size: 18px;
  }
}

.twm-bnr-popular-search {
  position: relative;
  font-size: 16px;
  margin-top: 30px;
  color: #fff;

  .twm-title {
    margin-right: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }
}

/*Search Bar Section home 2*/

.twm-search-bar-2-wrap {
  position: relative;
  z-index: 2;

  .twm-search-bar-2-inner {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(56, 152, 226, 0.3);
    margin: -80px 0px 0px 0px;
    position: relative;
    background-color: #fff;
    z-index: 1;

    > .row {
      margin-bottom: 10px;
    }

    .twm-bnr-popular-search {
      margin-top: 10px;
    }
  }
}

@media (max-width: 991px) {
  .twm-search-bar-2-wrap .twm-search-bar-2-inner {
    margin: 40px 0px 30px 0px;
  }
}

/*trusted Logo*/

.twm-trusted-by-wrap {
  margin-left: 50px;

  .twm-trusted-by-title {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }
}

@media (max-width: 1199px) {
  .twm-trusted-by-wrap {
    margin-left: 0px;
  }
}

@media (max-width: 991px) {
  .twm-trusted-by-wrap {
    margin: 40px 0px 30px 0px;
  }
}

@media (max-width: 991px) {
  .twm-trusted-by-wrap .twm-trusted-logo a {
    text-align: center;

    img {
      margin: 0px auto;
    }
  }
}

.twm-trusted-logo a img {
  width: auto !important;
}

.rotate-center {
  animation: rotate-center 30s linear infinite both;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate-center-reverse {
  animation: rotate-center 30s linear infinite reverse both;
}

@keyframes rotate-center-reverse {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.slide-top-animation {
  animation: slide-top-animation 3s ease-in-out infinite alternate-reverse both;
}

@keyframes slide-top-animation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100px);
  }
}

.scale-up-center {
  animation: scale-up-center 2s ease-in-out infinite alternate-reverse both;
}

@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

/*tabs*/

.twm-tabs-style-1 {
  &.center .nav-tabs {
    text-align: center;
    display: block;

    .nav-item {
      display: inline-block;
    }
  }

  .nav-tabs {
    margin-bottom: 50px;
    border: none;

    .nav-item .nav-link {
      border: none;
      padding: 5px 15px;
      margin-top: 10px;
      margin-right: 10px;
      display: inline-block;
      color: #1967d2;
      border-radius: 6px;
      font-size: 16px;
      background-color: #dbe6f7 !important;

      &.active {
        background-color: #1967d2 !important;
        color: #fff;
      }
    }
  }
}

@media (max-width: 575px) {
  .twm-tabs-style-1.center .nav-tabs {
    text-align: left;
  }
}

/*tabs*/

.twm-tabs-style-2 {
  &.center .nav-tabs {
    text-align: center;
    display: block;

    .nav-item {
      display: inline-block;
    }
  }

  .nav-tabs {
    margin-bottom: 20px;
    border: none;

    .nav-item .nav-link {
      border: none;
      padding: 8px 15px;
      margin-top: 10px;
      margin-right: 10px;
      display: inline-block;
      color: #1967d2;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 500;
      background-color: #fff;
      border: 1px solid #1967d2;

      i {
        margin-right: 5px;
      }

      &.active {
        background-color: #1967d2 !important;
        color: #fff;
      }
    }
  }
}

@media (max-width: 575px) {
  .twm-tabs-style-2 .nav-tabs .nav-item .nav-link {
    padding: 4px 10px;
    font-size: 16px;
  }
}

/*Side bar Advertisement*/

.twm-advertisment {
  border-radius: 10px;
  overflow: hidden;
  padding: 50px 30px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  color: #fff;
  margin: 30px 0px;

  .overlay {
    background-color: #1967d2;
    opacity: 0.8;
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: -1;
  }

  .twm-title {
    color: #fff;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 30px;
  }
}

.form-control {
  &:focus {
    box-shadow: none;
  }

  &::-webkit-input-placeholder {
    color: #a0a0a0;
    // font-size: 16px;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #a0a0a0;
    // font-size: 16px;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #a0a0a0;
    // font-size: 16px;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: #a0a0a0;
    // font-size: 16px;
  }
}

/*Side Bar Elements*/

.twm-sidebar-ele-filter {
  margin: 30px 0px;

  ul {
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      margin-bottom: 20px;
      color: #666666;

      .twm-job-type-count {
        padding-left: 30px;
        color: #1967d2;
        font-size: 16px;
      }
    }
  }
}

/*Checked Input*/

.form-check-input {
  width: 16px;
  height: 16px;

  &:checked[type="radio"] {
    background-image: none;
    position: relative;

    &:after {
      background-color: #1967d2;
      width: 10px;
      height: 10px;
      content: "";
      position: absolute;
      left: 2px;
      top: 2px;
      border-radius: 50%;
    }
  }

  &[type="radio"] {
    background-color: transparent;
  }
}

.product-filter-wrap {
  padding-bottom: 30px;

  .woocommerce-result-count-left {
    font-size: 16px;
    font-weight: 600;
  }

  .twm-filter-select {
    display: flex;
    align-items: center;

    .woocommerce-result-count {
      margin-right: 15px;
      font-size: 16px;
      font-weight: 600;
    }

    .form-select-3:last-child {
      margin-right: 0px;
    }
  }
}

@media (max-width: 767px) {
  .product-filter-wrap {
    display: block !important;

    .woocommerce-result-count-left {
      margin-bottom: 10px;
      display: block;
    }
  }
}

@media (max-width: 400px) {
  .product-filter-wrap .twm-filter-select {
    display: block;
  }
}

@media (max-width: 767px) {
  .product-filter-wrap .twm-filter-select .woocommerce-result-count {
    display: block;
    width: 100%;
    max-width: 120px;
  }
}

.form-select-3 {
  width: 160px;
  margin-right: 20px;
  background-color: #dbe6f7;
  border: 0px;
  border-radius: 10px;
}

@media (max-width: 400px) {
  .form-select-3 {
    margin-top: 10px;
    width: 100%;
  }
}

/*Error 404*/

.twm-error-wrap {
  padding: 100px 0px;

  .row {
    display: flex;
    align-items: center;
  }

  .twm-error-content {
    text-align: center;

    .twm-error-title {
      font-size: 150px;
      line-height: 150px;
    }

    .twm-error-title2 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
      font-size: 16px;
    }
  }
}

@media (max-width: 991px) {
  .twm-error-wrap {
    padding-bottom: 30px;

    .twm-error-image {
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 991px) {
  .twm-error-wrap .twm-error-content .twm-error-title {
    font-size: 58px;
    line-height: 58px;
  }
}

/*Candidate List*/

.twm-candidates-list-wrap {
  padding-top: 0px;

  ul {
    margin: 0px;

    li {
      list-style: none;
    }
  }
}

.twm-candidates-list-style1 {
  background-color: #fff;
  border: 1px solid #ddd;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;

  .twm-media {
    width: 110px;
    display: inline-block;
    margin-right: 10px;

    .twm-media-pic {
      width: 90px;
      height: 90px;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      top: -50px;
      margin-bottom: -30px;

      img {
        display: inline-block;
      }
    }

    .twm-candidates-tag span {
      color: #1967d2;
      border-radius: 5px;
      padding: 5px 12px;
      background-color: #dbe6f7;
    }
  }

  p {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .twm-mid-content {
    flex-grow: 1;
  }

  .twm-fot-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .twm-jobs-vacancies {
      font-weight: 500;
      color: #17171d;
      font-size: 16px;

      span {
        color: #046c38;
        margin-left: 5px;
      }
    }

    .twm-left-info {
      display: flex;
      justify-content: space-between;
    }

    .twm-candidate-address {
      font-size: 16px;
      margin-bottom: 0px;
      margin-right: 30px;
      font-weight: 500;

      i {
        margin-right: 5px;
      }
    }

    .twm-view-prifile {
      font-weight: 600;
      font-size: 18px;
    }
  }
}

@media (max-width: 575px) {
  .twm-candidates-list-style1 {
    display: block;
    text-align: center;
  }
}

@media (max-width: 575px) {
  .twm-candidates-list-style1 .twm-media {
    margin-right: 0px;
    margin-bottom: 10px;
    width: 90px;
  }
}

@media (max-width: 420px) {
  .twm-candidates-list-style1 .twm-fot-content {
    display: block;
  }
}

/*Candidate grid*/

.twm-candidates-grid-style1 {
  background-color: #fff;
  border: 1px solid #ddd;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  text-align: center;

  .twm-media {
    display: inline-block;
    margin-bottom: 20px;

    .twm-media-pic {
      width: 90px;
      height: 90px;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      top: -50px;
      margin-bottom: -30px;

      img {
        display: inline-block;
      }
    }

    .twm-candidates-tag span {
      color: #1967d2;
      border-radius: 5px;
      padding: 5px 12px;
      background-color: #dbe6f7;
    }
  }

  p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .twm-mid-content {
    flex-grow: 1;
  }

  .twm-view-prifile {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
    display: block;
  }

  .twm-fot-content {
    background-color: #f3f8ff;
    padding: 20px;
    margin: 0px -20px -20px;
    border-radius: 0px 0px 10px 10px;

    .twm-jobs-vacancies {
      font-weight: 500;
      color: #17171d;
      font-size: 16px;

      span {
        color: #046c38;
        margin-left: 5px;
      }
    }

    .twm-left-info {
      display: flex;
      justify-content: space-between;
    }

    .twm-candidate-address {
      font-size: 16px;
      margin-bottom: 0px;
      margin-right: 30px;
      font-weight: 500;

      i {
        margin-right: 5px;
      }
    }
  }
}

/*Candidates detail*/

.twm-s-title {
  margin: 30px 0px;
}

.twm-candi-self-wrap {
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 40px;
  background-size: cover;
}

.twm-candi-self-info {
  position: relative;
  z-index: 1;

  .twm-candi-self-top {
    text-align: center;
    color: #fff;
    position: relative;
    padding-top: 20px;

    .twm-candi-fee {
      color: #fff;
      font-weight: 600;
      font-size: 22px;
      position: absolute;
      right: 0px;
      top: -10px;
    }

    .twm-media {
      width: 90px;
      height: 90px;
      border-radius: 10px;
      overflow: hidden;
      margin: 0px auto 30px;

      img {
        width: 100%;
      }
    }

    .twm-job-title {
      color: #fff;
      margin-bottom: 15px;
    }

    .twm-candidate-address i {
      padding-right: 3px;
    }
  }

  .twm-candi-self-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (max-width: 575px) {
  .twm-candi-self-info .twm-candi-self-top .twm-candi-fee {
    font-size: 16px;
  }
}

@media (max-width: 360px) {
  .twm-candi-self-info .twm-candi-self-bottom {
    display: block;

    .site-button {
      display: block;
      margin-top: 3px;
      text-align: center;
    }
  }
}

/*Employer detail*/

.twm-employer-self-wrap {
  border-radius: 10px;
  margin-bottom: 40px;
}

.twm-employer-self-info {
  position: relative;
  z-index: 1;

  .twm-employer-self-top {
    position: relative;
    padding-top: 20px;

    .twm-media-bg img {
      border-radius: 10px;
    }

    .twm-media {
      width: 90px;
      height: 90px;
      line-height: 90px;
      display: inline-block;
      text-align: center;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
      position: relative;
      top: -40px;
      left: 20px;
      overflow: hidden;
      margin-bottom: -20px;

      img {
        width: 100%;
      }
    }

    .twm-job-title {
      margin-bottom: 15px;
      font-size: 16px;
    }

    .twm-employer-address i {
      padding-right: 3px;
    }

    .twm-employer-websites {
      margin-bottom: 10px;
    }

    .twm-mid-content {
      position: relative;
    }
  }

  .twm-employer-self-bottom {
    position: absolute;
    top: -62px;
    right: 0px;

    a {
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

@media (max-width: 767px) {
  .twm-employer-self-info .twm-employer-self-bottom {
    position: inherit;
    top: inherit;
    right: inherit;
    margin-top: 10px;
  }
}

/*Work Experience*/

.twm-timing-list-wrap {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 50px;
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .twm-timing-list-wrap {
    padding: 20px 30px;
  }
}

.twm-timing-list {
  margin-bottom: 30px;
  margin-left: 30px;

  &:last-child {
    margin-bottom: 0px;
  }

  .twm-time-list-date {
    font-size: 16px;
    color: #666666;
  }

  .twm-time-list-title {
    color: #1967d2;
    font-size: 16px;
    font-weight: 500;
    position: relative;

    &:before {
      width: 20px;
      height: 20px;
      border: 3px solid #dbe6f7;
      border-radius: 50%;
      background-color: #1967d2;
      content: "";
      position: absolute;
      left: -50px;
      top: 3px;
    }

    &:after {
      width: 22px;
      height: 1px;
      background-color: #1967d2;
      content: "";
      position: absolute;
      left: -26px;
      top: 13px;
    }
  }

  .twm-time-list-position {
    margin: 10px 0px;
    font-weight: 500;
    font-size: 16px;
    color: #000;
  }

  .twm-time-list-discription p:last-child {
    margin-bottom: 0px;
  }
}

/*Social Share*/

.twm-social-tags {
  margin-top: -10px;

  a {
    padding: 5px 15px;
    margin-top: 10px;
    margin-right: 10px;
    display: inline-block;
    color: #fff;
    border-radius: 6px;
    font-size: 16px;

    &:hover {
      opacity: 0.9;
    }
  }
}

.fb-clr {
  background-color: #4d69b2;
}

.tw-clr {
  background-color: #38b2e1;
}

.link-clr {
  background-color: #0579b6;
}

.whats-clr {
  background-color: #2ca53b;
}

.pinte-clr {
  background-color: #f03c41;
}

/*Employer detail*/

.twm-job-self-wrap {
  border-radius: 10px;
  margin-bottom: 40px;
}

.twm-job-self-info {
  position: relative;
  z-index: 1;

  .twm-job-self-top {
    position: relative;

    .twm-media-bg img {
      border-radius: 10px;
    }

    .twm-jobs-category {
      position: absolute;
      left: 20px;
      top: 20px;
    }

    .twm-media {
      width: 90px;
      height: 90px;
      line-height: 90px;
      display: inline-block;
      text-align: center;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
      position: relative;
      top: -40px;
      left: 20px;
      overflow: hidden;
      margin-bottom: -20px;

      img {
        width: 100%;
        display: block;
        object-fit: cover;
      }
    }

    .twm-job-title {
      margin-bottom: 15px;
      font-size: 16px;

      .twm-job-post-duration {
        color: #92388e;
        font-size: 16px;
      }
    }

    .twm-job-address i {
      padding-right: 3px;
    }

    .twm-job-self-mid {
      display: flex;
      justify-content: space-between;

      .twm-job-apllication-area {
        font-weight: 500;

        .twm-job-apllication-date {
          color: #92388e;
        }
      }
    }

    .twm-jobs-amount {
      font-weight: 500;
      color: #17171d;
      font-size: 16px;
      display: inline-block;

      span {
        color: #92388e;
      }
    }

    .twm-job-websites {
      margin-bottom: 10px;
      margin-right: 5px;
    }

    .twm-mid-content {
      position: relative;
    }
  }

  .twm-job-self-bottom {
    position: absolute;
    top: -62px;
    right: 0px;

    a {
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

@media (max-width: 420px) {
  .twm-job-self-info .twm-job-self-top .twm-media {
    left: auto;
    top: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .twm-job-self-info .twm-job-self-top .twm-job-self-mid {
    display: block;
  }
}

@media (max-width: 767px) {
  .twm-job-self-info .twm-job-self-bottom {
    position: inherit;
    top: inherit;
    right: inherit;
    margin-top: 10px;
  }
}

/* Home Page 2 Banner Section Css Start */

.twm-home2-banner-section {
  position: relative;
  padding-top: 280px;
  overflow: hidden;

  > .row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .twm-bnr-left-section {
      max-width: 800px;
      margin-left: auto;
      position: relative;
      z-index: 2;
      padding-left: 80px;

      .twm-bnr-title-small {
        font-size: 16px;
        font-weight: 500;
        color: #1967d2;
        margin-bottom: 30px;
        background-color: #dbe6f7;
        display: inline-block;
        padding: 6px 20px;
        border-radius: 10px;
      }

      .twm-bnr-title-large {
        font-size: 66px;
        line-height: 72px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 30px;

        span {
          text-decoration: underline;
        }
      }

      .twm-bnr-discription {
        font-size: 36px;
        margin-bottom: 30px;
      }
    }
  }

  .twm-bnr2-right-content {
    max-width: 900px;
    position: relative;
    margin-left: -80px;

    .twm-img-bg-circle-area2 {
      position: absolute;
      bottom: 100px;
      left: 0px;

      .twm-outline-ring-wrap {
        width: 510px;
        height: 510px;
        border: 1px solid #ccc;
        position: relative;
        border-radius: 50%;

        &:before {
          content: "";
          position: absolute;
          top: 35px;
          left: 50px;
          border: 1px solid #ccc;
          width: 435px;
          height: 435px;
          border-radius: 50%;
        }

        &:after {
          content: "";
          position: absolute;
          top: 90px;
          left: 100px;
          border: 1px solid #ccc;
          width: 335px;
          height: 335px;
          border-radius: 50%;
        }

        .twm-outline-ring-dott-wrap {
          width: 510px;
          height: 510px;
          position: relative;
          border-radius: 50%;

          .outline-dot-1 {
            width: 17px;
            height: 17px;
            background-color: #1967d2;
            border-radius: 50%;
            content: "";
            position: absolute;
            left: 40px;
            top: 100px;
            z-index: 9;
          }

          .outline-dot-2 {
            width: 17px;
            height: 17px;
            background-color: #1967d2;
            border-radius: 50%;
            content: "";
            position: absolute;
            left: -8px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 9;
          }

          .outline-dot-3 {
            width: 17px;
            height: 17px;
            background-color: #1967d2;
            border-radius: 50%;
            content: "";
            position: absolute;
            left: 40px;
            bottom: 100px;
            z-index: 9;
          }
        }
      }
    }

    .twm-small-ring-l {
      width: 50px;
      height: 50px;
      border: 5px solid #1967d2;
      border-radius: 50%;
      opacity: 0.4;
      position: absolute;
      top: 50%;
      left: -25px;
      z-index: 5;
      margin-top: -24px;
    }

    .twm-small-ring-2 {
      width: 50px;
      height: 50px;
      border: 5px solid #1967d2;
      border-radius: 50%;
      opacity: 0.4;
      position: absolute;
      top: -17px;
      right: -14px;
      z-index: 5;
    }

    .twm-bnr-blocks {
      display: flex;
      background-color: #fff;
      box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
      position: relative;
      padding: 20px 20px 10px 20px;
      border-radius: 10px;

      .twm-icon {
        width: 64px;
        height: 64px;
        line-height: 64px;
        border-radius: 64px;
        background-color: #1967d2;
        text-align: center;
        position: absolute;
        top: -50px;
        left: 0px;
        padding: 20px;

        &.pink {
          background-color: #c698d2;
        }

        img {
          width: 60%;
        }
      }

      .twm-content {
        .tw-count-number {
          font-size: 20px;
          line-height: 20px;
          font-weight: 700;

          .counter {
            font-size: 20px;
            line-height: 22px;
            font-weight: 600;
          }
        }

        .icon-content-info {
          color: #17171d;
          margin-bottom: 0px;
          font-size: 16px;
        }
      }
    }

    .twm-bnr-blocks-3 {
      background-color: #fff;
      border: 10px;
      box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
      position: relative;
      padding: 10px 20px;
      border-radius: 10px;
      width: 200px;

      .twm-pics {
        margin-left: 15px;
        margin-right: 15px;
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          border: 2px solid #fff;
          overflow: hidden;
          margin-left: -15px;
        }
      }

      .twm-content {
        display: flex;
        align-items: center;

        .tw-count-number {
          font-size: 38px;
          line-height: 38px;
          font-weight: 700;
          margin-right: 10px;

          .counter {
            font-size: 38px;
            line-height: 40px;
            font-weight: 600;
          }
        }

        .icon-content-info {
          color: #17171d;
          margin-bottom: 0px;
          font-size: 16px;
        }
      }
    }

    .twm-bnr-blocks-position-1 {
      position: absolute;
      z-index: 4;
      top: 50px;
      left: 40px;
    }

    .twm-bnr-blocks-position-2 {
      position: absolute;
      z-index: 4;
      bottom: 150px;
      left: 160px;
    }

    .twm-bnr-blocks-position-3 {
      position: absolute;
      z-index: 4;
      top: 300px;
      transform: translateY(-50%);
      left: 80px;
    }
  }

  .twm-home-2-bnr-images {
    position: relative;
    max-width: 620px;

    .bnr-image-1 {
      position: relative;
      z-index: 1;
      border-radius: 30px;
      margin-left: 150px;

      img {
        border-radius: 30px;
      }

      &:after {
        position: absolute;
        left: -30px;
        top: -30px;
        background-color: #eac87c;
        content: "";
        z-index: -1;
        width: 100%;
        height: 100%;
        border-radius: 30px;
      }
    }

    .bnr-image-2 {
      position: absolute;
      right: -190px;
      bottom: 100px;
      z-index: 1;

      &:after {
        content: "";
        position: absolute;
        right: -60px;
        top: -60px;
        z-index: -1;
        height: 500px;
        width: 250px;
        border-radius: 0px 250px 250px 0px;
        background-color: rgba(255, 255, 255, 0.6);
      }

      img {
        border-radius: 50%;
      }
    }
  }
}

@media (max-width: 1360px) {
  .twm-home2-banner-section > .row .twm-bnr-left-section {
    padding-left: 30px;
  }
}

@media (max-width: 991px) {
  .twm-home2-banner-section > .row .twm-bnr-left-section {
    margin: 0px auto 60px;
  }
}

@media (max-width: 575px) {
  .twm-home2-banner-section > .row .twm-bnr-left-section {
    padding: 0px 20px;
  }
}

@media (max-width: 1360px) {
  .twm-home2-banner-section > .row .twm-bnr-left-section .twm-bnr-title-large {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 15px;
  }
}

@media (max-width: 575px) {
  .twm-home2-banner-section > .row .twm-bnr-left-section .twm-bnr-title-large {
    font-size: 28px;
    line-height: 36px;
  }
}

@media (max-width: 991px) {
  .twm-home2-banner-section .twm-bnr2-right-content {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    padding: 40px;
  }
}

@media (max-width: 540px) {
  .twm-home2-banner-section .twm-bnr2-right-content {
    margin-bottom: 0px;
  }
}

@media (max-width: 991px) {
  .twm-home2-banner-section .twm-bnr2-right-content .twm-img-bg-circle-area2 {
    display: none;
  }
}

@media (max-width: 540px) {
  .twm-home2-banner-section .twm-bnr2-right-content .twm-bnr-blocks .twm-icon {
    position: inherit;
    top: inherit;
    left: inherit;
    margin-right: 10px;
  }
}

@media (max-width: 540px) {
  .twm-home2-banner-section .twm-bnr2-right-content .twm-bnr-blocks-position-1 {
    position: inherit;
    top: inherit;
    left: inherit;
    max-width: 320px;
    margin: 10px auto 10px;
    padding: 10px;
  }
}

@media (max-width: 540px) {
  .twm-home2-banner-section .twm-bnr2-right-content .twm-bnr-blocks-position-2 {
    position: inherit;
    bottom: inherit;
    left: inherit;
    max-width: 320px;
    margin: 10px auto 10px;
    padding: 10px;
  }
}

@media (max-width: 1360px) {
  .twm-home2-banner-section .twm-bnr2-right-content .twm-bnr-blocks-position-3 {
    top: 190px;
  }
}

@media (max-width: 991px) {
  .twm-home2-banner-section .twm-bnr2-right-content .twm-bnr-blocks-position-3 {
    top: 240px;
  }
}

@media (max-width: 540px) {
  .twm-home2-banner-section .twm-bnr2-right-content .twm-bnr-blocks-position-3 {
    position: inherit;
    top: inherit;
    left: inherit;
    max-width: 320px;
    width: 100%;
    margin: 10px auto 10px;
    transform: none;
  }
}

@media (max-width: 1360px) {
  .twm-home2-banner-section .twm-home-2-bnr-images {
    max-width: 500px;
  }
}

@media (max-width: 1199px) {
  .twm-home2-banner-section .twm-home-2-bnr-images {
    max-width: 450px;
  }
}

@media (max-width: 991px) {
  .twm-home2-banner-section .twm-home-2-bnr-images {
    margin: 0px auto;
  }
}

@media (max-width: 1199px) {
  .twm-home2-banner-section .twm-home-2-bnr-images .bnr-image-1 {
    margin-left: 100px;
  }
}

@media (max-width: 540px) {
  .twm-home2-banner-section .twm-home-2-bnr-images .bnr-image-1 {
    margin-left: 0px;
  }
}

@media (max-width: 1360px) {
  .twm-home2-banner-section .twm-home-2-bnr-images .bnr-image-2 {
    width: 280px;
  }
}

@media (max-width: 991px) {
  .twm-home2-banner-section .twm-home-2-bnr-images .bnr-image-2 {
    width: 180px;
    right: -30px;
  }
}

@media (max-width: 540px) {
  .twm-home2-banner-section .twm-home-2-bnr-images .bnr-image-2 {
    left: 20px;
    right: auto;
    bottom: 30px;
  }
}

@media (max-width: 1360px) {
  .twm-home2-banner-section .twm-home-2-bnr-images .bnr-image-2:after {
    right: -30px;
    top: -30px;
    height: 340px;
    width: 220px;
  }
}

@media (max-width: 991px) {
  .twm-home2-banner-section .twm-home-2-bnr-images .bnr-image-2:after {
    display: none;
  }
}

/* Home Page 1 Banner Section Css Start */

.twm-home1-banner-section {
  position: relative;
  padding-top: 160px;
  overflow: hidden;

  > .row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .twm-bnr-left-section {
      max-width: 645px;
      margin-left: auto;
      position: relative;
      z-index: 2;

      .twm-bnr-title-small {
        font-size: 20px;
        font-weight: 500;
        color: #17171d;
        margin-bottom: 30px;
      }

      .twm-bnr-title-large {
        font-size: 66px;
        line-height: 72px;
        font-weight: 500;
        color: #17171d;
        margin-bottom: 30px;
      }

      .twm-bnr-discription {
        font-size: 18px;
        margin-bottom: 30px;
      }
    }
  }

  .twm-bnr-right-section {
    position: relative;

    .twm-bnr-right-content {
      max-width: 900px;
      position: relative;
    }

    .twm-img-bg-circle-area {
      .twm-img-bg-circle1 {
        left: 225px;
        bottom: 150px;
        position: absolute;
        z-index: 3;

        span {
          width: 500px;
          height: 500px;
          border-radius: 50%;
          background-color: #b4cded;
          display: block;
          position: relative;

          &:after {
            width: 17px;
            height: 17px;
            background-color: #1967d2;
            border-radius: 50%;
            content: "";
            position: absolute;
            right: 35px;
            top: 100px;
            z-index: 9;
          }

          &:before {
            width: 17px;
            height: 17px;
            background-color: #1967d2;
            border-radius: 50%;
            content: "";
            position: absolute;
            left: 3px;
            top: 170px;
            z-index: 9;
          }
        }
      }

      .twm-img-bg-circle2 {
        left: 150px;
        bottom: 75px;
        position: absolute;
        z-index: 2;

        span {
          width: 650px;
          height: 650px;
          border-radius: 50%;
          background-color: #dbe6f4;
          display: block;

          &:after {
            width: 17px;
            height: 17px;
            background-color: #1967d2;
            border-radius: 50%;
            content: "";
            position: absolute;
            right: 75px;
            bottom: 100px;
            z-index: 9;
          }
        }
      }

      .twm-img-bg-circle3 {
        left: 75px;
        bottom: 0px;
        position: absolute;
        z-index: 1;

        span {
          width: 800px;
          height: 800px;
          border-radius: 50%;
          background-color: #ecf1f7;
          display: block;
        }
      }
    }

    .twm-bnr-right-carousel {
      position: relative;
      z-index: 4;

      .slide-img img {
        width: auto;
        margin-left: 100px;
      }
    }

    .twm-small-ring-l {
      width: 50px;
      height: 50px;
      border: 5px solid #1967d2;
      border-radius: 50%;
      opacity: 0.4;
      position: absolute;
      bottom: 30%;
      left: 11%;
      z-index: 5;
    }

    .twm-small-ring-2 {
      width: 50px;
      height: 50px;
      border: 5px solid #1967d2;
      border-radius: 50%;
      opacity: 0.4;
      position: absolute;
      top: 30%;
      right: 9%;
      z-index: 5;
    }
  }

  .twm-gradient-text {
    font-size: 150px;
    line-height: 110px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    font-weight: 800;
    text-transform: uppercase;
    padding-right: 200px;
    background: -moz-linear-gradient(top, rgba(192, 213, 240, 0.65) 0%, rgba(0, 0, 0, 0) 100%);

    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(192, 213, 240, 0.65) 0%, rgba(0, 0, 0, 0) 100%);

    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(192, 213, 240, 0.65) 0%, rgba(0, 0, 0, 0) 100%);

    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a6c0d5f0", endColorstr="#00000000", GradientType=0);

    /* IE6-9 */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .twm-bnr-blocks {
    display: flex;
    background-color: #fff;
    border: 10px;
    box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
    position: relative;
    padding: 10px 20px;
    border-radius: 10px;
    width: 270px;
    margin: 0px 4px;

    .twm-icon {
      width: 70px;
      margin-right: 20px;
    }

    .twm-content {
      .tw-count-number {
        font-size: 38px;
        line-height: 38px;
        font-weight: 700;

        .counter {
          font-size: 38px;
          line-height: 40px;
          font-weight: 600;
        }
      }

      .icon-content-info {
        color: #17171d;
        margin-bottom: 0px;
        font-size: 16px;
      }
    }
  }

  .twm-bnr-blocks-3 {
    display: flex;
    background-color: #fff;
    border: 10px;
    box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
    position: relative;
    padding: 10px 20px;
    border-radius: 10px;
    width: 290px;
    margin: 0px 4px;

    .twm-pics {
      margin-left: 15px;
      margin-right: 15px;
      display: flex;
      align-items: center;

      span {
        display: inline-block;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 2px solid #fff;
        overflow: hidden;
        margin-left: -15px;
      }
    }

    .twm-content {
      .tw-count-number {
        font-size: 38px;
        line-height: 38px;
        font-weight: 700;
        // font-family: "Josefin Sans", sans-serif;

        .counter {
          font-size: 38px;
          line-height: 40px;
          font-weight: 600;
          // font-family: "Josefin Sans", sans-serif;
        }
      }

      .icon-content-info {
        color: #17171d;
        margin-bottom: 0px;
        font-size: 16px;
      }
    }
  }

  .twm-bnr-blocks-position-1 {
    position: absolute;
    z-index: 4;
    top: 200px;
    left: 45px;
  }

  .twm-bnr-blocks-position-2 {
    position: absolute;
    z-index: 4;
    top: 90px;
    right: 0px;
  }

  .twm-bnr-blocks-position-3 {
    position: absolute;
    z-index: 4;
    bottom: 50px;
    right: 20px;
  }
}

@media (max-width: 1270px) {
  .twm-home1-banner-section > .row .twm-bnr-left-section {
    padding-left: 30px;
  }
}

@media (max-width: 991px) {
  .twm-home1-banner-section > .row .twm-bnr-left-section {
    max-width: 768px;
    margin: 0px auto;
    padding: 0px 30px 50px 30px;
  }
}

@media (max-width: 991px) {
  .twm-home1-banner-section > .row .twm-bnr-left-section .twm-bnr-title-small {
    margin-bottom: 10px;
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .twm-home1-banner-section > .row .twm-bnr-left-section .twm-bnr-title-large {
    font-size: 38px;
    line-height: 38px;
    margin-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .twm-home1-banner-section > .row .twm-bnr-left-section .twm-bnr-title-large {
    font-size: 28px;
    line-height: 36px;
  }
}

@media (max-width: 991px) {
  .twm-home1-banner-section .twm-bnr-right-section .twm-bnr-right-content {
    max-width: 100%;
    margin: 0px auto;
    padding: 0px 30px 0px 30px;
  }
}

@media (max-width: 480px) {
  .twm-home1-banner-section .twm-bnr-right-section .twm-img-bg-circle-area {
    display: none;
  }
}

@media (max-width: 1440px) {
  .twm-home1-banner-section .twm-bnr-right-section .twm-img-bg-circle-area .twm-img-bg-circle1 {
    left: 100px;
    bottom: 240px;
  }
}

@media (max-width: 1440px) {
  .twm-home1-banner-section
    .twm-bnr-right-section
    .twm-img-bg-circle-area
    .twm-img-bg-circle1
    span {
    width: 400px;
    height: 400px;
  }
}

@media (max-width: 1440px) {
  .twm-home1-banner-section
    .twm-bnr-right-section
    .twm-img-bg-circle-area
    .twm-img-bg-circle1
    span:after {
    right: 15px;
  }
}

@media (max-width: 1440px) {
  .twm-home1-banner-section
    .twm-bnr-right-section
    .twm-img-bg-circle-area
    .twm-img-bg-circle1
    span:before {
    left: -7px;
  }
}

@media (max-width: 1440px) {
  .twm-home1-banner-section .twm-bnr-right-section .twm-img-bg-circle-area .twm-img-bg-circle2 {
    left: 50px;
    bottom: 200px;
  }
}

@media (max-width: 1440px) {
  .twm-home1-banner-section
    .twm-bnr-right-section
    .twm-img-bg-circle-area
    .twm-img-bg-circle2
    span {
    width: 500px;
    height: 500px;
  }
}

@media (max-width: 1440px) {
  .twm-home1-banner-section
    .twm-bnr-right-section
    .twm-img-bg-circle-area
    .twm-img-bg-circle2
    span:after {
    right: 35px;
  }
}

@media (max-width: 1440px) {
  .twm-home1-banner-section .twm-bnr-right-section .twm-img-bg-circle-area .twm-img-bg-circle3 {
    left: 0px;
    bottom: 150px;
  }
}

@media (max-width: 1440px) {
  .twm-home1-banner-section
    .twm-bnr-right-section
    .twm-img-bg-circle-area
    .twm-img-bg-circle3
    span {
    width: 600px;
    height: 600px;
  }
}

@media (max-width: 1440px) {
  .twm-home1-banner-section .twm-bnr-right-section .twm-bnr-right-carousel .slide-img img {
    margin-left: 0px;
  }
}

@media (max-width: 1440px) {
  .twm-home1-banner-section .twm-bnr-right-section .twm-bnr-right-carousel .slide-img img {
    margin: 0px auto;
  }
}

@media (max-width: 1440px) {
  .twm-home1-banner-section .twm-bnr-right-section .twm-small-ring-l {
    left: 1%;
    bottom: 45%;
  }
}

@media (max-width: 991px) {
  .twm-home1-banner-section .twm-gradient-text {
    position: inherit;
  }
}

@media (max-width: 767px) {
  .twm-home1-banner-section .twm-gradient-text {
    display: none;
  }
}

@media (max-width: 991px) {
  .twm-home1-banner-section .twm-bnr-blocks-position-wrap {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .twm-home1-banner-section .twm-bnr-blocks-position-wrap {
    display: none;
  }
}

@media (max-width: 991px) {
  .twm-home1-banner-section .twm-bnr-blocks-position-1 {
    position: inherit;
    top: inherit;
    left: inherit;
  }
}

@media (max-width: 1366px) {
  .twm-home1-banner-section .twm-bnr-blocks-position-2 {
    top: -30px;
  }
}

@media (max-width: 991px) {
  .twm-home1-banner-section .twm-bnr-blocks-position-2 {
    position: inherit;
    top: inherit;
    right: inherit;
  }
}

@media (max-width: 991px) {
  .twm-home1-banner-section .twm-bnr-blocks-position-3 {
    position: inherit;
    right: inherit;
    bottom: inherit;
  }
}

/*Side Map*/

.twm-s-map-iframe {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);

  iframe {
    width: 100%;
    border: 0px;
    filter: grayscale(100%);
  }
}

/*Side Map2*/

.twm-m-map-iframe {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;

  iframe {
    width: 100%;
    border: 0px;
    filter: grayscale(100%);
  }
}

/*Sidebar Info*/

.twm-s-info3 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  padding: 30px;
  margin-top: 90px;

  .twm-s-info-logo-section {
    position: relative;

    .twm-media {
      width: 90px;
      height: 90px;
      line-height: 90px;
      display: inline-block;
      text-align: center;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
      position: relative;
      top: -80px;
      left: 0px;
      overflow: hidden;
      margin-bottom: -50px;
    }
  }

  ul {
    list-style: none;
    margin-bottom: 30px;

    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0px;
      }

      .twm-s-info-inner {
        position: relative;
        padding-left: 40px;

        i {
          position: absolute;
          left: 0px;
          top: 15px;
          font-size: 18px;
          color: #1967d2;
        }

        .twm-title {
          font-size: 16px;
          color: #666666;
        }

        .twm-s-info-discription {
          font-size: 16px;
          color: #17171d;
          word-break: break-word;
        }
      }
    }
  }

  .site-button {
    display: block;
    text-align: center;
  }
}

/*Sidebar Info*/

.twm-s-info {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  padding: 40px;

  ul {
    list-style: none;
    margin-bottom: 0px;

    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0px;
      }

      .twm-s-info-inner {
        position: relative;
        padding-left: 40px;

        i {
          position: absolute;
          left: 0px;
          top: 15px;
          font-size: 18px;
          color: #1967d2;
        }

        .twm-title {
          font-size: 16px;
          color: #666666;
        }

        .twm-s-info-discription {
          font-size: 16px;
          color: #17171d;
          word-break: break-word;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .twm-s-info {
    padding: 20px;
  }
}

/*Sidebar  Info 2*/

.twm-job-hilites {
  padding-bottom: 30px;

  li {
    display: block;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 15px 15px 50px;
    position: relative;
    color: #17171d;
    font-size: 16px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }

    i {
      position: absolute;
      left: 15px;
      top: 19px;
      font-size: 18px;
      color: #1967d2;
    }
  }
}

.twm-s-info2 .twm-job-hilites2 {
  list-style: none;
  margin-bottom: 0px;
  margin-left: 15px;

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }

    .twm-s-info-inner {
      position: relative;
      padding-left: 40px;

      i {
        position: absolute;
        left: 0px;
        top: 15px;
        font-size: 18px;
        color: #1967d2;
      }

      .twm-title {
        font-size: 16px;
        color: #666666;
      }

      .twm-s-info-discription {
        font-size: 16px;
        color: #17171d;
      }
    }
  }
}

/*Sidebar-contact*/

.twm-s-contact {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  padding: 40px;

  textarea.form-control {
    height: 100px;
    resize: none;
  }

  .form-control {
    width: 100%;
    height: 60px;
    padding: 20px;
    border: 1px solid transparent;
    background-color: #dbe6f7;
    border-radius: 10px;
  }
}

@media (max-width: 575px) {
  .twm-s-contact {
    padding: 20px;
  }
}

/*Faq*/

.tw-faq-section {
  max-width: 830px;
  margin: 0px auto;

  .tw-faq .accordion-item {
    border: 1px solid transparent;
    margin-bottom: 20px;
    overflow: hidden;
    border: 1px solid #efefef;
    border-radius: 10px;
    background-color: #dbe6f7;

    .accordion-button {
      font-size: 18px;
      color: #17171d;
      padding: 10px 16px 10px 16px;
      font-weight: 500;

      &:not(.collapsed) {
        box-shadow: none;
        background-color: #dbe6f7;
      }

      &:focus {
        box-shadow: none !important;
      }
    }
  }
}

/*Accordion button*/

.accordion-button {
  &:after {
    background-image: none;
    content: "\e9b3";
    font-family: "Feather";
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #17171d;
    text-align: center;
    font-size: 22px;
  }

  &:not(.collapsed):after {
    background-image: none;
    content: "\e998";
    transform: none;
  }
}

/*Sign Up popup*/

.twm-sign-up {
  margin-bottom: 10px;

  .modal-dialog {
    max-width: 590px;
  }

  .modal-content {
    border-top: 6px solid #1967d2;

    .form-check-label a {
      color: #1967d2;

      &:hover {
        color: #17171d;
      }
    }

    .twm-backto-login {
      background-color: transparent;
      color: #1967d2;
      border: none;

      &:hover {
        color: #17171d;
      }
    }
  }

  .modal-header {
    display: block;
    text-align: center;
    border: none;
    margin-top: 50px;

    .modal-title {
      font-size: 34px;
      margin-bottom: 15px;
    }

    .btn-close {
      position: absolute;
      right: 10px;
      top: 10px;
      opacity: 1;
    }
  }

  .modal-body {
    padding: 20px 110px;
  }

  .modal-footer {
    border: none;
    background-color: #dbe6f7;
    text-align: center;
    display: block;
    padding: 0px 0px 40px 0px;
    margin-top: 30px;

    .modal-f-title {
      display: inline-block;
      background-color: #dbe6f7;
      padding: 6px 15px;
      border-radius: 10px;
      position: relative;
      top: -22px;
      margin-bottom: -11px;
    }
  }

  .rem-forgot {
    display: flex;
    justify-content: space-between;
  }

  .site-button {
    display: block;
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 575px) {
  .twm-sign-up .modal-dialog {
    margin: 20px;
  }
}

@media (max-width: 575px) {
  .twm-sign-up .modal-body {
    padding: 20px;
  }
}

.twm-modal-social {
  list-style: none;

  li {
    display: inline-block;

    a {
      width: 32px;
      height: 32px;
      line-height: 32px;
      display: block;
      border-radius: 50%;
      text-align: center;
      border-width: 1px;
      border-style: solid;
    }
  }
}

.facebook-clr {
  color: #4d69b2;
  border-color: #4d69b2;
}

.twitter-clr {
  color: #38b2e1;
  border-color: #38b2e1;
}

.linkedin-clr {
  color: #0579b6;
  border-color: #0579b6;
}

.google-clr {
  color: #d8483d;
  border-color: #d8483d;
}

/*============================================================================================
	DashBoard Css Start
=============================================================================================*/

strong {
  font-weight: 500;
}

/*Dashboard Header*/

.header-widget-wrap .listing-user .dropdown-menu {
  width: 100%;
  min-width: 180px;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  border: 0px;
  padding: 20px;
}

.nav-btn-wrap {
  display: table-cell;
  vertical-align: middle;
}

.nav-btn-admin {
  font-size: 28px;
  line-height: 68px;
  cursor: pointer;
  display: block;
  color: #0f1221;
}

#header-admin.active {
  overflow: hidden;

  .nav-btn-admin {
    transform: rotate(180deg);
  }

  .container {
    min-width: 480px;
  }
}

.header-left {
  float: left;
  display: table;

  > .header-widget-wrap {
    display: table-cell;
    vertical-align: middle;
  }
}

.header-right {
  float: right;
  display: table;

  > {
    .header-widget-wrap,
    .header-login-signup {
      display: table-cell;
      vertical-align: middle;
    }
  }
}

.header-widget-wrap {
  > .header-widget {
    display: inline-block;
    margin-left: 25px;
    position: relative;
    cursor: pointer;
  }

  .dropdown-menu {
    width: 350px;
    min-width: 350px;
    top: 60px;
    left: auto;
    right: -15px;
    padding: 0;
  }
}

@media (max-width: 480px) {
  .header-widget-wrap .dropdown-menu {
    width: 300px;
    min-width: 300px;
    left: -5px !important;
  }
}

.listing-user {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .dropdown-toggle {
    padding-right: 20px;
    display: block;

    &::after {
      display: none;
    }
  }

  .user-name {
    transition: 0.5s all ease;
    color: #fff;
  }

  span {
    display: inline-block;
    width: 40px;
    margin-right: 15px;

    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
  }

  ul {
    list-style: none;

    li a {
      line-height: 22px;
      color: #17171d;
      font-size: 13px;
      padding: 5px 5px 5px 24px;
      display: block;
      transition: 0.2s;
      position: relative;

      i {
        position: absolute;
        left: 0px;
        top: 10px;
        color: #1967d2;
      }

      &:hover {
        color: #1967d2;
      }
    }
  }

  .user-name:before {
    font-family: "Font Awesome 5 Free";
    content: "\f107";
    font-weight: 900;
    transition: 0.5s all ease;
    display: inline-block;
    margin-left: 6px;
    color: #1967d2;
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
  }
}

@media (max-width: 400px) {
  .listing-user .user-name:before {
    font-size: 18px;
  }
}

.dashboard-user-section .listing-user {
  margin-right: 0px;
  position: relative;
  padding: 14px 0px;

  .user-name {
    color: inherit;

    &:after {
      color: inherit;
    }
  }
}

@media (max-width: 400px) {
  .dashboard-user-section .listing-user .user-name {
    font-size: 0px;
  }
}

.wt-admin-right-page-header {
  position: relative;
  padding: 15px 0px;
  margin-bottom: 20px;

  h2 {
    font-size: 28px;
    color: #17171d;
    font-weight: 400;
    margin-bottom: 15px;
  }

  .breadcrumbs {
    position: relative;

    a {
      margin-right: 24px;
      position: relative;
      font-weight: 400;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: -16px;
        margin-top: -3px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: #0f1221;
      }
    }

    span {
      margin-right: 24px;
      position: relative;
      font-weight: 400;
      color: #1967d2;
    }
  }
}

.content-admin-main .panel.panel-default {
  border-radius: 10px;
  background: #fff;
}

.wt-panel-heading {
  border-bottom: 1px solid #ddd;
}

/*My Account css*/

#header-admin {
  width: calc(100% - 320px);
  background: #fff;
  border-bottom: 1px solid #ddd;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  transition: all 0.3s;

  &.active {
    width: 100%;
  }

  .container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%;
  }
}

@media (max-width: 1360px) {
  #header-admin {
    width: calc(100% - 260px);
  }
}

#sidebar-admin-wraper {
  width: 320px;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  z-index: 999;
  background: #d4e6ff;
  color: #17171d;
  transition: 0.5s all ease;

  &.active {
    margin-left: -320px;
  }
}

@media (max-width: 1360px) {
  #sidebar-admin-wraper {
    width: 260px;
  }
}

@media (max-width: 768px) {
  #sidebar-admin-wraper.active {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  #sidebar-admin-wraper {
    margin-left: -320px;
  }
}

#content {
  width: calc(100% - 320px);
  padding-top: 70px;
  min-height: 100vh;
  transition: 0.5s all ease;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #f5f7f9;

  &.active {
    width: 100%;
    overflow: hidden;

    .content-admin-main {
      min-width: 480px;
    }
  }
}

@media (max-width: 1360px) {
  #content {
    width: calc(100% - 260px);
  }
}

@media (max-width: 768px) {
  #content.active {
    width: calc(100% - 260px);
  }
}

@media (max-width: 768px) {
  #content {
    width: 100%;
  }
}

@media (max-width: 768px) {
  #header-admin {
    width: 100%;

    &.active {
      width: calc(100% - 260px);
    }
  }
}

/*Dashboard message Dropdown*/

.dashboard-message-dropdown .dropdown-menu,
.dashboard-noti-dropdown .dropdown-menu {
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  border: 0px;
}

/*Dashboard Notification Dropdown*/

/*Dashboard Message*/

.jobzilla-admin-messange,
.jobzilla-admin-notification {
  color: #999;
  font-size: 18px;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  position: relative;
  top: 10px;

  &.dropdown-toggle:after {
    display: none;
  }

  span {
    position: absolute;
    top: -15px;
    right: -12px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    border-radius: 100%;
    background-color: #1967d2;
  }
}

/*Dashboard Notification*/

.page-logo {
  padding: 40px 10px 20px 30px;
  margin-bottom: 16px;

  a img {
    max-width: 130px;
  }
}

/*Admin Nav*/

.admin-nav {
  &.scrollbar-macosx {
    height: calc(100vh - 150px);
    padding-left: 30px;
    overflow-y: scroll;
  }

  ul {
    list-style: none;
    margin: 0;
    font-weight: 500;

    li {
      float: none;

      &:last-child {
        border-bottom: none;
      }

      &.has-child {
        position: relative;
      }

      a > i {
        font-size: 18px;
        margin-right: 25px;
        position: absolute;
        left: 10px;
        top: 14px;
      }

      .sub-menu {
        opacity: 1;
        margin-left: 30px;
        background: #f5f7f9;
        border-radius: 10px 0px 0px 10px;

        > li a {
          padding: 5px 20px;
          color: #1967d2;
          border-radius: 5px;
          margin-bottom: 1px;
          font-size: 13px;
          font-weight: normal;

          &:hover {
            color: #17171d;
          }

          > i {
            font-size: 12px;
          }
        }
      }
    }

    > li {
      > a {
        padding: 10px 15px 10px 40px;
        color: #323232;
        display: block;
        font-size: 16px;
        position: relative;
        font-weight: 400;
        transition: 0.5s all ease;

        &:hover {
          color: #1967d2;
        }
      }

      ul,
      .sub-menu {
        display: none;
        position: static;
        visibility: visible;
        width: auto;
      }

      &.active > a {
        color: #1967d2;
        background-color: #f5f7f9;
        border-radius: 10px 0px 0px 10px;
      }

      &.has-child.nav-active > .submenu-toogle:before {
        content: "\f107";
      }
    }
  }

  .has-child {
    .submenu-toogle {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #fff;
      z-index: 1;
      cursor: pointer;
      padding: 7px;
      font-size: 16px;
      display: none;
      display: block;
      color: #000;
    }

    li .submenu-toogle {
      opacity: 0.9;
    }

    &.nav-active > a + .submenu-toogle.fa-angle-down:before {
      content: "\f106";
    }
  }
}

.content-admin-main {
  padding: 30px;

  textarea.form-control {
    height: auto;
    resize: none;
  }
}

@media (max-width: 540px) {
  .content-admin-main {
    padding: 15px;
  }
}

.ls-inputicon-box {
  position: relative;

  .fs-input-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 60px;
    z-index: 5;
    line-height: 60px;
    text-align: center;
    font-size: 16px;
    color: #1967d2;
  }

  .form-control,
  .bootstrap-select .dropdown-toggle {
    padding-left: 35px;
  }

  .remove_field {
    position: absolute;
    right: 4px;
    top: 2px;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    display: block;
  }
}

/*Form Elements*/

.panel-default > .panel-heading {
  .panel-tittle i {
    margin-right: 20px;
  }

  .panel-heading-left {
    float: left;
  }

  .panel-heading-right {
    float: right;
  }

  + .panel-collapse > .panel-body {
    border-top-color: #e7ecf1;
  }
}

.dropdown.bootstrap-select.wt-select-box {
  width: 100% !important;

  .btn-light {
    background-color: #dbe6f7;
    border-color: #dbe6f7;
  }

  .form-control {
    height: 30px;
  }

  .dropdown-toggle {
    height: 60px;
    border-radius: 10px;

    &:focus {
      box-shadow: none !important;
      outline: none !important;
      background-color: #f8f8f8;
    }
  }
}

.bootstrap-select.wt-select-box .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden;
  padding-top: 12px;
}

.wt-input-icon .input-group-addon2 {
  position: absolute;
  left: 0px;
  top: 12px;
  z-index: 4;
  border: none;
  padding: 0;
  color: #222;
  border-radius: 6px;
  background: none;
}

.form-group.form-inline {
  .radio,
  .checkbox {
    margin-right: 15px;
  }
}

/*Dashboard Card*/

.dashboard-card {
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
}

.wt-card-right {
  text-align: right;
  font-size: 50px;
  line-height: 50px;
  font-weight: 300;
  margin-bottom: 10px;
}

.wt-card-bottom {
  text-align: right;

  h4 {
    font-weight: 400;
  }
}

.wt-card-icon {
  font-size: 48px;
  line-height: 0px;
  color: rgba(0, 0, 0, 0.1);
  z-index: -1;
  position: absolute;
  left: 20px;
  top: 20px;
}

/*Upload Video Button*/

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;

  input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
}

/*dashboard List*/

.dashboard-list-box {
  ul {
    list-style: none;
    margin-bottom: 0px;

    li {
      display: block;
      padding: 15px 35px 15px 0px;
      border-bottom: 1px solid #e7ecf1;
      position: relative;

      &:last-child {
        border-bottom: 0px;
      }

      a {
        padding: 0px 3px;
        font-weight: 500;
      }

      &:hover a.close-list-item {
        display: block;
      }
    }
  }

  a.close-list-item {
    color: red;
    position: absolute;
    right: 20px;
    font-weight: normal;
    padding: 0px 6px;
    display: none;
    right: 20px;
    top: 20px;

    i {
      padding-right: 5px;
    }
  }

  &.list-box-with-icon ul li .list-box-icon {
    font-size: 18px;
    vertical-align: middle;
    margin-right: 0px;
    position: relative;
    left: -10px;
  }
}

.list-box-with-icon ul li {
  padding: 15px 80px 15px 50px;
  position: relative;

  .list-box-icon {
    margin-left: -20px;
  }
}

@media (max-width: 540px) {
  .list-box-with-icon ul li {
    padding: 15px 15px 15px 50px;
  }
}

/*dashboard message*/

.dashboard-messages-box-scroll {
  height: 394px;
  overflow-y: scroll;
}

.dashboard-messages-box {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 10px;

  &:first-child {
    padding-top: 20px;
  }

  &:last-child {
    border-bottom: 0px;
  }
}

.dashboard-message-avtar {
  float: left;
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-left: 20px;
  border-radius: 10px;

  img {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 480px) {
  .dashboard-message-avtar {
    float: none;
  }
}

.dashboard-message-area {
  margin-left: 50px;
  text-align: left;
  padding-left: 50px;
  padding-right: 20px;

  p {
    margin-bottom: 20px;
  }

  h5 {
    position: relative;
    display: flex;
    justify-content: space-between;

    i {
      position: absolute;
      right: 0px;
      top: 0px;
      font-size: 18px;
      line-height: 40px;
      font-style: normal;
      color: #fff;
      width: 40px;
      height: 40px;
      background-color: #64bc36;
      text-align: center;
      border-radius: 50%;
    }

    span {
      font-size: 12px;
      color: #1967d2;
      display: block;
      font-weight: 500;
      padding-top: 4px;
    }
  }

  .dropdown-toggle {
    color: #1967d2;

    &:after {
      display: none;
    }
  }

  .dashboard-message-reply-textarea .form-control {
    border-radius: 10px !important;
  }
}

@media (max-width: 480px) {
  .dashboard-message-area {
    padding: 15px;
    margin-left: 0px;
  }
}

.dashboard-message-reply {
  margin-right: 20px;

  .acod-head a {
    font-size: 16px;
    border: none;
    padding: 0px;
    display: inline-block;
    color: #000;
    font-weight: 700;

    &:hover {
      color: #f96b39;
    }
  }
}

.dashboard-message-reply-textarea {
  margin-top: 20px;
}

/*Edit Profile*/

.dashboard-profile-photo {
  position: relative;
  display: block;
  margin-bottom: 10px;

  img {
    border-radius: 10px;
    max-width: 151px;
    width: 100%;
    padding: 5px;
    border: 1px solid #dbe6f7;
  }

  .upload-btn-wrapper {
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
}

.dashboard-cover-pic .dropzone {
  margin-bottom: 10px;
}

/*Dashboard Widget*/

.dashboard-widgets-header {
  padding: 20px 20px 0px 20px;
  font-weight: 500;
  font-size: 16px;
  color: #1967d2;
}

/*notification list*/

.dashboard-widget-scroll {
  position: relative;
  padding: 20px;
}

.noti-list ul {
  list-style: none;
  margin-bottom: 10px;

  li {
    position: relative;
    padding: 5px 15px 5px 20px;
    display: block;
    border-bottom: 1px solid #ddd;
    font-size: 16px;

    a {
      color: #000;
    }

    .noti-icon {
      position: absolute;
      left: 0px;
      top: 4px;
      font-size: 16px;
      color: #2db346;
    }

    .noti-texting b {
      font-weight: 500;
    }
  }
}

.noti-view-all {
  padding: 0px;

  a {
    color: #1967d2;
  }
}

/*Message list*/

.message-list ul {
  list-style: none;
  margin-bottom: 10px;

  li {
    position: relative;
    padding: 15px 0px 15px 0px;
    display: block;
    border-bottom: 1px solid #ddd;
    font-size: 12px;

    .msg-avtar {
      float: left;

      img {
        height: 30px;
        margin-top: 4px;
        border-radius: 10px;
      }
    }

    .msg-texting {
      overflow: hidden;
      padding-left: 10px;

      strong {
        color: #17171d;
        font-size: 16px;
        font-weight: 500;
      }

      .msg-time {
        float: right;
        color: #1967d2;
        font-weight: 500;

        span {
          padding-right: 3px;
        }
      }

      p {
        margin-bottom: 0px;
        line-height: inherit;
      }
    }
  }
}

.message-view-all {
  padding: 0px;

  a {
    color: #1967d2;
  }
}

/*Msg style 2*/

.wt-admin-dashboard-msg-2 {
  display: flex;
}

.wt-dashboard-msg-box {
  display: block;
}

.wt-dashboard-msg-user-list {
  display: block;
  width: 100%;
  margin-right: 30px;
  max-width: 400px;
}

.wt-dashboard-msg-search {
  position: relative;
  padding: 20px;
  background-color: #fff;

  .form-control {
    padding-right: 40px;
    border-radius: 10px !important;
    border: 0px;
  }

  .btn {
    outline: none !important;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #1967d2;
    font-size: 20px;
    z-index: 10;
    background: none;
  }
}

.msg-find-list .bootstrap-select .dropdown-toggle {
  border: 0px;
  box-shadow: none !important;
  border-radius: 0px;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-bottom: 1px solid #dbdbdb;

  &:hover,
  &:active,
  &:focus {
    border: 0px solid transparent !important;
    border-bottom: 1px solid #dbdbdb !important;
  }
}

.wt-dashboard-msg-search-list {
  background-color: #fff;
  height: 700px;
}

.wt-dashboard-msg-search-list-wrap {
  display: table;
  width: 100%;

  .msg-user-info {
    position: relative;
    display: block;
    padding: 30px 80px 30px 20px;
    background-color: #fff;
    margin: 0px 20px;
    border-radius: 10px;
  }

  &:hover .msg-user-info {
    background-color: #dbe6f7;
  }
}

@media (max-width: 1366px) {
  .wt-dashboard-msg-search-list-wrap .msg-user-info {
    padding: 40px 0px 10px 0px;
  }
}

.msg-user-timing {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 12px;
  color: #969696;
}

.msg-user-info-text {
  overflow: hidden;
}

.msg-user-info-pic {
  width: 40px;
  height: 40px;
  float: left;
  margin-right: 15px;
  overflow: hidden;
  border-radius: 6px;
}

.msg-user-name {
  font-size: 16px;
  color: #1967d2;
  font-weight: 500;
}

.msg-user-discription {
  font-size: 16px;
  color: #222;
}

.wt-dashboard-msg-box {
  background-color: #fff;
  position: relative;
  flex: 1;
}

.single-msg-user-name-box {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: #fff;
  border-bottom: 1px solid #dbdbdb;
  min-height: 85px;

  .message-action {
    color: #ff0000;
    font-size: 13px;
  }

  h4 {
    margin-bottom: 6px;
  }
}

.single-user-msg-conversation {
  padding: 0px;
  margin: 30px 40px;
  height: 550px;
}

@media (max-width: 540px) {
  .single-user-msg-conversation {
    margin: 10px;
  }
}

.single-user-comment-block {
  position: relative;
}

.single-user-comment-wrap {
  padding: 15px 0px;
  margin: 0px 15px;

  &.sigle-user-reply {
    margin-left: 0px;

    .single-user-com-text {
      background-color: #f5f5f5;
    }
  }
}

@media (max-width: 991px) {
  .single-user-comment-wrap.sigle-user-reply {
    margin-left: 15px;
  }
}

@media (max-width: 575px) {
  .single-user-comment-wrap {
    margin: 0px 10px;
  }
}

.single-user-com-pic {
  width: 60px;
  height: 60px;
  float: left;
  margin-right: 15px;
  overflow: hidden;
  padding: 3px;
  background-color: #fff;

  img {
    border-radius: 6px;
  }
}

@media (max-width: 575px) {
  .single-user-com-pic {
    float: none;
  }
}

.single-user-com-text {
  overflow: hidden;
  background-color: #edf6ff;
  padding: 20px 30px;
  margin-top: 10px;
  border-radius: 10px;
}

.single-user-msg-time {
  font-size: 12px;
  color: #969696;
  text-align: right;
  padding-top: 10px;
}

.single-msg-reply-comment {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;

  textarea {
    resize: none;

    &.form-control {
      border-radius: 0px;
      border-left: 0px;
      border-right: 0px;
      border-bottom: 0px;
      box-shadow: none;
      padding: 15px 50px 15px 20px;
      height: 60px;
    }
  }

  .btn {
    outline: none !important;
    position: absolute;
    right: 0;
    top: 50%;
    color: #1967d2;
    font-size: 20px;
    z-index: 10;
    background: none;
    transform: translateY(-50%);
  }
}

/*msg user list side button*/

.user-msg-list-btn-outer {
  display: none;
  position: absolute;
  top: 50%;
  right: 99%;
  z-index: 10;
  transform: translateY(-50%);

  &.active {
    .user-msg-list-btn-open {
      display: none;
    }

    .user-msg-list-btn-close {
      display: block;
    }
  }

  .user-msg-list-btn-open {
    display: inline-block;
    cursor: pointer;
    white-space: nowrap;
    background-color: #1967d2;
    border: none;
    padding: 2px 15px;
    color: #fff;
    position: absolute;
    transform: rotate(270deg);
    transform-origin: center right;
    border-radius: 8px;
  }

  .user-msg-list-btn-close {
    cursor: pointer;
    white-space: nowrap;
    background-color: #1967d2;
    border: none;
    padding: 2px 15px;
    color: #fff;
    position: absolute;
    transform: rotate(270deg);
    transform-origin: center right;
    display: none;
    top: -31px;
    right: 18px;
    z-index: 10;
    border-radius: 8px;
  }
}

@media (max-width: 991px) {
  .user-msg-list-btn-outer {
    display: block;
    z-index: 999;
  }
}

.wt-admin-dashboard-msg-2 {
  .user-msg-list-btn-open {
    top: -62px;
    right: 20px;
  }

  .wt-dashboard-msg-user-list {
    width: 420px;
    transition: all 0.5s linear;
  }

  &.active {
    .user-msg-list-btn-open {
      right: 257px;
      display: none;
    }

    .user-msg-list-btn-close {
      display: block;
    }
  }
}

@media (max-width: 1360px) {
  .wt-admin-dashboard-msg-2 .wt-dashboard-msg-user-list {
    width: 250px;
  }
}

@media (max-width: 991px) {
  .wt-admin-dashboard-msg-2 .wt-dashboard-msg-user-list {
    position: fixed;
    right: -250px;
    top: 0px;
    z-index: 999;
    width: 250px;
    margin-right: 0px;
    box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
    transition: all 0.5s linear;
  }
}

@media (max-width: 991px) {
  .wt-admin-dashboard-msg-2.active .wt-dashboard-msg-user-list {
    right: 0%;
  }
}

.gradi-1 {
  color: rgb(78, 215, 207);
}

.gradi-2 {
  color: rgb(234, 111, 157);
}

.gradi-3 {
  color: rgb(98, 124, 235);
}

.gradi-4 {
  color: rgb(251, 188, 117);
}

/*Chart*/

.twm-pro-view-chart {
  padding: 30px;

  #profileViewChart {
    height: 334px !important;
  }
}

@media (max-width: 540px) {
  .twm-pro-view-chart {
    padding: 5px;
  }
}

@media (max-width: 540px) {
  .twm-pro-view-chart #profileViewChart {
    height: auto !important;
  }
}

/*Dashboard candidates style*/

.twm-dashboard-candidates-wrap {
  padding: 30px;

  .twm-dash-candidates-list {
    background-color: #fff;
    border: 1px solid #ddd;
    position: relative;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    width: 100%;
    margin-bottom: 30px;

    .twm-media {
      width: 70px;
      display: inline-block;
      margin-right: 10px;

      .twm-media-pic {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        margin-bottom: 10px;

        img {
          display: inline-block;
        }
      }
    }

    p {
      font-size: 16px;
      margin-bottom: 5px;
    }

    .twm-mid-content {
      flex-grow: 1;
    }

    .twm-fot-content {
      display: flex;
      justify-content: space-between;

      .twm-jobs-vacancies {
        font-weight: 500;
        color: #17171d;
        font-size: 16px;

        span {
          color: #046c38;
          margin-left: 5px;
        }
      }

      .twm-left-info {
        display: flex;
        justify-content: space-between;
      }

      .twm-candidate-address {
        font-size: 16px;
        margin-bottom: 0px;
        margin-right: 30px;
        font-weight: 500;

        i {
          margin-right: 5px;
        }
      }

      .twm-view-prifile {
        font-weight: 600;
        font-size: 18px;
      }
    }

    .twm-controls-icon {
      display: flex;
      margin-bottom: 0px;

      li {
        margin-right: 3px;
        text-align: center;

        &:last-child {
          margin-right: 0px;
        }

        button {
          background-color: #dbe6f7;
          color: #1967d2;
          border: none;
          border-radius: 4px;
          width: 24px;
          height: 24px;
          line-height: 24px;
          font-size: 12px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: #1967d2;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .twm-dashboard-candidates-wrap {
    padding: 15px;
  }
}

@media (max-width: 540px) {
  .twm-dashboard-candidates-wrap .twm-dash-candidates-list {
    display: block;
    text-align: center;
  }
}

@media (max-width: 540px) {
  .twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-media {
    margin-right: 0px;
    width: 60px;
  }
}

@media (max-width: 540px) {
  .twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-fot-content {
    display: block;
    text-align: center;
  }
}

@media (max-width: 540px) {
  .twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-fot-content .twm-left-info {
    display: block;

    p {
      margin-right: 0px;
    }
  }
}

@media (max-width: 540px) {
  .twm-dashboard-candidates-wrap .twm-dash-candidates-list .twm-fot-content .twm-right-btn {
    display: inline-block;
  }
}

/* Data Table Candidets */

.twm-DT-candidates-list {
  position: relative;
  display: flex;
  width: 100%;

  .twm-media {
    width: 100%;
    max-width: 50px;
    display: inline-block;
    margin-right: 10px;

    .twm-media-pic {
      width: 40px;
      height: 40px;
      border-radius: 5px;
      overflow: hidden;
      position: relative;

      img {
        display: inline-block;
      }
    }
  }

  .twm-job-title h4 {
    color: #1967d2;
    font-size: 16px;
    font-weight: 500;
  }

  .twm-candidate-address {
    font-size: 16px;
    margin-bottom: 0px;

    i {
      margin-right: 5px;
    }
  }
}

.twm-table-controls {
  display: flex;

  .twm-DT-controls-icon {
    display: flex;
    margin-bottom: 0px;
    margin-left: auto;

    li {
      margin-right: 3px;

      &:last-child {
        margin-right: 0px;
      }

      button {
        background-color: #dbe6f7;
        color: #1967d2;
        border: none;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #1967d2;
          color: #fff;
        }
      }
    }
  }
}

.add_field_youtube,
.add_field_vimeo,
.add_field_custom {
  background: transparent;
  border: none;
  font-size: 13px;
  color: #1967d2;
}

.twm-D_table {
  padding: 20px;

  div.dataTables_wrapper div {
    &.dataTables_length select {
      width: 100px;
      border-radius: 6px;
      margin: 0px 5px;
      border: none;
      height: 40px;
      background-color: #dbe6f7;
    }

    &.dataTables_filter input {
      height: 40px;
      border-radius: 6px;
    }
  }

  .page-link {
    height: 50px;
    line-height: 50px;
    text-align: center;
    padding: 0px 20px;

    &:focus {
      box-shadow: none;
    }
  }

  .page-item.active .page-link {
    background-color: #1967d2;
  }
}

@media (max-width: 400px) {
  .twm-D_table .page-link {
    height: 40px;
    line-height: 40px;
    padding: 0px 10px;
  }
}

/*default table*/

.twm-table {
  position: relative;
  background: #fff;
  border: 0;
  border-radius: 5px;
  margin-bottom: 0px;
  overflow: hidden;
  width: 100%;

  thead {
    border-radius: 8px;
    color: #fff;

    th {
      position: relative;
      padding: 15px 20px;
      font-size: 16px;
      color: #17171d;
      font-weight: 500;
      line-height: 30px;
      white-space: nowrap;
    }
  }

  tbody tr {
    position: relative;
    border-bottom: 1px solid #ecedf2;

    td {
      position: relative;
      padding: 20px 20px;
      font-size: 16px;
      color: #17171d;
      font-weight: 400;

      button {
        border: none;
        background: transparent;
        display: contents;
        margin-bottom: 5px;

        .fa {
          background-color: #dbe6f7;
          color: #1967d2;
          border-radius: 4px;
          width: 24px;
          height: 24px;
          line-height: 24px;
          font-size: 12px;
        }

        &:hover .fa {
          background-color: #1967d2;
          color: #fff;
        }
      }
    }
  }

  tr td {
    position: relative;
    padding: 21px 20px;
    font-size: 16px;
    color: #696969;
    font-weight: 400;
  }
}

@media (max-width: 767px) {
  .twm-table tbody tr td {
    padding: 20px 10px;
  }
}

@media (max-width: 767px) {
  .twm-table tr td {
    padding: 21px 10px;
  }
}

/* Data Table Candidets */

.twm-bookmark-list-wrap {
  .twm-bookmark-list {
    position: relative;
    display: flex;
    width: 100%;

    .twm-media {
      width: 100%;
      max-width: 90px;
      display: inline-block;
      margin-right: 20px;
      border-radius: 10px;
      height: 90px;

      .twm-media-pic {
        width: 90px;
        height: 90px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        border: 1px solid #ddd;

        img {
          display: inline-block;
        }
      }
    }

    .twm-job-title h4 {
      color: #1967d2;
      font-size: 16px;
      font-weight: 500;
    }

    .twm-bookmark-address {
      font-size: 16px;
      margin-bottom: 10px;

      i {
        margin-right: 5px;
      }
    }
  }

  .twm-job-post-duration {
    color: #92388e;
    font-size: 16px;
  }
}

/* Data Table*/

.twm-D_table {
  .table > :not(caption) > * > * {
    padding: 14px;
  }

  .dataTables_length {
    margin-bottom: 10px;
  }
}

/* Model Popup */

.twm-model-popup .modal-content {
  border-top: 6px solid #1967d2;
  border-radius: 10px;
  overflow: hidden;

  .modal-header {
    border: none;
  }

  .modal-title {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
  }

  .modal-footer {
    background-color: #dbe6f7;
    justify-content: center;

    .site-button {
      padding: 4px 20px;
    }
  }
}

.twm-msg-noti {
  background-color: #1967d2;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 100%;
}

@media (max-width: 575px) {
  h3.twm-title {
    font-size: 16px;
  }
}

.main-bar {
  width: 100%;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
}

.color-fill .main-bar {
  position: fixed;
  animation: 0.95s ease 0s normal forwards 1 running mainBar_on_scrollAnimatio;
}

@keyframes mainBar_on_scrollAnimatio {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

/*----------------------------
	Home Page 3 Css
------------------------------*/
/*Blog post 3*/

.twm-blog-post-3-outer-wrap {
  padding-top: 20px;
}

.twm-blog-post-3-outer {
  .wt-post-media {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ddd;
    padding: 20px;
    margin-top: 30px;

    img {
      width: 100%;
      border-radius: 10px;
      margin-top: -50px;
    }
  }

  .wt-post-info {
    padding: 20px;

    .wt-post-meta {
      position: relative;
      margin-bottom: 10px;

      ul {
        list-style: none;
        margin-bottom: 0px;

        li {
          position: relative;
          z-index: 1;
          display: inline-block;

          &.post-date {
            color: #17171d;
            font-size: 16px;
            line-height: 28px;
            margin-right: 5px;
            font-weight: 600;
          }

          &.post-author {
            color: #17171d;
            font-weight: 600;

            a {
              color: #1967d2;
              display: inline-block;
            }
          }
        }
      }
    }

    .wt-post-title .post-title {
      margin-bottom: 0px;
    }
  }
}

/* Compny Approch 2*/

.twm-company-approch2-outer {
  position: relative;
  margin-bottom: 30px;
  padding-top: 60px;
  border-top: 1px solid #ddd;

  .twm-company-approch2 {
    max-width: 650px;
    margin: 0px auto;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .twm-company-approch2-outer .twm-company-approch2 {
    max-width: 520px;
  }
}

/*Client carousel 3*/

.home-client-carousel3 {
  padding: 0px;
  margin-bottom: 20px;

  .ow-client-logo {
    height: 100px;
    display: flex;
    align-items: center;
  }

  .client-logo {
    max-width: 130px;
    margin-left: auto;
    margin-right: auto;

    a {
      height: auto;
      display: flex;
      justify-content: space-around;

      img {
        width: auto;
        height: auto;
      }

      &:hover img {
        filter: grayscale(100%);
        transition: 0.5s all ease;
      }
    }
  }
}

@media (max-width: 991px) {
  .home-client-carousel3 {
    margin-bottom: 30px;
  }
}

/*process steps 3*/

.twm-how-it-work-section3 .row {
  display: flex;
  justify-content: center;
}

.twm-w-process-steps3 {
  margin-bottom: 30px;
  padding: 15px 15px 30px 30px;
  background-color: #fff;
  border-radius: 10px;
  height: 90%;

  p {
    margin-bottom: 0px;
  }

  .twm-large-number {
    font-size: 65px;
    line-height: 65px;
    // font-weight: 800;
    // font-family: "Poppins", sans-serif;
  }

  .twm-w-pro-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .twm-media {
      width: 90px;
      height: 95px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
    }

    .twm-title {
      color: #17171d;
      margin-bottom: 0px;
    }
  }
}

@media (max-width: 420px) {
  .twm-w-process-steps3 .twm-large-number {
    font-size: 40px;
  }
}

/*Featured cities*/

.twm-featured-city {
  margin-bottom: 30px;

  &.twm-large-block img {
    object-fit: cover;
    min-height: 520px;
  }

  .twm-media {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);

    .twm-city-info {
      position: absolute;
      left: 0px;
      bottom: 0px;
      background-color: #fff;
      padding: 10px 50px 10px 20px;

      .twm-city-jobs {
        color: #1967d2;
      }

      .twm-title {
        margin-bottom: 0px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .twm-featured-city.twm-large-block img {
    min-height: 426px;
  }
}

@media (max-width: 991px) {
  .twm-featured-city.twm-large-block img {
    min-height: auto;
  }
}

/* ---- Home Page 3 Banner Section Css Start ---- */

.twm-home3-banner-section {
  position: relative;
  padding-top: 315px;
  overflow: hidden;

  .twm-home3-inner-section .twm-bnr-mid-section {
    max-width: 720px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: 160px;

    .twm-bnr-title-large {
      font-size: 66px;
      line-height: 70px;
      text-transform: uppercase;
      font-weight: 500;
      color: #fff;
    }

    .twm-bnr-title-light {
      font-size: 66px;
      line-height: 70px;
      font-weight: 300;
      color: #fff;
      margin-bottom: 30px;
    }

    .twm-bnr-discription {
      font-size: 18px;
      margin-bottom: 50px;
      color: #fff;
    }

    .twm-bnr-popular-search {
      position: relative;
      font-size: 16px;
      margin-top: 30px;
      color: #fff;

      .twm-title {
        margin-right: 20px;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
      }

      a {
        color: #fff;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .twm-bnr-bottom-section {
    padding: 0px 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .twm-browse-jobs {
      font-size: 60px;
      font-weight: 700;
      opacity: 0.3;
      text-transform: uppercase;
      color: #fff;
    }

    .twm-bnr-blocks-wrap {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .twm-bnr-blocks {
        display: flex;
        position: relative;
        padding: 10px 20px;
        border-radius: 10px;
        margin: 0px 10px;

        .twm-content {
          .tw-count-number {
            font-size: 38px;
            line-height: 38px;
            font-weight: 700;

            .counter {
              font-size: 38px;
              line-height: 40px;
              font-weight: 600;
            }

            &.text-clr-pink {
              color: #e47dff;
            }

            &.text-clr-yellow {
              color: #e2d438;
            }
          }

          .icon-content-info {
            color: #fff;
            margin-bottom: 0px;
            font-size: 16px;
          }
        }
      }

      .twm-bnr-blocks-3 {
        display: flex;
        position: relative;
        margin: 0px 10px;

        .twm-pics {
          margin-left: 15px;
          margin-right: 15px;
          display: flex;
          align-items: center;

          span {
            display: inline-block;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            border: 2px solid #fff;
            overflow: hidden;
            margin-left: -15px;
          }
        }

        .twm-content {
          .tw-count-number {
            font-size: 38px;
            line-height: 38px;
            font-weight: 700;
            // font-family: "Josefin Sans", sans-serif;

            .counter {
              font-size: 38px;
              line-height: 40px;
              font-weight: 600;
              // font-family: "Josefin Sans", sans-serif;
            }

            &.text-clr-green {
              color: #00ffc6;
            }
          }

          .icon-content-info {
            color: #fff;
            margin-bottom: 0px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .twm-home3-banner-section {
    padding-top: 240px;
  }
}

@media (max-width: 767px) {
  .twm-home3-banner-section {
    padding-top: 170px;
  }
}

@media (max-width: 1600px) {
  .twm-home3-banner-section .twm-home3-inner-section .twm-bnr-mid-section {
    padding-bottom: 100px;
  }
}

@media (max-width: 767px) {
  .twm-home3-banner-section .twm-home3-inner-section .twm-bnr-mid-section {
    padding: 0px 15px 30px 15px;
  }
}

@media (max-width: 991px) {
  .twm-home3-banner-section .twm-home3-inner-section .twm-bnr-mid-section .twm-bnr-title-large {
    font-size: 38px;
    line-height: 38px;
    margin-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .twm-home3-banner-section .twm-home3-inner-section .twm-bnr-mid-section .twm-bnr-title-large {
    font-size: 28px;
    line-height: 36px;
  }
}

@media (max-width: 991px) {
  .twm-home3-banner-section .twm-home3-inner-section .twm-bnr-mid-section .twm-bnr-title-light {
    font-size: 38px;
    line-height: 38px;
    margin-bottom: 10px;
  }
}

@media (max-width: 575px) {
  .twm-home3-banner-section .twm-home3-inner-section .twm-bnr-mid-section .twm-bnr-title-light {
    font-size: 28px;
    line-height: 36px;
  }
}

@media (max-width: 1199px) {
  .twm-home3-banner-section .twm-bnr-bottom-section {
    display: block;
    text-align: center;
  }
}

@media (max-width: 540px) {
  .twm-home3-banner-section .twm-bnr-bottom-section {
    padding: 0px 15px;
  }
}

@media (max-width: 1440px) {
  .twm-home3-banner-section .twm-bnr-bottom-section .twm-browse-jobs {
    font-size: 40px;
  }
}

@media (max-width: 540px) {
  .twm-home3-banner-section .twm-bnr-bottom-section .twm-browse-jobs {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .twm-home3-banner-section .twm-bnr-bottom-section .twm-bnr-blocks-wrap {
    display: block;
  }
}

@media (max-width: 767px) {
  .twm-home3-banner-section .twm-bnr-bottom-section .twm-bnr-blocks-wrap .twm-bnr-blocks {
    display: inline-block;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .twm-home3-banner-section .twm-bnr-bottom-section .twm-bnr-blocks-wrap .twm-bnr-blocks-3 {
    margin-left: auto;
    margin-right: auto;
    max-width: 225px;
    margin-bottom: 30px;
  }
}

/* ---- categories style 3 ---- */

.job-categories-3-wrap {
  margin-bottom: 40px;
  margin-top: 10px;
}

.job-categories-3 {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  position: relative;
  text-align: center;
  height: 100%;
  top: 40px;
  transition: 0.5s all ease;

  .twm-media {
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(56, 152, 226, 0.3);
    display: inline-block;
    margin-bottom: -40px;
    position: relative;
    top: -70px;
    background-color: #fff;
    transition: 0.5s all ease;

    img {
      width: auto;
    }
  }

  .twm-content {
    .twm-jobs-available {
      background-color: #1967d2;
      color: #fff;
      font-size: 16px;
      border-radius: 5px;
      padding: 4px 15px;
      display: inline-block;
      margin-bottom: 10px;
    }

    a {
      margin-bottom: 0px;
      display: block;
    }
  }

  &:hover {
    background-color: #1967d2;

    .twm-content {
      .twm-jobs-available {
        background-color: #fff;
        color: #1967d2;
      }

      a {
        color: #fff;
      }
    }
  }
}

/* Home Page 4 Banner Section Css Start*/

.twm-home4-banner-section {
  max-width: 1560px;
  margin: 0px auto;
  border-radius: 80px;
  padding: 50px 125px;
  overflow: hidden;

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .twm-bnr-left-section {
      max-width: 650px;
      margin-left: auto;
      position: relative;
      z-index: 2;
      padding-right: 62px;

      .twm-bnr-title-large {
        font-size: 66px;
        line-height: 72px;
        font-weight: 500;
        color: #17171d;
        margin-bottom: 30px;

        span {
          text-decoration: underline;
        }
      }

      .twm-bnr-discription {
        font-size: 18px;
        margin-bottom: 30px;
      }
    }

    .twm-bnr-right-section {
      max-width: 690px;
      width: 100%;
      padding-top: 15%;
      position: relative;

      .twm-graphics-h3 {
        position: absolute;
      }

      .twm-graphics-user {
        position: relative;
        z-index: 4;
      }

      .twm-bg-line {
        z-index: 1;
        left: -5%;
        top: 14%;
        width: 93.5%;
      }

      .twm-user {
        left: 0%;
        top: 13%;
        width: 50.4%;
      }

      .twm-bg-plate {
        z-index: 2;
        right: -1%;
        top: 12%;
        width: 79%;
      }

      .twm-checked-plate {
        z-index: 3;
        left: 25%;
        top: 20%;
        width: 66.7%;
      }

      .twm-blue-block {
        z-index: 3;
        left: 31%;
        top: 56%;
        width: 26.5%;
      }

      .twm-color-dotts {
        z-index: 3;
        left: 29%;
        top: 65%;
        width: 23.8%;
      }

      .twm-card-large {
        z-index: 3;
        left: 38%;
        top: 0%;
        width: 59.7%;
      }

      .twm-card-s1 {
        z-index: 3;
        right: 5%;
        top: 39%;
        width: 29.9%;
      }

      .twm-card-s2 {
        z-index: 3;
        right: 15%;
        top: 59%;
        width: 29.9%;
      }

      .twm-white-dotts {
        z-index: 3;
        left: 30%;
        top: 3%;
        width: 17%;
      }

      .twm-top-shadow {
        z-index: 0;
        left: -17%;
        top: -10%;
        width: 68.6%;
      }

      .twm-bottom-shadow {
        z-index: 0;
        right: -5%;
        top: 38%;
        width: 68.6%;
      }
    }
  }
}

@media (max-width: 1199px) {
  .twm-home4-banner-section {
    padding: 100px 125px 50px 125px;
  }
}

@media (max-width: 991px) {
  .twm-home4-banner-section {
    padding: 40px 30px;
    margin: 40px;
  }
}

@media (max-width: 991px) {
  .twm-home4-banner-section {
    margin: 40px 15px;
  }
}

@media (max-width: 1360px) {
  .twm-home4-banner-section .row .twm-bnr-left-section {
    padding-left: 30px;
    padding-right: 0px;
  }
}

@media (max-width: 1199px) {
  .twm-home4-banner-section .row .twm-bnr-left-section {
    margin: 0px auto 50px;
  }
}

@media (max-width: 991px) {
  .twm-home4-banner-section .row .twm-bnr-left-section {
    margin: 0px auto 60px;
  }
}

@media (max-width: 575px) {
  .twm-home4-banner-section .row .twm-bnr-left-section {
    padding: 0px;
  }
}

@media (max-width: 1360px) {
  .twm-home4-banner-section .row .twm-bnr-left-section .twm-bnr-title-large {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 15px;
  }
}

@media (max-width: 575px) {
  .twm-home4-banner-section .row .twm-bnr-left-section .twm-bnr-title-large {
    font-size: 28px;
    line-height: 36px;
  }
}

@media (max-width: 1199px) {
  .twm-home4-banner-section .row .twm-bnr-right-section {
    margin: 0px auto 50px;
  }
}

.bg-none {
  background-color: transparent;

  &:focus {
    background-color: transparent;
  }
}

/* About-1 section*/

.twm-about-1-section-wrap {
  margin-bottom: 100px;

  > .row {
    display: flex;
    align-items: center;

    .twm-about-1-section {
      margin-right: 20px;
    }

    .twm-about-1-section-right {
      margin-left: 20px;
    }
  }
}

@media (max-width: 991px) {
  .twm-about-1-section-wrap {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .twm-about-1-section-wrap > .row .twm-about-1-section {
    margin-right: 0px;
    margin-bottom: 30px;
    text-align: center;
  }
}

@media (max-width: 414px) {
  .twm-about-1-bottom-wrap .twm-card-blocks {
    display: flex;
    flex-direction: column;
  }
}

.twm-about-1-bottom-wrap {
  max-width: 830px;
  margin: 0px auto 30px;

  .row {
    display: flex;
    justify-content: center;
  }

  .twm-card-blocks {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
    position: relative;
    padding: 12px 20px;
    border-radius: 10px;

    .twm-icon {
      width: 64px;
      height: 64px;
      line-height: 64px;
      border-radius: 64px;
      background-color: #1967d2;
      text-align: center;
      margin-right: 10px;

      &.pink {
        background-color: #c698d2;
      }

      img {
        width: 60%;
      }
    }

    .twm-content {
      .tw-count-number {
        font-size: 20px;
        line-height: 20px;
        font-weight: 700;

        .counter {
          font-size: 20px;
          line-height: 22px;
          font-weight: 600;
        }
      }

      .icon-content-info {
        color: #17171d;
        margin-bottom: 0px;
        font-size: 16px;
      }
    }
  }

  .twm-card-blocks-2 {
    background-color: #fff;
    border: 10px;
    box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
    position: relative;
    padding: 7px 20px;
    border-radius: 10px;

    .twm-pics {
      margin-left: 15px;
      margin-right: 15px;
      display: flex;
      align-items: center;

      span {
        display: inline-block;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 2px solid #fff;
        overflow: hidden;
        margin-left: -15px;
      }
    }

    .twm-content {
      display: flex;
      align-items: center;

      .tw-count-number {
        font-size: 38px;
        line-height: 38px;
        font-weight: 700;
        margin-right: 10px;

        .counter {
          font-size: 38px;
          line-height: 40px;
          font-weight: 600;
        }
      }

      .icon-content-info {
        color: #17171d;
        margin-bottom: 0px;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 991px) {
  .twm-about-1-bottom-wrap .row [class*="col-"]:last-child .twm-card-blocks {
    margin-bottom: 0px;
  }
}

@media (max-width: 991px) {
  .twm-about-1-bottom-wrap .twm-card-blocks {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .twm-about-1-bottom-wrap .twm-card-blocks-2 {
    margin-bottom: 30px;
  }
}

/* For employee Section*/

.twm-for-employee-4 {
  max-width: 1560px;
  margin: 120px auto;
  border-radius: 80px;
  padding: 120px 125px 0px 125px;
  overflow: hidden;

  .twm-for-employee-content {
    .row {
      display: flex;
      align-items: center;
    }

    .twm-explore-top-section {
      margin-bottom: 40px;
    }

    .twm-explore-right-section {
      position: relative;
      z-index: 1;
      height: 100%;

      .twm-media {
        text-align: center;
        position: relative;
        width: 100%;

        img {
          width: 100%;
        }

        .twm-employee-pic {
          position: relative;
          z-index: 1;
          width: 61.6%;
          left: 50%;
          transform: translateX(-50%);
        }

        .twm-bg-circle {
          z-index: 0;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 0%;
          width: 70.5%;
        }

        .twm-shot-pic1,
        .twm-shot-pic2,
        .twm-shot-pic3 {
          position: absolute;
        }

        .twm-shot-pic1 {
          top: 1%;
          right: 15%;
        }

        .twm-shot-pic2 {
          top: 45%;
          left: 10%;
        }

        .twm-shot-pic3 {
          bottom: 35%;
          right: 10%;
        }
      }

      .counter-outer-two {
        position: absolute;
        background-color: #fff;
        width: 220px;
        border-radius: 10px;
        padding: 10px;

        .tw-count-number {
          font-size: 38px;
          line-height: 38px;
          font-weight: 700;
          // font-family: "Josefin Sans", sans-serif;
        }

        .counter {
          font-size: 46px;
          line-height: 50px;
          font-weight: 600;
          // font-family: "Josefin Sans", sans-serif;
        }

        .icon-content-info {
          color: #17171d;
          margin-bottom: 0px;
          font-size: 16px;
        }

        &.one {
          top: 5%;
          left: 5%;
          z-index: 1;
        }

        &.two {
          top: 35%;
          right: 0%;
          z-index: 2;
        }

        &.three {
          bottom: 30%;
          left: 0%;
          z-index: 2;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .twm-for-employee-4 {
    padding: 40px 30px;
    margin: 40px;
  }
}

@media (max-width: 991px) {
  .twm-for-employee-4 {
    margin: 40px 15px;
  }
}

@media (max-width: 991px) {
  .twm-for-employee-4 .twm-for-employee-content .twm-explore-top-section {
    margin-right: 0px;
  }
}

@media (max-width: 767px) {
  .twm-for-employee-4 .twm-for-employee-content .twm-explore-right-section .counter-outer-two {
    position: inherit;
    width: 100%;

    &.one,
    &.two {
      left: auto;
      top: auto;
      right: auto;
      bottom: auto;
    }

    &.three {
      left: auto;
      top: auto;
      right: auto;
      bottom: auto;
      margin-bottom: 0px;
    }
  }
}

/*Featured jobs*/

.twm-jobs-featured-style1 {
  background-color: #1967d2;
  border: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  position: relative;
  padding: 30px;
  border-radius: 10px;
  width: 100%;

  .twm-media {
    width: 90px;
    height: 90px;
    line-height: 90px;
    display: inline-block;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
    margin-right: 30px;
    overflow: hidden;

    img {
      display: block;
    }
  }

  .twm-mid-content {
    padding-top: 20px;
    color: #fff;

    .twm-job-title h4 {
      color: #fff;
    }
  }

  .twm-bot-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .twm-job-address {
      margin-bottom: 0px;
      color: #fff;

      i {
        margin-right: 5px;
      }
    }
  }

  .twm-job-post-duration {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
  }

  .twm-jobs-category {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

@media (max-width: 420px) {
  .twm-jobs-featured-style1 {
    text-align: center;
    margin-top: 0px;
  }
}

@media (max-width: 420px) {
  .twm-jobs-featured-style1 .twm-media {
    margin-right: 0px;
  }
}

@media (max-width: 420px) {
  .twm-jobs-featured-style1 .twm-bot-content {
    display: block;
  }
}

@media (max-width: 420px) {
  .twm-jobs-featured-style1 .twm-job-post-duration {
    display: block;
    margin: 0px;
  }
}

@media (max-width: 420px) {
  .twm-jobs-featured-style1 .twm-jobs-category {
    position: inherit;
    top: inherit;
    right: inherit;
    margin-top: 10px;
  }
}

/*Client carousel 4*/

.home-client-carousel4 {
  padding: 0px;
  margin-bottom: 20px;

  .ow-client-logo {
    height: 100px;
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(56, 152, 226, 0.2);
    padding: 12px 20px;
    border-radius: 10px;
    margin: 10px;
  }

  .client-logo {
    max-width: 130px;
    margin-left: auto;
    margin-right: auto;

    a {
      height: auto;
      display: flex;
      justify-content: space-around;

      img {
        width: auto;
        height: auto;
      }

      &:hover img {
        filter: grayscale(100%);
        transition: 0.5s all ease;
      }
    }
  }
}

@media (max-width: 991px) {
  .home-client-carousel4 {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
}

/* How It Work Section*/

.twm-how-it-work-1-area {
  max-width: 1560px;
  margin: 120px auto;
  border-radius: 80px;
  padding: 120px 125px 120px 125px;
  overflow: hidden;

  .twm-how-it-work-1-content {
    .row {
      display: flex;
      align-items: center;
    }

    .twm-how-it-work-1-section {
      margin-bottom: 40px;

      .section-head {
        .wt-small-separator,
        h2 {
          color: #fff;
        }
      }
    }

    .twm-how-it-work-1-left {
      margin-right: 100px;
    }

    .twm-how-it-right-section {
      position: relative;
      z-index: 1;
      height: 100%;

      .twm-media {
        text-align: center;
        position: relative;
        width: 100%;

        .twm-bg-circle {
          position: relative;
          z-index: 1;
          left: 50%;
          transform: translateX(-50%);
          width: 90.1%;
        }

        .twm-block-left {
          z-index: 1;
          position: absolute;
          left: 0px;
          top: 0%;
          width: 16.5%;
        }

        .twm-block-right {
          position: absolute;
          z-index: 1;
          width: 12%;
          right: -4%;
          bottom: -4%;
        }

        .twm-main-bg {
          z-index: 1;
          position: absolute;
          left: 0%;
          top: 0%;
          width: 91.7%;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .twm-how-it-work-1-area {
    padding: 40px 30px;
    margin: 40px;
  }
}

@media (max-width: 991px) {
  .twm-how-it-work-1-area {
    margin: 40px 15px;
  }
}

@media (max-width: 1360px) {
  .twm-how-it-work-1-area .twm-how-it-work-1-content .twm-how-it-work-1-left {
    margin-right: 20px;
  }
}

.twm-step-section-4 ul {
  list-style: none;
  margin-bottom: 30px;
  position: relative;

  li {
    margin-bottom: 50px;
    position: relative;
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      left: 33px;
      top: 70px;
      height: 125%;
      width: 5px;
      background-color: #fff;
      z-index: -1;
    }

    &:last-child:before {
      display: none;
    }

    .twm-step-count {
      width: 70px;
      height: 70px;
      line-height: 70px;
      border-radius: 10px;
      color: #1967d2;
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      font-family: "Poppins", sans-serif;
      position: absolute;
      left: 0px;
      top: 0px;
    }

    .twm-step-content {
      padding-left: 110px;

      .twm-title {
        color: #fff;
      }

      p {
        margin-bottom: 0px;
        color: #e7e7e7;
      }
    }
  }
}

@media (max-width: 480px) {
  .twm-step-section-4 ul li:before {
    display: none;
  }
}

@media (max-width: 480px) {
  .twm-step-section-4 ul li {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .twm-step-section-4 ul li .twm-step-count {
    position: inherit;
    margin: 0px auto 30px;
  }
}

@media (max-width: 480px) {
  .twm-step-section-4 ul li .twm-step-content {
    padding-left: 0px;
  }
}

/* under Maintenance*/

.twm-u-maintenance-area {
  height: 100vh;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 991px) {
  .twm-u-maintenance-area {
    height: auto;
  }
}

.twm-u-maintenance-wrap {
  padding: 100px 20px;
  max-width: 1360px;
  margin: 0px auto;

  .row {
    display: flex;
    align-items: center;

    .twm-u-maintenance-content {
      margin-bottom: 50px;

      .media {
        margin-bottom: 50px;
        max-width: 140px;
      }

      .twm-u-maintenance-title {
        font-size: 20px;

        span {
          font-size: 60px;
          line-height: 80px;
          display: block;
        }
      }

      .twm-u-maintenance-title2 {
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 20px;
        font-size: 16px;
      }

      .social-icons {
        margin: 0px;

        li {
          display: inline-block;

          a {
            height: 45px;
            width: 45px;
            line-height: 45px;
            background-color: #fff;
            padding: 0px;
            color: #1967d2;
            font-size: 22px;
            text-align: center;
            margin-right: 3px;
            border-radius: 10px;

            &:hover {
              color: #17171d;
            }
          }
        }
      }
    }
  }

  .twm-u-maintenance-image {
    max-width: 840px;
    width: 100%;

    img {
      width: 100%;
    }
  }
}

@media (max-width: 991px) {
  .twm-u-maintenance-wrap {
    padding-bottom: 30px;

    .twm-error-image {
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 575px) {
  .twm-u-maintenance-wrap .row .twm-u-maintenance-content {
    text-align: center;
  }
}

@media (max-width: 575px) {
  .twm-u-maintenance-wrap .row .twm-u-maintenance-content .media {
    margin: 0px auto 50px;
  }
}

@media (max-width: 575px) {
  .twm-u-maintenance-wrap .row .twm-u-maintenance-content .twm-u-maintenance-title span {
    font-size: 30px;
    line-height: 40px;
  }
}

/* under Maintenance*/

.twm-c-soon-area {
  height: 100vh;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 991px) {
  .twm-c-soon-area {
    height: auto;
  }
}

.twm-c-soon-wrap {
  padding: 100px 20px;
  max-width: 1360px;
  margin: 0px auto;

  .row {
    display: flex;
    align-items: center;

    .twm-c-soon-content {
      margin-bottom: 50px;
      text-align: center;

      .twm-c-soon-title {
        font-size: 20px;
        margin-bottom: 20px;

        span {
          font-size: 60px;
          line-height: 80px;
          display: block;
          margin-bottom: 30px;
        }
      }

      .twm-c-soon-title2 {
        margin-bottom: 30px;
      }

      p {
        margin-bottom: 30px;
        font-size: 16px;
      }

      .social-icons {
        margin: 0px 0px 50px 0px;

        li {
          display: inline-block;

          a {
            height: 45px;
            width: 45px;
            line-height: 45px;
            background-color: #fff;
            padding: 0px;
            color: #1967d2;
            font-size: 22px;
            text-align: center;
            margin-right: 3px;
            border-radius: 10px;

            &:hover {
              color: #17171d;
            }
          }
        }
      }
    }
  }

  .cs-nw-form {
    display: flex;
    margin-bottom: 30px;

    .form-control {
      height: 66px;
      border: 0px;
      background-color: #dbe6f7;
      border-radius: 10px 0px 0px 10px;
    }

    .cs-subcribe-btn {
      font-size: 16px;
      color: #fff;
      background-color: #1967d2;
      border: none;
      // font-family: "Josefin Sans", sans-serif;
      font-weight: 600;
      padding: 9px 30px;
      border-radius: 0px 10px 10px 0px;
      white-space: nowrap;
    }
  }
}

@media (max-width: 991px) {
  .twm-c-soon-wrap {
    padding-bottom: 30px;

    .twm-error-image {
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 575px) {
  .twm-c-soon-wrap .row .twm-c-soon-content {
    text-align: center;
  }
}

@media (max-width: 575px) {
  .twm-c-soon-wrap .row .twm-c-soon-content .twm-c-soon-title span {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (max-width: 400px) {
  .twm-c-soon-wrap .cs-nw-form {
    display: block;
  }
}

@media (max-width: 575px) {
  .twm-c-soon-wrap .cs-nw-form .form-control {
    height: 48px;
  }
}

@media (max-width: 400px) {
  .twm-c-soon-wrap .cs-nw-form .form-control {
    border-radius: 10px;
  }
}

@media (max-width: 768px) {
  .twm-c-soon-wrap .cs-nw-form .cs-subcribe-btn {
    font-size: 16px;
    padding: 7px 20px;
  }
}

@media (max-width: 400px) {
  .twm-c-soon-wrap .cs-nw-form .cs-subcribe-btn {
    border-radius: 10px;
    display: block;
    width: 100%;
    margin-top: 10px;
  }
}

/*Countdown*/

.twm-countdown-wrap {
  border-radius: 20px;

  span {
    display: inline-block;
    line-height: 1;
    padding: 20px;
    font-size: 40px;
    font-weight: 600;
    color: #fff;
    vertical-align: bottom;

    > span {
      display: block;
      font-size: 20px;
      color: #17171d;
      box-shadow: none;
      background-color: transparent;
    }
  }

  #days {
    font-size: 100px;
    background-color: #7cb5ea;
  }

  #hours {
    font-size: 80px;
    background-color: #cea7d9;
  }

  #minutes {
    font-size: 55px;
    background-color: #8be3c6;
  }

  #seconds {
    font-size: 35px;
    background-color: #eac87c;
  }
}

@media (max-width: 600px) {
  .twm-countdown-wrap span {
    padding: 10px;
  }
}

@media (max-width: 420px) {
  .twm-countdown-wrap span {
    padding: 5px;
  }
}

@media (max-width: 600px) {
  .twm-countdown-wrap span > span {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .twm-countdown-wrap #days {
    font-size: 40px;
  }
}

@media (max-width: 420px) {
  .twm-countdown-wrap #days {
    font-size: 26px;
  }
}

@media (max-width: 600px) {
  .twm-countdown-wrap #hours {
    font-size: 30px;
  }
}

@media (max-width: 420px) {
  .twm-countdown-wrap #hours {
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .twm-countdown-wrap #minutes {
    font-size: 20px;
  }
}

@media (max-width: 420px) {
  .twm-countdown-wrap #minutes {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .twm-countdown-wrap #seconds {
    font-size: 16px;
  }
}

@-webkit-keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }

  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}

@keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }

  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}

/*Candidate pages start------------------------------------------------------------*/

.side-bar-st-1 {
  background: #d4e6ff;
  padding: 30px;
  border-radius: 10px;
}

/*Edit Profile*/

.twm-candidate-profile-pic {
  position: relative;
  display: block;
  max-width: 150px;
  margin: 0px auto 30px;
  border: 1px solid #fff;
  border-radius: 10px;

  img {
    border-radius: 10px;
    width: 100%;
    padding: 10px;
  }

  .upload-btn-wrapper {
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
}

/*Navigation list*/

.twm-nav-list-1 ul {
  list-style: none;
  margin: 0;
  font-weight: 500;

  li {
    float: none;

    &:last-child {
      border-bottom: none;
    }

    &.has-child {
      position: relative;
    }

    a > i {
      font-size: 18px;
      margin-right: 25px;
      position: absolute;
      left: 10px;
      top: 14px;
      color: #1967d2;
    }
  }

  > li {
    > a {
      padding: 10px 5px 10px 5px;
      color: #323232;
      display: flex;
      font-size: 16px;
      position: relative;
      font-weight: 400;
      transition: 0.5s all ease;

      &:hover {
        color: #1967d2;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 25px rgba(180, 205, 237, 0.7);
      }
    }

    ul {
      display: none;
      position: static;
      visibility: visible;
      width: auto;
    }

    &.active > a {
      color: #1967d2;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 25px rgba(180, 205, 237, 0.7);
    }
  }
}

.twm-right-section-panel {
  padding: 30px;
  border-radius: 10px;

  textarea.form-control {
    height: auto;
    resize: none;
  }

  .panel.panel-default {
    border-radius: 10px;
    background: #fff;
  }
}

@media (max-width: 540px) {
  .twm-right-section-panel {
    padding: 15px;
  }
}

/*Candidate Save Jobs*/

.candidate-save-job .twm-D_table {
  padding: 0px;
}

/* Data Table Candidets */

.twm-candidate-save-job-list-wrap .twm-candidate-save-job-list {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;

  .twm-media {
    width: 100%;
    max-width: 40px;
    display: inline-block;
    margin-right: 20px;
    border-radius: 10px;
    height: 40px;

    .twm-media-pic {
      width: 40px;
      height: 40px;
      border-radius: 10px;
      overflow: hidden;
      position: relative;

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .twm-job-title h4 {
    color: #1967d2;
    font-size: 16px;
    font-weight: 500;
  }
}

/*CV Manager List*/

.twm-cv-manager-list-wrap {
  padding-top: 0px;

  ul {
    margin: 0px;

    li {
      list-style: none;
    }
  }
}

.twm-cv-manager-list-style1 {
  background-color: #fff;
  border: 1px solid #ddd;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;

  .twm-media {
    width: 110px;
    display: inline-block;
    margin-right: 10px;

    .twm-media-pic {
      width: 90px;
      height: 90px;
      border-radius: 10px;
      overflow: hidden;
      position: relative;

      img {
        display: inline-block;
      }
    }
  }

  .twm-candidates-tag span {
    color: #1967d2;
  }

  p {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .twm-mid-content {
    flex-grow: 1;
  }

  .twm-fot-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .twm-left-info {
      display: flex;
      justify-content: space-between;
    }

    .twm-candidate-address {
      font-size: 16px;
      margin-bottom: 0px;
      margin-right: 20px;
      font-weight: 500;

      i {
        margin-right: 5px;
      }
    }

    .twm-view-button a {
      text-align: center;
      display: inline-block;

      .fa {
        background-color: #dbe6f7;
        color: #1967d2;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
      }
    }
  }

  .twm-job-post-duration {
    color: #92388e;
    font-size: 16px;
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  .twm-cv-manager-list-style1 {
    display: block;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .twm-cv-manager-list-style1 .twm-media {
    margin-right: 0px;
    margin-bottom: 10px;
    width: 90px;
  }
}

@media (max-width: 420px) {
  .twm-cv-manager-list-style1 .twm-fot-content {
    display: block;
  }
}

@media (max-width: 420px) {
  .twm-cv-manager-list-style1 .twm-fot-content .twm-left-info {
    display: block;
  }
}

@media (max-width: 420px) {
  .twm-cv-manager-list-style1 .twm-fot-content .twm-candidate-address {
    margin-right: 0px;
  }
}

@media (max-width: 420px) {
  .twm-cv-manager-list-style1 .twm-job-post-duration {
    margin-right: 0px;
  }
}

.twm-table-controls .twm-DT-controls-icon li a {
  background-color: #dbe6f7;
  color: #1967d2;
  border: none;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #1967d2;
    color: #fff;
  }
}

/*saved jobs view popup*/

.twm-saved-jobs-view {
  margin-bottom: 10px;

  .modal-dialog {
    max-width: 590px;
  }

  .modal-content {
    border-top: 6px solid #1967d2;
  }

  .modal-header {
    display: block;
    text-align: center;
    border: none;
    margin-top: 50px;

    .modal-title {
      font-size: 24px;
      margin-bottom: 0px;
    }

    .btn-close {
      position: absolute;
      right: 10px;
      top: 10px;
      opacity: 1;
    }
  }

  .modal-body {
    padding: 30px 50px;
  }

  .modal-footer {
    border: none;
    background-color: #dbe6f7;
    text-align: center;
    display: block;
    padding: 20px;
    margin-top: 30px;

    .site-button {
      display: inline-block;
      text-align: center;
    }
  }
}

@media (max-width: 575px) {
  .twm-saved-jobs-view .modal-dialog {
    margin: 20px;
  }
}

@media (max-width: 575px) {
  .twm-saved-jobs-view .modal-body {
    padding: 20px;
  }
}

.twm-table tbody tr td a {
  border: none;
  background: transparent;
  display: inline-block;
  margin-bottom: 5px;
  text-align: center;

  .fa {
    background-color: #dbe6f7;
    color: #1967d2;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }

  &:hover .fa {
    background-color: #1967d2;
    color: #fff;
  }
}

.panel-heading-with-btn {
  display: flex;
  justify-content: space-between;
}

.twm-textarea-full .form-control {
  height: 130px;
}

.twm-s-detail-section {
  position: relative;
  margin-bottom: 15px;

  .twm-title {
    font-size: 15px;
    font-weight: 600;
    color: #17171d;
    word-break: break-word;
  }

  .twm-s-info-discription {
    font-size: 16px;
    color: #666666;
  }
}

.twm-panel-inner .twm-list-wrap {
  margin: 0px 0px 30px 0px;
  border-bottom: 1px solid #f5f7f9;
}

.wt-search-bar-select {
  height: 34px;
  border: none;
  line-height: 20px;
  border-radius: 0px;
  width: 100% !important;
  display: block !important;

  .btn-light {
    font-size: 16px;
    color: #a0a0a0;
    background-color: transparent;
    border: none;
    padding: 10px 0px !important;

    &:focus {
      box-shadow: none;
      outline: 0px !important;
    }
  }

  .form-control {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.wt-select-bar-2 {
  width: 160px !important;
  margin-right: 20px;

  &:last-child {
    margin-right: 0px;
  }

  .form-control {
    height: 30px;
  }

  .btn-light {
    font-size: 16px;
    color: #6c757d;
    height: 50px;
    line-height: 50px;
    background-color: #dbe6f7 !important;
    border: none;
    padding: 0px 15px;
    border-radius: 10px;

    &:focus {
      box-shadow: none;
      outline: 0px !important;
    }
  }

  .dropdown-menu {
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  .wt-select-bar-2 {
    margin-top: 10px;
    width: 100%;
  }
}

.wt-select-bar-large {
  border: none;
  width: 100% !important;

  .form-control {
    height: 30px;
  }

  .btn-light {
    font-size: 16px;
    color: #a0a0a0;
    height: 60px;
    line-height: 60px;
    background-color: #fff !important;
    border: none;
    padding: 0px 20px;
    border-radius: 10px;

    &:focus {
      box-shadow: none;
      outline: 0px !important;
    }
  }

  .dropdown-toggle:after {
    color: #17171d;
  }
}

/*Login - Register Form Wrap*/

.twm-log-reg-form-wrap {
  max-width: 767px;
  margin: 0px auto 30px;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  background-color: #fff;
}

@media (max-width: 600px) {
  .twm-log-reg-form-wrap {
    padding: 20px;
  }
}

/*Frontend Login menu*/

.twm-login-user-menu {
  margin-right: 0px;
  position: relative;
  padding: 14px 0px;
  position: relative;
  display: inline-block;
  cursor: pointer;

  .user-name {
    color: inherit;
    transition: 0.5s all ease;
    color: #fff;

    &:after {
      color: inherit;
    }

    &:before {
      font-family: "Font Awesome 5 Free";
      content: "\f107";
      font-weight: 900;
      transition: 0.5s all ease;
      display: inline-block;
      margin-left: 6px;
      color: #1967d2;
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
    }
  }

  span {
    display: inline-block;
    width: 40px;
    margin-right: 15px;

    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
  }

  ul {
    list-style: none;

    li a {
      line-height: 22px;
      color: #17171d;
      font-size: 13px;
      padding: 5px 5px 5px 24px;
      display: block;
      transition: 0.2s;
      position: relative;

      i {
        position: absolute;
        left: 0px;
        top: 10px;
        color: #1967d2;
      }

      &:hover {
        color: #1967d2;
      }
    }
  }

  .dropdown-menu {
    width: 100%;
    min-width: 180px;
    border-radius: 10px;
    box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
    border: 0px;
    padding: 20px;
  }

  .dropdown-toggle {
    padding-right: 20px;
    display: block;

    &::after {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  .twm-login-user-menu .user-name {
    font-size: 0px;
  }
}

@media (max-width: 600px) {
  .twm-login-user-menu .user-name:before {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .twm-login-user-menu span {
    display: none;
  }
}

/**/

.twm-hdr-user-notification {
  background-color: #1967d2;
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 100%;
}

@media (max-width: 768px) {
  .contact-us {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .search-bar {
    // grid-gap: 1.5rem !important;
    // gap: 1.5rem !important;
  }

  .margin-navbar {
    margin-right: auto !important;
  }
}

.twm-testimonial-v-area {
  overflow: hidden;
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    width: 600px;
    height: 600px;
    background-color: #eaf1fb;
    border-radius: 50%;
    position: absolute;
    left: -400px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
}

.twm-testimonial-v-section {
  .row {
    display: flex;
    align-items: center;
  }
}

.v-testimonial-wrap {
  padding: 50px;
  position: relative;
  z-index: 1;

  &:after {
    content: "";
    border-radius: 40px;
    background-color: #1967d2;
    height: 100%;
    width: 340px;
    right: 0px;
    top: 0px;
    position: absolute;
    z-index: -1;
  }

  .v-testi-dotted-pic {
    position: absolute;
    left: 0px;
    top: 0px;
  }
}

.v-testimonial-slider {
  height: 490px;
  overflow: hidden;

  .swiper-pagination {
    left: 0px;
  }
}

.testimonials-v {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  background-color: #f3f7fd;
  padding: 30px;
  display: flex;
  height: 200px;

  &:after {
    content: "";
    height: 14px;
    right: 20px;
    left: 20px;
    bottom: -14px;
    position: absolute;
    z-index: -1;
    background-color: rgba(243, 247, 253, 0.4);
    border-radius: 0px 0px 10px 10px;
  }

  .t-testimonial-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .twm-testi-media {
    margin-top: 40px;
    margin-right: 30px;
    overflow: hidden;
    border-radius: 50%;
    width: 56px;
    min-width: 56px;
    height: 56px;
  }

  .t-discription {
    font-size: 16px;
    font-style: italic;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: justify;
  }

  .t-readmore {
    text-decoration: underline;
    text-underline-offset: 5px;
    font-style: italic;
  }

  .twm-testi-detail {
    .twm-testi-name {
      margin-top: 4px;
      color: #17171d;
      font-size: 16px;
      font-weight: 600;
    }

    .twm-testi-position {
      font-size: 13px;
      color: #7f7f7f;
    }
  }

  .t-quote {
    font-size: 34px;
    line-height: 0px;
    color: #1967d2;
  }

  .t-rating {
    span {
      display: inline;

      i {
        color: #ffac16;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 991px) {
  .twm-testimonial-v-area {
    &:before {
      display: none;
    }

    .twm-explore-content-outer2 {
      margin-bottom: 30px;
    }
  }

  .v-testimonial-wrap {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .v-testimonial-wrap {
    padding: 50px 50px 50px 0px;
  }

  .v-testimonial-slider {
    height: auto;
  }
}

@media (max-width: 480px) {
  .v-testimonial-wrap {
    padding: 0px;

    &:after {
      display: none;
    }
  }

  .v-testimonial-slider {
    .swiper-pagination {
      bottom: -40px;
    }

    margin-bottom: 60px;
  }

  .testimonials-v {
    display: block;

    .twm-testi-media {
      margin: 0px auto 30px;
      display: block;
    }
  }
}

.twm-about-9-section-wrap {
  margin-bottom: 30px;
  overflow: hidden;

  > .row {
    display: flex;
    align-items: center;

    .twm-about-9-section {
      position: relative;
      z-index: 1;

      .twm-media {
        img {
          display: block;
          margin: 0px auto;
          position: relative;
          left: 45%;
          transform: translateX(-50%);
          bottom: 0px;
          margin-left: 20px;
        }
      }

      .img-bg-circle {
        border-radius: 50%;
        background-color: #f9f9f9;
        max-width: 560px;
        height: 560px;
        width: 100%;
        position: absolute;
        display: block;
        margin: 0px auto;
        left: 50%;
        bottom: 0px;
        transform: translateX(-50%);
        z-index: -1;
      }

      .twm-card-blocks-2 {
        background-color: #fff;
        border: 10px;
        box-shadow: 0px 0px 40px rgba(56, 152, 226, 0.3);
        position: absolute;
        padding: 7px 20px;
        border-radius: 10px;
        left: 20px;
        top: 50%;
        min-width: 250px;
        transform: translateY(-50%);

        .twm-pics {
          margin-left: 15px;
          margin-right: 15px;
          display: flex;
          align-items: center;

          span {
            display: inline-block;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            border: 2px solid #fff;
            overflow: hidden;
            margin-left: -15px;
          }
        }

        .twm-content {
          display: flex;
          align-items: center;

          .tw-count-number {
            font-size: 38px;
            line-height: 38px;
            font-weight: 700;
            margin-right: 10px;

            .counter {
              font-size: 38px;
              line-height: 40px;
              font-weight: 600;
            }
          }

          .icon-content-info {
            color: #17171d;
            margin-bottom: 0px;
            font-size: 16px;
          }
        }
      }
    }

    .twm-about-9-section-right {
      margin-left: 20px;

      .twm-card-blocks {
        box-shadow: none;
        border: 1px solid #eaf1fb;
      }
    }
  }
}

@media (max-width: 991px) {
  .twm-about-9-section-wrap {
    margin-bottom: 0px;

    > .row {
      .twm-about-9-section {
        margin-bottom: 30px;

        .twm-card-blocks-2 {
          margin-bottom: 30px;
        }
      }

      .twm-about-9-section-right {
        margin-left: 0px;

        .twm-about-1-bottom-wrap {
          margin-bottom: 0px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .twm-about-9-section-wrap {
    > .row {
      .twm-about-9-section {
        .twm-media {
          img {
            margin-left: 0px;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .twm-about-9-section-wrap {
    > .row {
      .twm-about-9-section {
        .img-bg-circle {
          height: 480px;
          width: 480px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .twm-about-9-section-wrap {
    > .row {
      .twm-about-9-section {
        .img-bg-circle {
          height: 400px;
          width: 400px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .twm-about-9-section-wrap {
    > .row {
      .twm-about-9-section-right {
        margin-bottom: 30px;
      }
    }
  }
}

.box-search {
  box-shadow: 0px 0px 30px rgba(230, 230, 230, 0.3);
}

.twm-home7-banner-section {
  position: relative;
  margin-top: 12vh;
  // min-height: 110vh;
  overflow: hidden;

  .twm-home7-bnr-container {
    .twm-home7-inner-section {
      // margin-top: -40vh;
      .twm-bnr-left-section {
        // padding-bottom: 40px;
        .twm-bnr-title-large {
          font-size: 55px;
          max-width: 80%;
          line-height: 70px;
          font-weight: 700;
          // color: #fff;
          margin-bottom: 10px;
        }

        .twm-bnr-title-light {
          max-width: 720px;
          font-size: 66px;
          line-height: 70px;
          font-weight: 300;
          color: #fff;
          margin-bottom: 50px;
        }

        .twm-bnr-discription {
          font-size: 18px;
          margin-bottom: 40px;
          color: #fff;
        }

        .twm-bnr-search-bar {
          max-width: 80%;
          margin-bottom: 40px;
          box-shadow: none;
        }
      }
    }
  }

  .twm-bnr-bottom-section {
    padding: 0px 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .twm-browse-jobs {
      position: absolute;
      right: 0px;
      font-weight: 800;
      opacity: 0.2;
      text-transform: uppercase;
      color: #fff;
      font-size: 140px;
      line-height: 135px;
      font-family: "Poppins", sans-serif;
      display: block;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: 1px;
      color: #ececec;
    }
  }
}

.twm-home7-bnr-container {
  margin-left: 3vw;
  margin-right: 3vw;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .twm-home7-banner-section {
    margin-top: 200px;

    // min-height: 620px;
    .twm-home7-bnr-container {
      .twm-home7-inner-section {
        .twm-bnr-left-section {
          .twm-bnr-title-large {
            font-size: 28px;
            line-height: 48px;
          }

          .twm-bnr-title-light {
            font-size: 38px;
            line-height: 48px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .twm-home7-banner-section {
    margin-top: 120px;

    //   min-height: 620px;
    .twm-home7-bnr-container {
      .twm-home7-inner-section {
        .twm-bnr-left-section {
          .twm-bnr-title-large {
            font-size: 40px;
            // max-width: 40%;
            line-height: 58px;
            text-transform: uppercase;
            font-weight: 500;
            // color: #fff;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .twm-bnr-search-bar {
    // scale: 0.8;
    // margin-left: -80px;
  }
}

@media (min-width: 1440px) and (max-width: 1600px) {
  .twm-home7-banner-section {
    margin-top: 12vh;

    // min-height: 780px;
    .twm-home7-bnr-container {
      .twm-home7-inner-section {
        .twm-bnr-left-section {
          .twm-bnr-title-large {
            font-size: 55px;
            max-width: 90%;
            line-height: 68px;
            text-transform: uppercase;
            font-weight: 500;
            // color: #fff;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .twm-home7-banner-section {
    .twm-home7-inner-section {
      .twm-bnr-mid-section {
        .twm-bnr-title-large {
          font-size: 28px;
          line-height: 36px;
        }

        .twm-bnr-title-light {
          font-size: 28px;
          line-height: 36px;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .twm-home7-banner-section {
    .twm-bnr-bottom-section {
      .twm-browse-jobs {
        font-size: 80px;
        line-height: 70px;
      }
    }
  }
}

@media (max-width: 420px) {
  .twm-home7-banner-section {
    .twm-bnr-bottom-section {
      .twm-browse-jobs {
        font-size: 40px;
        line-height: 40px;
      }
    }
  }
}

.job-card-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.job-card-prefecture {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.job-card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/*# sourceMappingURL=style.css.map */
.input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.align-content-bottom {
  align-content: end;
}
