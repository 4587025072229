.box-container {
  background-color: #fff;
  border: 10px;
  box-shadow: 0px 0px 25px rgba(56, 152, 226, 0.3);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;

  .right-box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .description {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-height: 100px;
  }
}

.header-contain {
  display: flex;
  justify-content: space-between;
}

.twm-jobs-browse {
  background: #92388e;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 600;
  border-radius: 8px;
  padding: 10px 20px;
}

@media (max-width: 767px) {
  .twm-jobs-browse {
    margin-top: 10px;
  }
}

@media (max-width: 660px) {
  .box-container {
    text-align: center;
    display: block;
    margin-bottom: 30px !important;
  }

  .header-contain {
    display: block;
  }

  .salary-range {
    text-align: left;
  }
}
.button-text {
  border-width: 0;
  background-color: transparent;
}

.industry-form {
  height: 400px;
  margin-top: 10px;
  overflow: scroll;
  overflow-x: hidden;
}

.industry-form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  margin-right: 10px;
}

.twm-job-detail-form-step-item-container {
  margin-bottom: 10px;
  align-items: center;
  text-align: center;
  border: none;
  background-color: transparent;
}

.twm-job-detail-form-step-item {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #92388e;
  border: solid 1px #92388e;
  cursor: pointer;
  margin: auto;
}

.step-active {
  background-color: #92388e;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: 700;
}

.twm-job-detail-form-step-line {
  width: 100%;
  height: 1px;
  background-color: #92388e;
  margin-top: 20px;
}
