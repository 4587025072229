.btn-header-purple {
    background: rgba(146, 56, 142, 1);
    background: linear-gradient(90deg, rgba(146, 56, 142, 1) 0%, rgba(146, 56, 142, 1) 100%);
    background-size: inherit;
    color: white;
    border: none !important;
    transition: all 0.1s ease-out !important;
    -webkit-transition: all 0.1s ease-out !important;
    -ms-transition: all 0.1s ease-out !important;
    -o-transition: all 0.1s ease-out !important;
}

.btn-header-purple:hover {
    color: white;
    background: linear-gradient(257.82deg, #bd4a94 11.17%, #603e92 88.61%);
}

.btn-header-purple-outline {
    background: transparent;
    color: #92388e;
    border: 1px solid #92388e;
    box-shadow: none;
    transition: background 0.5s cubic-bezier(0.11, 0, 0.5, 0), color 0.5s linear;
    -webkit-transition: background 0.5s cubic-bezier(0.11, 0, 0.5, 0), color 0.5s linear;
    -ms-transition: background 0.5s cubic-bezier(0.11, 0, 0.5, 0), color 0.5s linear;
    -o-transition: background 0.5s cubic-bezier(0.11, 0, 0.5, 0), color 0.5s linear;
}

.btn-header-purple-outline:hover {
    color: white;
    background: linear-gradient(257.82deg, #bd4a94 11.17%, #603e92 88.61%);
}

.nav-mx-1 {
    margin: auto 4px;
}

.ml-1 {
    margin-left: 4px;
}

.link-nav {
    font-weight: 500;
    color: #232323 !important;
    transition: all 0.3s ease-out;
}

.link-nav:hover {
    color: #bd4a94 !important;
}

.nav-lang-label {
    color: #232323 !important;
    margin-left: 6px;
}

.dropdown-item-lang:hover {
    background-color: #bd4a9528;
    color: #bd4a94;
}

button.navbar-toggler {
    height: 36px;
    width: 36px;
    border-radius: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #92388e;
}

.navbar-toggler-icon {
    background-image: url("../icons/burger-menu.svg") !important;
    scale: 1.3;
}

button.navbar-toggler:focus {
    outline: none !important;
    box-shadow: none !important;

}

@media screen and (max-width: 768px) {
   .navbar-brand > img {
    height: 36px !important;
   }

   li.list-menu-name {
    padding: 4px 0;
    border-bottom: 1px solid #ddd;
   }

   li.list-menu-name::before {
    content: '>';
    position: absolute;
    right: 16px;
    top: inherit;
    padding-top: 4px;
   }

   li.list-menu-name:last-child {
    border-bottom: none;
   }

}