.candicates-layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

@media screen and (min-width: 1250px) {
  .candicates-layout {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
}

@media screen and (max-width: 1249px) {
  .candicates-layout {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .candicates-layout {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  .candicates-layout {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
}

@media screen and (max-width: 555px) {
  .candicates-layout {
    grid-template-columns: 1fr;
  }
}
